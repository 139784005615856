import api from "lib/api";

const PAYMENT_REQUEST_MBWAY_PAYMENT = "PAYMENT_REQUEST_MBWAY_PAYMENT";

const hipayMbwayPaymentRequest = payload => (dispatch, getState) => {
  const state = getState();

  if (state.context.allowGuestLogin && !state.user.token) {
    return dispatch({
      type: PAYMENT_REQUEST_MBWAY_PAYMENT,
      payload: api.campaign.getGuestVoucherHiPayPaymentLink({
        clientApplication: state.context.clientPlatform || state.context.clientApplication,
        providerId: state.context.providerId,
        channelManager: state.context.channelManager,
        language: state.context.language,
        payload
      })
    });
  }

  return dispatch({
    type: PAYMENT_REQUEST_MBWAY_PAYMENT,
    payload: api.campaign.getVoucherHiPayPaymentLink({
      userId: state.user.id,
      token: state.user.token,
      clientApplication: state.context.clientPlatform || state.context.clientApplication,
      language: state.context.language,
      payload
    })
  });
};

hipayMbwayPaymentRequest.action = PAYMENT_REQUEST_MBWAY_PAYMENT;

export default hipayMbwayPaymentRequest;
