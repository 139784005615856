import api from "lib/api";

const CAMPAIGN_FETCH = "CAMPAIGN_FETCH";

const fetchCampaign = ({ campaignId }) => (dispatch, getState) => {
  const state = getState()

  return dispatch({
    type: CAMPAIGN_FETCH,
    payload: api.campaign.get({ campaignId, language: state.context.language })
  });
}

fetchCampaign.action = CAMPAIGN_FETCH;

export default fetchCampaign;
