import api from "lib/api";

const USER_REQUEST_SMS = "USER_REQUEST_SMS";

const requestSms = payload => (dispatch, getState) => {
  const state = getState();
  return dispatch({
    type: USER_REQUEST_SMS,
    payload: api.campaign.requestSms({
      userId: state.user.id,
      token: state.user.token,
      language: state.context.language,
      payload
    })
  });
};
requestSms.action = USER_REQUEST_SMS;

export default requestSms;
