import React from "react";
import { useSelector } from "react-redux";
import selectors from "lib/redux/selectors";
import { ReservationModes, ReservationTypes } from "lib/helpers/constants";
import BookingConfirmation from "./booking-confirmation";
import VoucherPayment from "./voucher-payment";
import SmartSubscription from "./smart-subscription";

/**
 * Flow Step 3 - Customer name, phone notes and actual booking submission
 */
export default function FlowStepConfirmPurchase(props) {
  const {
    mode,
    isTransported,
    reservationType,
  } = useSelector((state) => state.flow.steps)
  const smartUser = useSelector(selectors.user.isPremium)

  if (reservationType === ReservationTypes.SMART && !smartUser) {
    return <SmartSubscription {...props} />
  }

  const isBookDate = mode === ReservationModes.BOOK_DATE

  return isBookDate || isTransported ? (
    <BookingConfirmation {...props} />
  ) : (
    <VoucherPayment {...props} />
  )
}
