import api from "lib/api";

const USER_FETCH_WITH_EXTERNAL_AUTH = "USER_FETCH_WITH_EXTERNAL_AUTH";

const fetchUserWithExternalAuth = ({ externalToken, providerId }) => (
  dispatch
) => {
  return dispatch({
    type: USER_FETCH_WITH_EXTERNAL_AUTH,
    payload: api.user.getWithExternalAuthentication({
      externalToken,
      providerId,
    }),
  });
};
fetchUserWithExternalAuth.action = USER_FETCH_WITH_EXTERNAL_AUTH;

export default fetchUserWithExternalAuth;
