import React, {useCallback, useEffect, useState} from "react";
import withErrorModal from "lib/hocs/with-error-modal";
import {Col, Form, Row} from "react-bootstrap";
import phoneCodes from "lib/helpers/phone-codes.json";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {css} from "@emotion/core";
import {useTranslation} from "react-i18next";
import {Formik} from "formik";
import {addHours, addMinutes, formatDate, getJavaDateOffset, pad2} from "lib/helpers/calendar";
import actions from "lib/redux/actions";
import {PaymentMethods} from "lib/helpers/constants";
import FormikEffect from "components/formik-effect";
import {triggerEvent} from "lib/analytics";
import HipayMbwayLogo from 'public/static/images/payment-methods/hipay_mbway.png';
import Image from "next/image";
import selectors from "lib/redux/selectors";

const styles = {
  phoneRow: css({
    display: "flex",
    select: {
      minWidth: "10em",
    },
    ".phone-number": {
      flex: 1,
      marginLeft: "1rem",
    },
  }),
}

const schema = yup.object({
  mbwayPrefix: yup.string(),
  mbwayPhoneNumber: yup
    .string()
    .trim()
    .when("mbwayPrefix", {
      is: "+351",
      then: (schema) => schema.matches(/^9[0-9]{8}$/),
      otherwise: (schema) => schema.matches(/[0-9]+/),
    })
    .required(),
});

function MbwayWaitingConfirmation({ onFormikProps, updateStepState, stepState, onNextStep, showModal }) {
  const { t } = useTranslation();
  const campaign = useSelector((state) => state.campaign);
  const flow = useSelector((state) => state.flow.steps);
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(true);
  const payment = useSelector((state) => state.payment.payment);
  const [paymentUntil, setPaymentUntil] = useState(addMinutes(new Date(), 4))
  const isPremiumReservation = useSelector(selectors.flow.isPremiumReservation)

  const retryUpdate = () => {
    setTimeout(() => {
      try {
        dispatch(actions.payment.getHipayMbwayPaymentStatus())
      } catch (e) {
        showModal(e.message)
      }
    }, 15000)
  }

  useEffect(() => {
    // if status didn't change - retry
    if (payment.status === 'IN_PROGRESS') {
      retryUpdate()
    } else {
      // if success
      if (payment.status === 'COMPLETED') {
        triggerEvent(
          "Purchase",
          "Successfull_voucher_purchase",
          "MBWay",
          {
            dimension10: payment.id,
          }
        )
        setSubmitting(false);
        onNextStep()
      } else if (payment.status === 'CANCELLED') {
        showModal(t('paymentMethods.mbway.status.cancelled'))
      } else if (payment.status === 'ERROR_IN_PAY_PLATFORM') {
        showModal(t('paymentMethods.mbway.status.error_in_payment_platform'))
      }
    }
  }, [payment])

  const newPaymentRequest = async () => {
    try {// create mbway payment
      setSubmitting(true);

      const expireDate = addHours(new Date(), 2);
      const {campaignView} = campaign.info;
      const {campaignId, campaignType} = campaignView;
      const {numberOfPeople, needVatInvoice, name, vatId, prizeId} = flow.voucher;

      const {mbwayPrefix, mbwayPhoneNumber, email} = flow

      const result = await dispatch(
        actions.payment.hipayMbwayPaymentRequest({
          expireDate: `${formatDate(expireDate)}T00:00:00${getJavaDateOffset()}`,
          invoiceName: needVatInvoice ? name : "",
          invoiceFiscalId: needVatInvoice ? vatId : "",
          onBehalfOfName: "",
          campaignId: String(campaignId),
          campaignTypeId: campaignType,
          quantityPurchased: numberOfPeople,
          prizeTransactionId: prizeId,
          paymentMethod: PaymentMethods.HIPAY_MBWAY,
          paymentPhoneNumber: mbwayPrefix + String(mbwayPhoneNumber),
          paymentReservationType: isPremiumReservation ? 'premium' : 'voucher',
          email: email,
        })
      );

      const {
        id,
        status,
        message,
      } = result.action.payload.data;

      // payment request successful
      if (status === 'IN_PROGRESS') {
        triggerEvent(
          "Purchase",
          "buy_as_gift_click_go_to_payment_gateway_again",
          "MBWay",
          {
            dimension10: id,
          }
        );
      } else if (status === 'ERROR_IN_PAY_PLATFORM') {
        // show error - allow retry
        showModal(message)
      }

      // do nothing? it should always be success/in_progress, error_in_platform or exception
    } catch (err) {
      showModal(err.message)
    }
  }

  const handleFormChange = useCallback(
    ({ nextValues }) => {
      updateStepState(nextValues);
    },
    [updateStepState]
  );

  useEffect(function init() {
    triggerEvent(
      "Purchase",
      "voucher_purchase_waiting_payment_confirmation",
      "MBWay",
      {
        dimension10: payment.id,
      }
    );
  }, [])

  useEffect(
    function updateFormikProps() {
      onFormikProps({
        submitLabel: t("flow.waitingPayment"),
        isValid: true,
        isSubmitting: submitting,
      });
    },
    [submitting]
  );

  return (
    <div>
      <Row>
        <Col className="text-center">
          <Image
            src="https://www.mygon.com/reservation-widget/static/images/payment-methods/hipay_mbway.png"
            width="180px"
            height="90px"
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="medium-text">
          <p>{t('paymentMethods.mbway.registeredPayment', {paymentId: payment.id})}</p>
          <p>{t('paymentMethods.mbway.confirmPayment', {
            until: paymentUntil.getHours() + ":" + pad2(paymentUntil.getMinutes())
          })}</p>
          <p>{t('paymentMethods.mbway.paymentProcessing')}</p>
          <p>{t('paymentMethods.mbway.notificationNotReceived')}<a href="#" onClick={newPaymentRequest}>{t('paymentMethods.mbway.hereLink')}</a></p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Formik
            validateOnMount
            validationSchema={schema}
            onSubmit={newPaymentRequest}
            initialValues={{
              mbwayPrefix: stepState.mbwayPrefix,
              mbwayPhoneNumber: stepState.mbwayPhoneNumber || "",
            }}
          >
            {({
                handleBlur,
                handleChange,
                handleSubmit,
                submitForm,
                values,
                touched,
                errors,
              }) => {
              onFormikProps({
                submitForm,
                submitLabel: t("flow.waitingPayment"),
                isSubmitting: true
              });
              return (
                <>
                  <FormikEffect onChange={handleFormChange} />
                  <Form
                    noValidate
                    className="form"
                    onSubmit={handleSubmit}
                  >
                    <Form.Group css={styles.phoneRow}>
                      <div className="phone-prefix">
                        <Form.Control
                          as="select"
                          className="rounded-underline"
                          name="mbwayPrefix"
                          placeholder={t("forms.phonePrefix")}
                          defaultValue={values.mbwayPrefix}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.mbwayPrefix && !!errors.mbwayPrefix}
                        >
                          {phoneCodes.map((entry) => (
                            <option
                              key={`${entry.code}-${entry.dial_code}`}
                              value={entry.dial_code}
                            >
                              {entry.code} ({entry.dial_code})
                            </option>
                          ))}
                        </Form.Control>
                        {touched.mbwayPrefix && (
                          <Form.Control.Feedback type="invalid">
                            {t("forms.errors.phonePrefixInvalid")}
                          </Form.Control.Feedback>
                        )}
                      </div>
                      <div className="phone-number">
                        <Form.Control
                          className="rounded-underline"
                          type="number"
                          pattern="[0-9]*"
                          inputMode="numeric"
                          name="mbwayPhoneNumber"
                          placeholder={t("forms.phone")}
                          value={values.mbwayPhoneNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.mbwayPhoneNumber && !!errors.mbwayPhoneNumber}
                        />
                        {touched.mbwayPhoneNumber && (
                          <Form.Control.Feedback type="invalid">
                            {t("forms.errors.phoneInvalid")}
                          </Form.Control.Feedback>
                        )}
                      </div>
                    </Form.Group>
                  </Form>
                </>
              )
            }}
          </Formik>
        </Col>
      </Row>
    </div>
  )
}

export default withErrorModal(MbwayWaitingConfirmation);
