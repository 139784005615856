import api from "lib/api";

const USER_FETCH_PRIZES = "USER_FETCH_PRIZES";

const fetchPrizes = () => (dispatch, getState) => {
  const state = getState();
  return dispatch({
    type: USER_FETCH_PRIZES,
    payload: api.user.getPrizes({
      userId: state.user.id,
      token: state.user.token
    })
  });
};
fetchPrizes.action = USER_FETCH_PRIZES;

export default fetchPrizes;
