import api from "lib/api";

const USER_FORGOT_PASSWORD = "USER_FORGOT_PASSWORD";

const forgotPassword = payload => (dispatch, getState) => {
  const state = getState();
  return dispatch({
    type: USER_FORGOT_PASSWORD,
    payload: api.user.forgotPassword({
      payload: {
        providerId: state.context.providerId,
        redirectUrl: "https%253A%252F%252Fwww.mygon.com%252Fen",
        ...payload
      }
    })
  });
};
forgotPassword.action = USER_FORGOT_PASSWORD;

export default forgotPassword;
