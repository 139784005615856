import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "@emotion/core";
import { Button, Container, Row, Col, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IconWrapper } from "components/icons";
import withErrorModal from "lib/hocs/with-error-modal";
import actions from "lib/redux/actions";
import { ReservationTypes } from "lib/helpers/constants";
import ReservationSummaryHeading from "components/elements/reservation-summary-heading";
import SmartMemberHeading from "components/elements/smart-member-heading";
import SmartPlanItem from "components/elements/smart-plan-item";
import cssVars from "styles/variables.module.scss";

const styles = {
  plansContainer: css({
    margin: "0 -7px",
    minHeight: "300px",
    ".plan-container": {
      marginBottom: "1.3rem",
      padding: "0 7px"
    },
    "&.loading": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  }),
  giveUpSmart: css({
    display: "flex",
    alignItems: "center",
    ".lateral-element": {
      width: "0.8rem"
    },
    ".text": {
      flex: 1,
      fontSize: "0.75rem"
    },
    ".btn": {
      marginLeft: "0.1rem"
    },
    svg: {
      fill: cssVars.gray600
    }
  })
};

/**
 * Flow Step 3 - Customer name, phone notes and actual booking submission
 */
function SmartSubscription({ onFormikProps, showModal, onPrevStep }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [plans, setPlans] = useState(null);
  const campaign = useSelector(state => state.campaign);

  useEffect(function init() {
    // Disable the global "submit to next step" button
    onFormikProps({
      submitForm: null,
      submitLabel: null
    });
    // setPlans({
    //   products: [
    //     {
    //       id: 1,
    //       description: "1 Mês",
    //       period: 30,
    //       useLimit: 1,
    //       months: 1,
    //       price: "10",
    //       totalPrice: "10",
    //       urlHiPay: "https://payment.hipay.com/index/mapi/id/5e4bee65648c4"
    //     },
    //     {
    //       id: 2,
    //       description: "3 Meses",
    //       period: 90,
    //       useLimit: 1,
    //       months: 3,
    //       price: "8",
    //       totalPrice: "24",
    //       urlHiPay: "https://payment.hipay.com/index/mapi/id/5e4bee65a3e29"
    //     },
    //     {
    //       id: 3,
    //       description: "6 Meses",
    //       period: 180,
    //       useLimit: 1,
    //       months: 6,
    //       price: "6",
    //       totalPrice: "36",
    //       urlHiPay: "https://payment.hipay.com/index/mapi/id/5e4bee65de538"
    //     },
    //     {
    //       id: 4,
    //       description: "12 Meses",
    //       period: 365,
    //       useLimit: 1,
    //       months: 12,
    //       price: "4",
    //       totalPrice: "48",
    //       urlHiPay: "https://payment.hipay.com/index/mapi/id/5e4bee662716a"
    //     }
    //   ],
    //   bestSubscription: 4
    // });

    (async () => {
      const result = await dispatch(actions.campaign.fetchPremiumPlans());
      setPlans(result.value.data);
    })();
  }, []);

  const changeToReservationTypeMygon = () => {
    // Give up on the smart flow. This will trigger a re-render and
    // this component will be unmounted and the other correct step-3 component
    // will be shown instead.
    dispatch(
      actions.flow.setStepState({
        data: {
          reservationType: ReservationTypes.NORMAL
        }
      })
    );
  };

  const ready = !!plans && !!plans.products;

  return (
    <div>
      <SmartMemberHeading />
      <ReservationSummaryHeading onClick={onPrevStep} />
      <Container>
        <div className="padded-step-container">
          <div css={styles.plansContainer} className={ready ? "" : "loading"}>
            {ready ? (
              <Row className="plans-container no-gutters">
                {plans.products.map(product => (
                  <Col xs={6} className="plan-container">
                    <SmartPlanItem
                      {...product}
                      isBest={product.id === plans.bestSubscription}
                    />
                  </Col>
                ))}
              </Row>
            ) : (
              <Spinner animation="border" role="status">
                <span className="sr-only">{t("waiting")}...</span>
              </Spinner>
            )}
          </div>
          <Button
            block
            variant="outlined"
            onClick={changeToReservationTypeMygon}
            css={styles.giveUpSmart}
          >
            <div className="text">{t("smart.giveUpPremium")}</div>
            <IconWrapper
              className="lateral-element"
              name="mdiChevronRight"
              height="1.5rem"
              viewBox="5 0 12 24"
            />
          </Button>
        </div>
      </Container>
    </div>
  );
}

export default withErrorModal(SmartSubscription);
