const CONTEXT_SET = "CONTEXT_SET";

const setContext = payload => ({
  type: CONTEXT_SET,
  payload
});

setContext.action = CONTEXT_SET;

export default setContext;
