import React from "react";
import { Button, Modal } from "react-bootstrap";

export default function ModalError({ message, showing, onHide }) {
  return showing ? (
    <Modal show centered onHide={onHide}>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onHide}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  ) : null;
}
