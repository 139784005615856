module.exports = ({ fetch }) => {
  return {
    /**
     * get payment status
     *
     * @param paymentId
     * @param token
     */
    getHipayMbwayPaymentStatus: ({ paymentId, token }) =>
      fetch(`/mygon-middleware/rest/payment/mbway-payment-status/${paymentId}/`, {
        method: "get",
        queryParams: { token },
      }),
    getGuestHipayMbwayPaymentStatus: ({ paymentId, token }) =>
      fetch(`/mygon-middleware/rest/payment/mbway-payment-status/guest/${paymentId}/`, {
        method: "get",
        queryParams: { token },
      }),
  };
};
