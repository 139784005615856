import React, {useCallback, useState} from "react";
import {css} from "@emotion/core";
import {useDispatch, useSelector} from "react-redux";
import {Form, Container} from "react-bootstrap";
import {Formik} from "formik";
import {useTranslation} from "react-i18next";
import ShoppingCartList from "components/shopping-cart/list";
import selectors from "lib/redux/selectors";
import {removeShoppingCartItem} from "lib/helpers/inter-frame-communication";
import FormikEffect from "components/formik-effect";
import cssVars from "styles/variables.module.scss";
import {mq} from "styles";
import actions from "../../lib/redux/actions";
import withErrorModal from "../../lib/hocs/with-error-modal";

const styles = {
  formContainer: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  }),
  emptyCart: css({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  }),
  form: css({
    overflow: 'auto',
    flexGrow: 1,
    ".price": {
      fontSize: "1.1rem",
      fontWeight: 500,
    },
  }),
  contactText: css(
    mq({
      fontSize: ["1rem", "1.25rem"],
      fontWeight: 500,
      color: cssVars.gray800,
      textAlign: "center",
    })
  ),
}

/**
 * Flow Step 0 for Takeaway&Delivery and Product checkout
 */
function FlowStepShoppingCart({
                                stepState,
                                updateStepState,
                                onFormikProps,
                                onNextStep,
                                showModal,
                              }) {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const shoppingCartItems = useSelector(selectors.shoppingCart.items)

  const {
    productOrder,
  } = stepState

  const emptyCart = shoppingCartItems.length === 0

  const handleValidSubmit = async ({shoppingCart}) => {
    updateStepState({shoppingCart});
    onNextStep();
    return false;
  }

  const handleFormChange = useCallback(
    ({nextValues}) => {
      updateStepState(nextValues);
    },
    [updateStepState]
  )

  const handleRemoveShoppingItem = useCallback((index) => {
    removeShoppingCartItem(shoppingCartItems[index].id)
      .then(result => {
        removeLocalItem(shoppingCartItems[index].id)
      })
      .catch((e) => {
        // manually call API from widget
        dispatch(actions.shoppingCart.removeItemFromCartApi(shoppingCartItems[index].id))
          .then(() => {
            removeLocalItem(shoppingCartItems[index].id)
          })
          .catch((error) => {
            showModal(error.message);
          })
      })
    }, [shoppingCartItems])

  const removeLocalItem = function (itemId) {
    dispatch(actions.shoppingCart.removeItemFromCart(itemId))
  }

  if (emptyCart) {
    onFormikProps({
      // Disable the global "submit to next step" button
      submitForm: null,
      submitLabel: null,
    })

    return (
      <div css={styles.emptyCart}>
        <div>{t("cart.emptyError")}</div>
      </div>
    )
  }

  return (
    <Formik
      onSubmit={handleValidSubmit}
      initialValues={{
        shoppingCartItems,
      }}
    >
      {({
          handleBlur,
          handleChange,
          handleSubmit,
          submitForm,
          isSubmitting,
          values,
          isValid,
        }) => {
        onFormikProps({
          submitForm,
          isValid,
          isSubmitting,
          submitLabel: t("flow.continue"),
        });
        return (
          <div css={styles.formContainer}>
            <FormikEffect onChange={handleFormChange}/>
            <Form
              noValidate
              className="form padded-step-container"
              onSubmit={handleSubmit}
              css={styles.form}
            >
              <Container>
                <Form.Group>
                  <Form.Label className="medium-text">
                    {t("reservation.confirmCartTitle")}
                  </Form.Label>
                  <ShoppingCartList
                    onRemove={handleRemoveShoppingItem}
                    productOrder={productOrder}
                    updateStepState={updateStepState}
                    //TODO: onNotesUpdate
                    //TODO: onQuantityUpdate
                  />
                </Form.Group>
              </Container>
            </Form>
          </div>
        );
      }}
    </Formik>
  )
}

export default withErrorModal(FlowStepShoppingCart)
