import { css } from "@emotion/core";
import MenuCampaignItem from "components/menu/item-campaign";
import MenuSection from "components/menu/section";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const styles = {
  noMenuContainer: css({
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
};

export default function FlowStepMenu({ }) {
  const { t } = useTranslation();
  const shop = useSelector((state) => state.shop);
  const { priceListView } = shop;
  const { sections } = priceListView || {};

  if (!sections) {
    return (
      <div css={styles.noMenuContainer}>
        <div>{t("menu.notAvailable")}</div>
      </div>
    );
  }

  const sectionsWithActiveItems = sections.filter(s => s.items.some(p => p.status === 'active'))

  return (
    <div>
      {sectionsWithActiveItems.map((section) => (
        <MenuSection
          {...section}
          key={section.name}
          items={section.items.filter(p => p.status === 'active')}
          ItemElement={MenuCampaignItem}
        />
      ))}
    </div>
  );
}
