import allSelectors from "./index";
import {TransportTypes, ReservationTypes} from "lib/helpers/constants";
import {stringPriceToFloat} from "lib/helpers/utils";
import {FlowTypes} from "../../hooks/use-flow";

const selectors = {};

selectors.book = (state) => state.flow.steps.book
selectors.numberOfPeople = (state) => state.flow.steps.book.numberOfPeople
selectors.showMenu = (state) => !!state.flow.steps.showMenu;
selectors.isTransported = (state) => !!state.flow.steps.isTransported;
selectors.reservationType = (state) => state.flow.steps.reservationType;
selectors.transportType = (state) => state.flow.steps.transportType;

selectors.serviceId = (state) => state.flow.steps.serviceId;
selectors.campaignId = (state) => state.flow.steps.campaignId;
selectors.backButton = (state) => state.flow.steps.backButton
// book, voucher, or premium - button user clicked in the frontend
selectors.defaultView = (state) => state.flow.steps.defaultView;
// if campaign has charge_value(premium) and view is premium
selectors.isPremiumReservation = (state) => {
  if (
    !state ||
    !state.campaign ||
    !state.campaign.info ||
    !state.campaign.info.campaignView
  ) {
    return false
  }
  const {chargeValue} = state.campaign.info.campaignView
  return chargeValue > 0 && state.flow.steps.defaultView === 'premium'
}
selectors.startTime = (state) => state.flow.steps.defaultView;
selectors.referralCode = (state) => state.flow.steps.referralCode;

selectors.selectedPriceInfo = (state) => {
  const selector = selectors.priceInfoCreator(
    selectors.serviceId(state),
    selectors.reservationType(state),
    selectors.isTransported(state)
  );
  return selector(state);
};

selectors.bookingMerchantNotes = (state) => {
  const serviceId = selectors.serviceId(state)

  let bookingConfig

  if (!!serviceId) {
    const service = allSelectors.shop.shopMerchantService(serviceId)(state)
    bookingConfig = service.bookingConfig
  } else {
    const campaign = allSelectors.campaign.info(state)
    bookingConfig = campaign.bookingConfig
  }

  return !!bookingConfig
    ? {
      bookingStartNote: bookingConfig.bookingStartNote,
      bookingFinishingNote: bookingConfig.bookingFinishingNote,
      bookingConfirmedNote: bookingConfig.bookingConfirmedNote,
    }
    : {}
}

selectors.priceInfoCreator = (serviceId, reservationType, isTransported) => (
  state
) => {
  const isService = !!serviceId;
  const campaignView = allSelectors.campaign.campaignView(state);
  const isNormal = reservationType === ReservationTypes.NORMAL;

  if (isService) {
    const service = allSelectors.shop.shopMerchantService(serviceId)(state);
    const {price} = service;
    const shoppingCartPricingFinal = isTransported
      ? allSelectors.shoppingCart.pricing(state).final
      : 0;
    return {
      currency: "€",
      priceBefore: price,
      offerPrice: null,
      discount: 0,
      shoppingCartFinalPrice: shoppingCartPricingFinal,
    };
  } else if (campaignView) {
    const {
      currency,
      offerPrice,
      priceBefore,
      discount,
      attributes,
    } = campaignView;

    if (isTransported) {
      const shoppingCartPricing = allSelectors.shoppingCart.pricing(state);
      return {
        currency,
        priceBefore,
        offerPrice,
        discount,
        shoppingCartFinalPrice: shoppingCartPricing.final,
      };
    } else if (isNormal) {
      return {currency, priceBefore, offerPrice, discount};
    } else {
      const discount = attributes.SMART_DISCOUNT
        ? // For reasons unknown to me, the smart price discount is a positive string
          // number. The campaignView.discount on the other hand is a negative number.
          // Here we normalize the smart discount to be a negative number as well.
        -stringPriceToFloat(attributes.SMART_DISCOUNT)
        : 0;
      return {
        currency,
        priceBefore,
        discount,
        offerPrice: attributes.SMART_PRICE,
      };
    }
  } else if (allSelectors.shoppingCart.shoppingCart(state).id) {
    const shoppingCartPricing = allSelectors.shoppingCart.pricing(state);
    return {
      currency: "€",
      shoppingCartFinalPrice: shoppingCartPricing.final,
    };
  } else {
    return {}
  }
};

export default selectors;
