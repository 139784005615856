import React, {useCallback} from "react"
import { css } from "@emotion/core";
import {Button, Form} from "react-bootstrap"
import { IconWrapper } from "components/icons";
import { useSelector } from "react-redux";
import selectors from "lib/redux/selectors";
import cssVars from "styles/variables.module.scss";
import { formatPrice } from "lib/helpers/utils";
import {useTranslation} from "react-i18next"

const styles = {
  button: css({
    display: "flex",
    alignItems: "center",
    padding: 0,
  }),
  iconButton: css({
    padding: "0.1rem",
    marginLeft: "0.75rem",
  }),
  list: css({
    listStyle: "none",
    padding: 0,
    margin: 0,
    fontSize: "0.8rem",
    ".group": {
      marginTop: '0.4rem',
      marginBottom: '1.6rem',
    },
    ".delivery-cost": {
      display: "flex",
      columnGap: '0.4rem',
      borderTop: `1px solid ${cssVars.gray500}`,
      width: 'fit-content',
      marginLeft: 'auto',
      padding: '0.2rem',
      ".label": {
        fontWeight: "300",
        fontSize: "0.9rem",
      },
      ".price": {
        fontWeight: "500",
        fontSize: "1rem",
      },
    },
    ".shop-name": {
      fontSize: "1.2rem",
    },
    ".note": {
      marginTop: '0.4rem',
    },
    ".cart-item": {
      padding: "0.4rem 0 0.4rem 0",
      borderTop: `1px solid ${cssVars.gray500}`,
      display: "flex",
      alignItems: "center",
      columnGap: '8px',
    },
    ".product-image": {
      height: "36px",
      width: "36px",
      img: {
        borderRadius: '7px',
        height: "100%",
        width: "100%",
        objectFit: "cover",
      },
      ".placeholder": {
        borderRadius: '7px',
        height: "100%",
        width: "100%",
        backgroundColor: cssVars.gray400
      }
    },
    ".first-line": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontWeight: "500",
      flexGrow: 1,
      ".name": {
        flex: 1,
        fontSize: '1rem',
      },
      ".price": {
        marginLeft: "1rem",
      },
      a: {
        display: "flex",
        alignItems: "center",
      },
      ".btn-link": {
        ".icon-wrapper": {
          marginLeft: "0.4rem",
        },
      },
      svg: {
        fill: cssVars.primary,
      },
    },
  }),
};

export default function ShoppingCartList({ updateStepState, productOrder, onRemove, editMode = true }) {
  const { t } = useTranslation()

  const shoppingCartShops = useSelector(selectors.shoppingCart.shops)
  const selectedDeliveryMethods = useSelector(selectors.deliveries.selectedDeliveryMethods)
  //TODO: notes per cart row

  const handleNoteChange = useCallback((shopId, note) => {
    const newProductOrder = {...productOrder}
    if (!newProductOrder[shopId]) {
      newProductOrder[shopId] = {}
    }
    newProductOrder[shopId].note = note
    updateStepState({ productOrder: newProductOrder })
  }, [updateStepState])

  return (
    <ul css={styles.list}>
      {shoppingCartShops.map(({ id: shopId, name, items }, index) => (
        <li key={`shop-${index}-${shopId}`} className="group">
          <h3 className="shop-name">{name}</h3>

          <div className="shop-items">
            {items.map(({ id, name, quantity, price, currency, image, notes }, index) => (
              <div key={`product-${index}-${id}`} className="cart-item">
                <div className="product-image">
                  {image
                    ? <img src={image} alt={name}/>
                    : <div className="placeholder"></div>
                  }
                </div>
                <div className="first-line">
                  <div className="name">{name} x{quantity}</div>
                  <div className="price">
                    {formatPrice(price * quantity, currency, { minFractionDigits: 2 })}
                  </div>
                  {editMode && (
                    <div>
                      <Button
                        css={styles.button}
                        type="button"
                        variant="link"
                        onClick={() => onRemove(index)}
                      >
                        <IconWrapper name="mdiCloseCircle" size="1.5rem" />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            ))}

            {selectedDeliveryMethods && selectedDeliveryMethods[shopId] && (
              <div className="delivery-cost">
                <span className="label">{t('cart.deliveryCost')}:</span>
                <span className="price">{formatPrice(selectedDeliveryMethods[shopId].costWithVat, '€', {minFractionDigits: 2})}</span>
              </div>
            )}
          </div>

          {editMode && (
            <Form.Control
              as="textarea"
              rows="2"
              name="shoppingCartNotes"
              placeholder={t("forms.notesForMerchant")}
              className="note"
              onChange={(e) => handleNoteChange(shopId, e.target.value)}
              value={productOrder[shopId] ? productOrder[shopId].note : ''}
            />
          )}
        </li>
      ))}
    </ul>
  );
}
