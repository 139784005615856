
/**
 * Validate if user can use a prize when buying a voucher
 * Currently the price minimum when discounting with the prize must be 2€
 *
 * @param {Number} offerPrice
 * @param {Number} voucherDiscount
 * @param {Number} prizeTransaction
 */
export const getVoucherPrice = (offerPrice, voucherDiscount, prizeTransaction) => {
  const { discount, discountWithPrize } = getFinalDiscount(voucherDiscount, prizeTransaction)

  return {
    canUsePrize: offerPrice - discountWithPrize >= 2,
    finalDiscount: discountWithPrize,
    finalPrice: (offerPrice - discountWithPrize) >= 0 ? offerPrice - discountWithPrize : 0
  }
}

/**
 * Get total discount allowed for voucher
 *
 * @param {Number} giftVoucherExtraDiscount
 * @param {Number} prizeTransaction
 */
const getFinalDiscount = (giftVoucherExtraDiscount, prizeTransaction = null) => {
  let discount = !!giftVoucherExtraDiscount ? giftVoucherExtraDiscount : 0
  let discountWithPrize = !!prizeTransaction && !!prizeTransaction.prizeValue ? discount + prizeTransaction.prizeValue : discount
  return { discount, discountWithPrize }
}
