import api from "lib/api"

const DELIVERY_METHODS_FETCH = "DELIVERY_METHODS_FETCH"

const fetchDeliveryMethods = ({ }) => (dispatch, getState) => {
  const state = getState()

  return dispatch({
    type: DELIVERY_METHODS_FETCH,
    payload: api.deliveries.getDeliveryMethods({
      language: state.context.language,
      entityId: state.entity.entityId,
      token: state.user.token,
    }),
  })
}

fetchDeliveryMethods.action = DELIVERY_METHODS_FETCH

export default fetchDeliveryMethods
