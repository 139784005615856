import React, { useCallback, useState } from "react";
import ModalError from "components/elements/modal-error";

export default function useModal() {
  const [message, setMessage] = useState("");
  const [showing, setShowing] = useState(false);

  const show = useCallback(message => {
    setMessage(message);
    setShowing(true);
  });

  const modalElement = (
    <ModalError
      showing={showing}
      message={message}
      onHide={() => setShowing(false)}
    />
  );
  return [modalElement, show];
}
