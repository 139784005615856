import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IconWrapper } from "components/icons";

export default function LoginSocialButton(props) {
  const viewbox = props.viewBox ||  "100 200 300 250"
  return (
    <Button
      variant={props.provider}
      className="social-login"
      aria-label={props.label}
      {...props}
    >
      <IconWrapper
        name={props.icon}
        width="1.7rem"
        height="1.7rem"
        viewBox={viewbox}
      />
      <span className="label">{props.label}</span>
    </Button>
  );
}
