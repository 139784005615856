import api from "lib/api";

const SHOP_FETCH_CALENDAR_SLOTS = "SHOP_FETCH_CALENDAR_SLOTS";

const fetchShopCalendarSlots = ({ shopMerchantServiceId }) => (dispatch, getState) => {
  const state = getState()
  return dispatch({
    type: SHOP_FETCH_CALENDAR_SLOTS,
    payload: api.shop.getCalendarSlots({
      shopMerchantServiceId,
      language: state.context.language
    }),
    meta: {
      shopMerchantServiceId,
    },
  });
}

fetchShopCalendarSlots.action = SHOP_FETCH_CALENDAR_SLOTS;

export default fetchShopCalendarSlots;
