import {
  isDateAvailable,
  matchesWorkingHours,
  createDate,
  formatDate,
} from "lib/helpers/calendar";
import { stringPriceToFloat } from "lib/helpers/utils";

const selectors = {};

selectors.schedule = (state) => state.campaign.schedule;
selectors.info = (state) => state.campaign.info;
selectors.deliveryCost = (state) => state.campaign.info.deliveryCost;
selectors.isAvailable = (state) => state.campaign.info.isAvailable;
selectors.hasTakeaway = (state) => state.campaign.info.campaignView.isTakeaway;
selectors.hasDelivery = (state) => state.campaign.info.campaignView.isDelivery;
selectors.minimumSecondsBeforeBooking = (state) => state.campaign.info.minimumSecondsBeforeBooking;
selectors.hasTakeawayOrDelivery = (state) =>
  selectors.hasTakeaway(state) || selectors.hasDelivery(state);
selectors.images = (state) => state.campaign.info.campaignImages ?? []
selectors.minimumMinutesBeforeBooking = (state) => {
  const minSecs = state.campaign.info.minimumSecondsBeforeBooking || 0;
  return Math.ceil(minSecs / 60);
};
selectors.avgMinutesOfOrderPreparation = (state) =>
  state.campaign.info.avgMinutesOfOrderPreparation || 0;
selectors.minMinutesBeforeTimeSelection = (state) => {
  const minMinutesBeforeBooking = selectors.minimumMinutesBeforeBooking(state);
  const minMinutesOrderPreparation =
    selectors.avgMinutesOfOrderPreparation(state);
  return Math.max(minMinutesBeforeBooking, minMinutesOrderPreparation);
};
selectors.canBookDate = (state) => {
  if (
    !state ||
    !state.campaign ||
    !state.campaign.info ||
    !state.campaign.info.campaignView
  ) {
    return false;
  }
  const { offer, isFreeOffer, merchantStatus } =
    state.campaign.info.campaignView;
  const allowVoucherOnly =
    offer && (!isFreeOffer || merchantStatus === "BLOCKED_NEGATIVE_BALANCE");
  return !allowVoucherOnly;
};
selectors.canBuyVoucher = (state) => {
  if (
    !state ||
    !state.campaign ||
    !state.campaign.info ||
    !state.campaign.info.campaignView
  ) {
    return false;
  }
  return state.campaign.info.campaignView.offer;
};
selectors.giftVoucherExtraDiscount = (state) => {
  if (
    !state ||
    !state.campaign ||
    !state.campaign.info ||
    !state.campaign.info.campaignView ||
    !state.campaign.info.giftVoucherExtraDiscount
  ) {
    return {
      value: false,
      percentage: false,
    };
  }

  const { giftVoucherExtraDiscount } = state.campaign.info;
  const { offerPrice } = state.campaign.info.campaignView;
  const [offerPriceF] = stringPriceToFloat(offerPrice);

  const percentage = Math.round((giftVoucherExtraDiscount / offerPriceF) * 100);

  return {
    value: giftVoucherExtraDiscount,
    percentage,
  };
};
selectors.premiumVoucherExtraDiscount = (state) => {
  if (
    !state ||
    !state.campaign ||
    !state.campaign.info ||
    !state.campaign.info.campaignView ||
    !state.campaign.info.premiumVoucherExtraDiscount
  ) {
    return {
      value: false,
      percentage: false,
    };
  }

  const { premiumVoucherExtraDiscount } = state.campaign.info;
  const { offerPrice } = state.campaign.info.campaignView;
  const [offerPriceF] = stringPriceToFloat(offerPrice);

  const percentage = Math.round((premiumVoucherExtraDiscount / offerPriceF) * 100);

  return {
    value: premiumVoucherExtraDiscount,
    percentage,
  };
};
selectors.campaignView = (state) => state.campaign.info.campaignView;
selectors.priceListView = (state) => state.campaign.info.priceListView;
selectors.addressView = (state) => state.campaign.info.addressView;
selectors.menuItemsById = (state) => {
  const { sections } = selectors.priceListView(state);
  const itemsArrays = sections.map((section) => section.items);
  // Flatten [[1,2],[3,4]] to [1,2,3,4]
  const itemsArray = [].concat(...itemsArrays);
  return itemsArray.reduce(
    (accum, item) => ({
      [item.id]: {
        ...item,
      },
      ...accum,
    }),
    {}
  );
};

/**
 * Checks if we are currently inside the shop's working hours so that we can
 * show the ASAP delivery. The code takes into account the
 * avgMinutesOfOrderPreparation and returns only true if adding that time to the
 * current time falls within the working hours.
 */
selectors.isInsideWorkingHours = (state) => {
  const schedule = selectors.schedule(state);
  const campaignView = selectors.campaignView(state);
  // Although the avgMinutesOfOrderPreparation might make more sense for this
  // use case, Ricardo asked that we use minimumMinutesBeforeBooking.
  const timeToPrep = selectors.minimumMinutesBeforeBooking(state);
  const { startDate, endDate, nonAvailableDays } = schedule;
  const { startTime, endTime } = campaignView;

  const calendarScheduleProps = {
    startDay: formatDate(createDate(startDate)),
    endDay: formatDate(createDate(endDate)),
    unavailableDays: nonAvailableDays.map((d) => formatDate(createDate(d))),
  };

  const d = new Date();

  const dateIsAvailable = isDateAvailable(
    formatDate(d),
    calendarScheduleProps.startDay,
    calendarScheduleProps.endDay,
    calendarScheduleProps.unavailableDays
  );

  if (!dateIsAvailable) {
    return false;
  }

  return matchesWorkingHours(d, startTime, endTime, timeToPrep);
};

selectors.hasSmartPrice = (state) => {
  const { attributes } = selectors.campaignView(state);
  if (!attributes) {
    return false;
  }

  return !!(attributes.SMART_DISCOUNT || attributes.SMART_PRICE);
};

selectors.calendarSlots = (serviceId) => (state) => {
  return state.campaign.calendarSlots[serviceId];
};

export default selectors;
