import React, { useMemo } from "react";
import { css } from "@emotion/core";
import { IconWrapper } from "components/icons";
import { useTranslation } from "react-i18next";
import {
  dateTimesObjectToOrderedArray,
  formatTime
} from "lib/helpers/calendar";
import cssVars from "styles/variables.module.scss";

const styles = {
  list: css({
    listStyle: "none",
    padding: 0,
    margin: "1.5rem 0",
    fontSize: "0.8rem",
    li: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: `1px solid ${cssVars.gray500}`,
      fontWeight: "bold",
      padding: "0.7rem 0",
      "&:first-of-type": {
        borderTop: `1px solid ${cssVars.gray500}`
      },
      a: {
        display: "flex",
        alignItems: "center"
      },
      ".icon-wrapper": {
        marginLeft: "0.4rem"
      },
      svg: {
        fill: cssVars.primary
      }
    }
  })
};

/**
 * Lists the dates that were chosen by the user and allows them to remove one by
 * one.
 *
 * @param {Object} props
 */
export default function ChosenDatesList({ dateTimes, onRemove }) {
  const { t } = useTranslation();
  const sortedDateTimes = useMemo(
    () =>
      dateTimesObjectToOrderedArray(dateTimes).map(dt => ({
        ...dt,
        dateObject: new Date(dt.date)
      })),
    [dateTimes]
  );

  return (
    <ul css={styles.list}>
      {sortedDateTimes.length
        ? sortedDateTimes.map(({ date, dateObject, time }) => (
            <li key={`${date} ${time}`}>
              <div>
                {t("calendar.reservationDate", {
                  weekday: dateObject.getDay(),
                  day: dateObject.getDate(),
                  month: dateObject.getMonth(),
                  time: formatTime(time)
                })}
              </div>
              <div>
                <a href="#" onClick={() => onRemove(date, time)}>
                  {t("reservation.remove")}
                  <IconWrapper name="mdiCloseCircle" size="1.5rem" />
                </a>
              </div>
            </li>
          ))
        : null}
    </ul>
  );
}
