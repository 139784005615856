import { css } from "@emotion/core";
import MenuServiceItem from "components/menu/item-service";
import MenuSection from "components/menu/section";
import { Formik } from "formik";
import actions from "lib/redux/actions";
import selectors from "lib/redux/selectors";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import Footer from "../footer";

const styles = {
  noMenuContainer: css({
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: '1rem',
    fontSize: '1.2rem',
  }),
  menuContainer: css({
    ".menu-section:last-of-type": {
      "& .menu-item-container:last-of-type": {
        "& .container": {
          borderBottom: "none",
        },
      },
    },
  }),
  buttonContinue: css({
    display: "flex",
    justifyContent: "space-around",
    "& .price-info": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "stretch",
      color: "white",
      ".price-before": {
        lineHeight: 1,
        textDecoration: "line-through",
        fontSize: "0.8em",
        marginRight: "10px",
      },
      ".price-after": {
        lineHeight: 1,
      },
    },
  }),
  footer: css({
    marginTop: '32px',
    paddingLeft: '12px',
    paddingRight: '12px',
  }),
};

const schema = yup.object({
  shoppingItemIds: yup.string().min(1).required(),
});

export default function FlowStepServiceCart({
  stepState,
  onFormikProps,
  updateStepState,
  onNextStep,
  onPrevStep,
  ...props
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const shoppingCart = useSelector(selectors.shoppingCart.shoppingCart);
  const shopMerchantServices = useSelector(selectors.shop.shopMerchantServices);
  const subCategoriesById = useSelector(selectors.categories.allSubCategoriesById);

  onFormikProps({ submitLabel: null });

  const handleReserve = useCallback(async (service) => {
    updateStepState({ serviceId: service.id });
    await dispatch(
      actions.shop.fetchShopCalendarSlots({
        shopMerchantServiceId: service.id,
      })
    );
    onNextStep();
  }, []);

  const subCategoryIds = useMemo(() => {
    const subCategoryIds = []
    shopMerchantServices.forEach(service => {
      if (!!service.mainSubCategory && !subCategoryIds.includes(service.mainSubCategory)) {
        subCategoryIds.push(service.mainSubCategory)
      }
    })
    return subCategoryIds
  }, [shopMerchantServices])

  const containsOnlineServices = useMemo(() => {
    const onlineServicesByCategory = subCategoryIds.map((subCategoryId) => {
      if (shopMerchantServices && shopMerchantServices.length === 0) {
        return []
      }

      return shopMerchantServices.filter((entry) =>
        entry.mainSubCategory === subCategoryId
        && entry.isMerchantServiceOnline
        && entry.bookingConfig
        && entry.bookingConfig.isOnline)
    })

    return onlineServicesByCategory.some(categoryServices => categoryServices.length > 0)
  }, [shopMerchantServices, subCategoryIds])

  if (!shopMerchantServices || !shopMerchantServices.length || !containsOnlineServices) {
    return (
      <div css={styles.noMenuContainer}>
        <div>{t("menu.noServices")}</div>
      </div>
    );
  }

  return (
    <Formik
      initialValues={{
        shoppingCart,
      }}
    >
      <div
        css={styles.menuContainer}
        className="service-listing"
      >
        {[null, ...subCategoryIds].map((subCategoryId) => {
          const categoryServices = shopMerchantServices.filter((entry) =>
            entry.mainSubCategory === subCategoryId
            && entry.isMerchantServiceOnline
            && entry.bookingConfig
            && entry.bookingConfig.isOnline)

          if (categoryServices && categoryServices.length > 0) {
            const subCategory = subCategoriesById.find(sC => parseInt(sC.subcategoryId, 10) === parseInt(subCategoryId, 10))
            const section = { name: subCategory ? subCategory.subcategoryName : '' }

            return (
              <MenuSection
                {...section}
                key={section.name}
                items={categoryServices}
                ItemElement={MenuServiceItem}
                onAction={handleReserve}
              />
            )
          }
        })}

        <div css={styles.footer}>
          <Footer />
        </div>
      </div>
    </Formik>
  );
}
