import React from "react";
import { css } from "@emotion/core";
import { IconWrapper } from "components/icons";
import cssVars from "styles/variables.module.scss";

const styles = {
  container: css({
    textAlign: "center",
    fontSize: "1.1rem",
    fontWeight: 500,
    marginBottom: "2rem",
    ".icon": {
      marginBottom: "0.5rem",
      svg: {
        fill: cssVars.primary
      }
    }
  })
};

export default function AuthTitle({ icon, children }) {
  return (
    <div className="autht-title" css={styles.container}>
      {icon && (
        <div className="icon">
          <IconWrapper name={icon} size="3.5rem" />
        </div>
      )}
      <div className="text">{children}</div>
    </div>
  );
}
