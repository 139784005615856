const SELECT_PAYMENT_METHOD = "SELECT_PAYMENT_METHOD"

const selectPaymentMethod = (paymentMethod) => {
  return {
    type: SELECT_PAYMENT_METHOD,
    payload: paymentMethod
  }
}

selectPaymentMethod.action = SELECT_PAYMENT_METHOD

export default selectPaymentMethod
