const STEP_ADDRESS_STATE = "STEP_ADDRESS_STATE"

const setAddressState = ({ data }) => {
  return {
    type: STEP_ADDRESS_STATE,
    payload: { data }
  }
}

setAddressState.action = STEP_ADDRESS_STATE

export default setAddressState
