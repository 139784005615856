import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import selectors from "lib/redux/selectors";
import AuthEmailSocial from "components/authentication/email-social";
import AuthLoginRegister from "components/authentication/login-register";
import ForgotPassword from "components/authentication/forgot-password";
import ReservationSummaryHeading from "components/elements/reservation-summary-heading";

const authSteps = {
  EMAIL_SOCIAL: "email-social",
  LOGIN_REGISTER: "login-register",
  FORGOT_PASSWORD: "forgot-password"
};

/**
 * Flow Step 2 - Authentication
 */
export default function FlowStepAuthentication({
  email,
  onFormikProps,
  onNextStep,
  onPrevStep
}) {
  const [emailAddress, setEmailAddress] = useState(email);
  const [existingUser, setExistingUser] = useState(false);
  const [authStep, setAuthStep] = useState(authSteps.EMAIL_SOCIAL);
  const [authStepProps, setAuthStepProps] = useState({});
  const [authStepState, setAuthStepState] = useState({
    [authSteps.EMAIL_SOCIAL]: {},
    [authSteps.LOGIN_REGISTER]: {},
    [authSteps.FORGOT_PASSWORD]: {}
  });
  const loggedIn = useSelector(selectors.user.loggedIn);

  const changeAuthStep = useCallback((newAuthStep, newAuthStepProps = {}) => {
    setAuthStepProps(newAuthStepProps);
    setAuthStep(newAuthStep);
  }, []);

  useEffect(() => {
    if (loggedIn) {
      // The user completed log in (Facebook or actual login or registration)
      // Move to the next step
      onNextStep();
    }
  }, [loggedIn]);

  const handleEmailComplete = useCallback(({ email, existingUser }) => {
    if (loggedIn) {
      // User logged in with facebook, login/register sub-step is not required
      onNextStep();
    } else if (email) {
      setAuthStepState(prev => ({
        ...prev,
        [authSteps.EMAIL_SOCIAL]: { email }
      }));
      setEmailAddress(email);
      setExistingUser(existingUser);
      changeAuthStep(authSteps.LOGIN_REGISTER);
    }

    // onNextStep();
    return false;
  }, []);

  const handleLoginRegisterComplete = useCallback(() => {
    // Don't move to the next step because we're detecting loggedIn status
    // changes in a useEffect above.
    return false;
  }, []);

  const handleForgotPasswordComplete = useCallback(({ email }) => {
    setEmailAddress(email);
    changeAuthStep(authSteps.LOGIN_REGISTER, { forgotPasswordComplete: true });
    return false;
  }, []);

  const components = {
    [authSteps.EMAIL_SOCIAL]: (
      <AuthEmailSocial
        onFormikProps={onFormikProps}
        onComplete={handleEmailComplete}
        {...authStepProps}
      />
    ),
    [authSteps.LOGIN_REGISTER]: (
      <AuthLoginRegister
        onBack={() => changeAuthStep(authSteps.EMAIL_SOCIAL)}
        isLogin={existingUser}
        email={authStepState[authSteps.EMAIL_SOCIAL].email}
        onFormikProps={onFormikProps}
        onForgotPassword={() => changeAuthStep(authSteps.FORGOT_PASSWORD)}
        onComplete={handleLoginRegisterComplete}
        {...authStepProps}
      />
    ),
    [authSteps.FORGOT_PASSWORD]: (
      <ForgotPassword
        onBack={() => changeAuthStep(authSteps.LOGIN_REGISTER)}
        email={emailAddress}
        onFormikProps={onFormikProps}
        onComplete={handleForgotPasswordComplete}
        {...authStepProps}
      />
    )
  };

  return (
    <div>
      <ReservationSummaryHeading onClick={onPrevStep} />
      {components[authStep]}
    </div>
  );
}
