import {usePrevious} from "../lib/hooks/use-previous"
import {useEffect} from "react"

/**
 * Alternative for FormikEffect component when using useFormik() instead of Formik component
 *
 * @param onChange
 * @param formik
 * @returns {null}
 */
export default function useFormikEffect(onChange, formik) {
  const { values } = formik
  const prevValues = usePrevious(values)

  useEffect(() => {
    // Don't run effect on form init
    if (prevValues) {
      onChange({ prevValues, nextValues: values, formik })
    }
  }, [values])

  return null
}
