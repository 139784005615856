import React, { useState } from "react";
import { css } from "@emotion/core";
import { useTranslation } from "react-i18next";
import { Button, Container, Form } from "react-bootstrap";
import { formatPrice, formatDuration } from "lib/helpers/utils";
import cssVars from "styles/variables.module.scss";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import Icon from "@mdi/react";

const styles = {
  container: css({
    ".container": {
      paddingLeft: "12px",
      paddingRight: "12px",
      borderBottom: `1px solid ${cssVars.gray500}`,
    },
    ".menu-item-wrapper": {
      display: "flex",
      alignItems: "flex-start",
      padding: "0.875rem 0",
    },
    ".naming-col": {
      flex: 1,
      ".name": {
        display: "flex",
        alignItems: "baseline",
        cursor: "pointer",
        fontSize: "1.1rem",
        fontWeight: 500,
        color: cssVars.black,
      },
      ".estimated-time": {
        fontSize: "0.85rem",
        marginTop: "0.6rem",
      },
    },
    ".end-cols": {
      paddingLeft: "0.5rem",
      ".price": {
        fontSize: "1.4rem",
        fontWeight: 500,
        color: cssVars.gray800,
      },
      ".add-button": {
        fontSize: "1.4rem",
        fontWeight: 500,
        border: "none",
        color: "white",
        backgroundColor: cssVars.primary,
      },
    },
    ".expand-icon": {
      marginLeft: "0.5rem",
      cursor: "pointer",
    },
  }),
};

export default function MenuItem({ item, onAction }) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const {
    serviceName,
    serviceDescription,
    price,
    estimatedServiceDurationSeconds,
    bookingConfig,
  } = item;

  const toggleDescription = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="menu-item-container" css={styles.container}>
      <Container>
        <div className="menu-item-wrapper">
          <div className="naming-col">
            <div
              className="name"
              onClick={toggleDescription}
            >
              {serviceName}
              {serviceDescription && (
                <div className="expand-icon">
                  <Icon
                    path={expanded ? mdiChevronUp : mdiChevronDown}
                    size={1}
                  />
                </div>
              )}
            </div>
            {estimatedServiceDurationSeconds && (
              <div className="estimated-time">
                {formatDuration(estimatedServiceDurationSeconds)}
              </div>
            )}
          </div>

          {bookingConfig && bookingConfig.isOnline && (
            <div className="end-cols">
              {price > 0 && (
                <div className="price">{formatPrice(price, "€")}</div>
              )}
              <Button className="reserve-button" onClick={() => onAction(item)}>
                {t("services.reserve")}
              </Button>
            </div>
          )}
        </div>
        {serviceDescription && (
          <div
            className="service-description"
            style={{
              maxHeight: expanded ? "2400px" : 0, // Set maxHeight to a large value when expanded, 0 otherwise
            }}
            dangerouslySetInnerHTML={{ __html: serviceDescription }}
          />
        )}
      </Container>
    </div>
  );
}
