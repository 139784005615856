import api from "lib/api";

const CAMPAIGN_GET_HIPAY_PAYMENT_LINK = "CAMPAIGN_GET_HIPAY_PAYMENT_LINK";

const getHiPayPaymentLink = payload => (dispatch, getState) => {
  const state = getState();

  if (state.context.allowGuestLogin && !state.user.token) {
    return dispatch({
      type: CAMPAIGN_GET_HIPAY_PAYMENT_LINK,
      payload: api.campaign.getGuestVoucherHiPayPaymentLink({
        clientApplication: state.context.clientPlatform || state.context.clientApplication,
        providerId: state.context.providerId,
        channelManager: state.context.channelManager,
        language: state.context.language,
        payload
      })
    });
  }

  return dispatch({
    type: CAMPAIGN_GET_HIPAY_PAYMENT_LINK,
    payload: api.campaign.getVoucherHiPayPaymentLink({
      userId: state.user.id,
      token: state.user.token,
      clientApplication: state.context.clientPlatform || state.context.clientApplication,
      language: state.context.language,
      payload
    })
  });
};
getHiPayPaymentLink.action = CAMPAIGN_GET_HIPAY_PAYMENT_LINK;

export default getHiPayPaymentLink;
