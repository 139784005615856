import { css } from "@emotion/core";
import MenuSection from "components/menu/section";
import MenuCampaignItem from "components/menu/item-campaign";
import { Formik } from "formik";
import { formatPrice } from "lib/helpers/utils";
import actions from "lib/redux/actions";
import selectors from "lib/redux/selectors";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

const styles = {
  noMenuContainer: css({
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  menuContainer: css({
    ".menu-section:last-of-type": {
      "& .menu-item-container:last-of-type": {
        "& .container": {
          borderBottom: "none",
        },
      },
    },
  }),
  buttonContinue: css({
    display: "flex",
    justifyContent: "space-around",
    "& .price-info": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "stretch",
      color: "white",
      ".price-before": {
        lineHeight: 1,
        textDecoration: "line-through",
        fontSize: "0.8em",
        marginRight: "10px",
      },
      ".price-after": {
        lineHeight: 1,
      },
    },
  }),
};

const schema = yup.object({
  shoppingItemIds: yup.string().min(1).required(),
});

export default function FlowStepMenu({
  stepState,
  onFormikProps,
  updateStepState,
  onNextStep,
  onPrevStep,
  ...props
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const shoppingCart = useSelector(selectors.shoppingCart.shoppingCart);
  const { currency } = useSelector(selectors.product.info);
  const priceListView = useSelector(selectors.product.priceListView);
  const { sections } = priceListView || {};
  const { itemsTotal, final } = useSelector(selectors.shoppingCart.pricing);
  const hasItems = !!shoppingCart.length;

  const handleValidSubmit = async () => {
    onNextStep();
    return false;
  };

  const addToCart = useCallback((item) => {
    dispatch(actions.shoppingCart.addItemToCart(item));
  }, []);

  if (!sections) {
    return (
      <div css={styles.noMenuContainer}>
        <div>{t("menu.notAvailable")}</div>
      </div>
    );
  }

  return (
    <Formik
      onSubmit={handleValidSubmit}
      initialValues={{
        shoppingCart,
      }}
    >
      {({ submitForm, isSubmitting, isValid }) => {
        onFormikProps({
          submitForm,
          isValid: hasItems,
          isSubmitting,
          submitLabel: (
            <div css={styles.buttonContinue}>
              <div>{t("flow.viewOrder")}</div>
              {hasItems && (
                <div className="price-info">
                  {itemsTotal !== final && (
                    <div className="price-before">
                      {formatPrice(itemsTotal, currency)}
                    </div>
                  )}
                  <div className="price-after">
                    {formatPrice(final, currency)}
                  </div>
                </div>
              )}
            </div>
          ),
        });
        return (
          <div css={styles.menuContainer}>
            {sections.map((section) => (
              <MenuSection
                {...section}
                ItemElement={MenuCampaignItem}
                onAction={addToCart}
              />
            ))}
          </div>
        );
      }}
    </Formik>
  );
}
