module.exports = ({ fetch }) => {
  return {
    order: ({ token, sessionId, payload, clientApplication, entityId, language, channel }) =>
      fetch(`/mygon-middleware/rest/v1/web/orders`, {
        method: "post",
        queryParams: { clientApplication, entityId, token, sessionId, channel, language },
        body: JSON.stringify(payload),
      }),
    guestOrder: ({ payload, sessionId, clientApplication, entityId, language, channel }) =>
      fetch(`/mygon-middleware/rest/v1/web/orders/guest`, {
        method: "post",
        queryParams: { clientApplication, entityId, sessionId, channel, language },
        body: JSON.stringify(payload),
      }),
  };
};
