import React, { useState } from "react";
import { css } from "@emotion/core";
import { Container } from "react-bootstrap";
import { IconWrapper } from "components/icons";
import cssVars from "styles/variables.module.scss";

const styles = {
  container: css({
    ".section-head": {
      position: "sticky",
      top: "-1px",
      ".container": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: `1px solid ${cssVars.gray500}`,
        backgroundColor: cssVars.gray300,
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        ".name": {
          fontSize: "1.1rem",
        },
      },
    },
  }),
};

export default function MenuSection({ name, items = [], ItemElement, ...props }) {
  const [expanded, setExpanded] = useState(true);

  return (
    <div className="menu-section" css={styles.container}>
      {name && (
        <div className="section-head">
          <Container onClick={() => setExpanded((current) => !current)}>
            <div className="name">{name}</div>
            <IconWrapper
              name={expanded ? "mdiChevronUp" : "mdiChevronDown"}
              size="1.5rem"
            />
          </Container>
        </div>
      )}
      {expanded && (
        <div className="items">
          {items.map((item) => (
            <ItemElement
              {...props}
              item={item}
              key={item.id}
            />
          ))}
        </div>
      )}
    </div>
  );
}
