import React, { useEffect, useCallback, useState } from "react";
import { Nav } from "react-bootstrap";
import { css } from "@emotion/core";
import {useDispatch, useSelector} from "react-redux";
import { IconWrapper } from "components/icons";
import cssVars from "styles/variables.module.scss";
import { useTranslation } from "react-i18next";
import { ReservationModes } from "lib/helpers/constants";
import { formatPrice, stringPriceToFloat } from "lib/helpers/utils";
import selectors from "lib/redux/selectors";
import actions from "lib/redux/actions";
import { mq } from "styles";
import BookDate from "./book-date";
import BuyVoucher from "./buy-voucher";

const modeComponents = {
  [ReservationModes.BOOK_DATE]: BookDate,
  [ReservationModes.BUY_VOUCHER]: BuyVoucher,
};

const styles = {
  tabsContainer: css({
    display: "flex",
    alignItems: "stretch",
    borderBottom: `1px solid ${cssVars.gray500}`,
    ".nav-item": {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "stretch",
    },
    ".nav-link": {
      display: "flex",
      flex: 1,
      alignItems: "center",
      padding: ["0.7rem 0.5rem", "0.7rem 0.5rem"],
    },
  }),
  tab: css(
    mq({
      ".nav-link": {
        display: "flex",
        justifyContent: ["center"],
      },
      ".content": {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
      },
      ".icon-wrapper": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: ["0.3rem", "1rem"],
        marginTop: ['0', '0.1rem'],
        fill: cssVars.gray600,
        width: ["1.3rem", "1.5rem"],
        height: ["1.3rem", "1.5rem"],
      },
      ".active .icon-wrapper": {
        fill: cssVars.primary,
      },
      ".tab-title": {
        color: cssVars.gray900,
        fontSize: ["1.1rem", "1.25rem"],
        fontWeight: 500,
        display: "flex",
        flexWrap: ["wrap", "no-wrap"],
        ".title": {
          whiteSpace: "nowrap",
          marginBottom: "0.1rem",
          marginRight: "0.6rem",
        },
      },
      ".active .tab-title": {
        color: cssVars.primary,
      },
      ".tab-subtitle": {
        color: cssVars.gray600,
        fontSize: ["0.8rem", "1rem"],
        textAlign: ["left", "center"],
      },
      ".premium": {
        "&.nav-link, &.nav-link.active:hover": {
          borderColor: cssVars.goldPremium,
        },
        ".tab-title": {
          color: cssVars.goldPremium
        },
        ".icon-wrapper": {
          fill: cssVars.goldPremium,
        },
      }
    })
  ),
};

/**
 * Flow Step 0 for Campaign reservations
 */
export default function FlowStep1({
  stepState,
  updateStepState,
  onPrevStep,
  onNextStep,
  ...props
}) {
  const { t } = useTranslation();
  const serviceId = useSelector(selectors.flow.serviceId);
  const isTransported = useSelector(selectors.flow.isTransported);
  const campaign = useSelector((state) => state.campaign);
  const context = useSelector((state) => state.context);
  const canBuyVoucher = context.canBuyVoucher && useSelector(selectors.campaign.canBuyVoucher);
  const canBookDate = context.canBookDate && (!!serviceId || useSelector(selectors.campaign.canBookDate));
  const { value: giftVoucherExtraDiscount } = useSelector(selectors.campaign.giftVoucherExtraDiscount);
  const { value: premiumVoucherExtraDiscount } = useSelector(selectors.campaign.premiumVoucherExtraDiscount);
  const defaultView = useSelector(selectors.flow.defaultView);
  const isPremiumReservation = useSelector(selectors.flow.isPremiumReservation);
  const dispatch = useDispatch();

  // if default view is premium, and voucher is available, open voucher mode
  // if we can't buy voucher - open free booking
  let defaultMode
  if (defaultView === 'premium') {
    if (canBuyVoucher) {
      defaultMode = ReservationModes.BUY_VOUCHER
    } else {
      defaultMode = ReservationModes.BOOK_DATE
    }
  } else {
    defaultMode = canBookDate && defaultView !== "voucher"
      ? ReservationModes.BOOK_DATE
      : ReservationModes.BUY_VOUCHER;
  }
  const [mode, setMode] = useState(stepState.mode || defaultMode);
  const { campaignType, offerPrice } = (campaign && campaign.info.campaignView) || {};
  const [offerPriceF] = stringPriceToFloat(offerPrice);

  // for free reservation, if campaign_type 5, and min_party_size and max_party_size
  if (campaign.info && campaign.info.campaignView) {
    const {
      minNumberPeople,
      maxNumberPeople,
    } = campaign.info.campaignView
    if (defaultView !== 'voucher' && defaultView !== 'premium') {
      if (campaignType === 5 && minNumberPeople === 1 && maxNumberPeople === 1) {
        dispatch(actions.flow.setBackButtonState(false))
        onNextStep()
      }
    }
  }

  // Save form state continuously so that we can recover state if we change the
  // mode
  const handleFormStateChange = useCallback(
    (state) => {
      updateStepState({
        ...stepState,
        mode,
        [mode]: {
          ...stepState[mode],
          ...state,
        },
      });
    },
    [updateStepState, stepState, mode]
  );

  const handleChangeMode = useCallback(
    (newMode) => {
      setMode(newMode);
      updateStepState({
        ...stepState,
        mode: newMode,
      });
    },
    [stepState, updateStepState]
  );

  const ModeComponent = modeComponents[mode];

  return (
    <div>
      {!isTransported && (
        <Nav
          variant="tabs"
          defaultActiveKey={mode}
          activeKey={mode}
          onSelect={handleChangeMode}
          css={styles.tabsContainer}
        >
          {mode === 'book' ? (
            <Nav.Item css={styles.tab}>
              <Nav.Link
                href="#"
                eventKey={ReservationModes.BOOK_DATE}
                className={isPremiumReservation ? 'premium' : ''}
              >
                <div className="content">
                  <IconWrapper name="calendar"/>
                  <div>
                    <div className="tab-title">
                      {isPremiumReservation ? (
                          <span className="title">{t("reservation.bookDatePremium")}</span>
                        ) : (
                          <span className="title">{t("reservation.bookDate")}</span>
                      )}
                      {!!giftVoucherExtraDiscount && canBuyVoucher && (
                        <span className="price">
                          {formatPrice(offerPriceF, "€", {
                            minFractionDigits: 0,
                          })}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </Nav.Link>
            </Nav.Item>
          ) : (
            <Nav.Item css={styles.tab}>
              <Nav.Link
                href="#"
                eventKey={ReservationModes.BUY_VOUCHER}
                disabled={!(canBuyVoucher || isTransported)}
                className={isPremiumReservation ? 'premium' : ''}
              >
                <div className="content">
                  <IconWrapper name="voucher" />
                  <div>
                    <div className="tab-title">

                      {isPremiumReservation ? (
                        <>
                          <span className="title">{t("reservation.buyPremiumVoucher")}</span>
                          {!!premiumVoucherExtraDiscount && (
                            <span className="price">
                              {formatPrice(
                                offerPriceF - premiumVoucherExtraDiscount,
                                "€",
                                {
                                  minFractionDigits: 2,
                                }
                              )}
                            </span>
                          )}
                        </>
                      ) : (
                        <>
                          <span className="title">{t("reservation.buyVoucher")}</span>
                          {!!giftVoucherExtraDiscount && (
                            <span className="price">
                              {formatPrice(
                                offerPriceF - giftVoucherExtraDiscount,
                                "€",
                              {
                                minFractionDigits: 0,
                              }
                            )}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
      )}
      <ModeComponent
        onFormStateChange={handleFormStateChange}
        onNextStep={onNextStep}
        {...props}
        {...stepState[mode]}
      />
    </div>
  );
}
