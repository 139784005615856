import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { css } from "@emotion/core";
import { IconWrapper } from "components/icons";
import { useTranslation, Trans } from "react-i18next";
import selectors from "lib/redux/selectors";
import { formatPrice, stringPriceToFloat } from "lib/helpers/utils";
import { ReservationModes, TransportInstants } from "lib/helpers/constants";
import { dateTimesObjectToOrderedArray } from "lib/helpers/calendar";
import { mq } from "styles";
import cssVars from "styles/variables.module.scss";
import { getVoucherPrice } from "lib/helpers/voucher-utils";

const styles = {
  wrapper: css(
    mq({
      fontWeight: "bold",
      fontSize: ["0.8rem", "1.1rem"],
      ".container": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      a: {
        textDecoration: "none",
        color: cssVars.primary,
      },
      ".price-container": {
        textAlign: "right",
      },
      ".price-before": {
        color: cssVars.gray500,
        textDecoration: "line-through",
        fontSize: "0.8em",
      },
      ".icon-container": {
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        color: "inherit",
        ".icon-wrapper": {
          width: ["1.4rem", "1.7rem"],
          height: ["1.4rem", "1.7rem"],
        },
        ".icon-wrapper, svg": {
          marginRight: "0.5rem",
          fill: cssVars.primary,
        },
      },
    })
  ),
  voucherCalcTotal: css({
    span: {
      marginLeft: "0.3rem",
    },
    ".extra-discount": {
      marginLeft: 0,
      color: cssVars.primary,
    },
  }),
};

export default function ReservationSummaryHeading({ onClick }) {
  const { t } = useTranslation();
  const { transportType, mode, book, voucher } = useSelector(
    (state) => state.flow.steps
  );
  const { date, dateTimes, transportInstant } = book;
  const { bookingType } = useSelector(selectors.product.info);
  const isTransported = useSelector(selectors.flow.isTransported);
  const prizes = useSelector(selectors.user.prizes);
  const selectedPrize = prizes.find(p => p.id === voucher.prizeId);

  const {
    shoppingCartFinalPrice,
    currency,
    offerPrice,
    priceBefore,
    discount,
  } = useSelector(selectors.flow.selectedPriceInfo);

  const { value: giftVoucherExtraDiscount } = useSelector(selectors.campaign.giftVoucherExtraDiscount);
  const { value: premiumVoucherExtraDiscount } = useSelector(selectors.campaign.premiumVoucherExtraDiscount);
  const isPremiumReservation = useSelector(selectors.flow.isPremiumReservation)
  const [offerPriceF, priceBeforeF] = stringPriceToFloat(
    offerPrice,
    priceBefore
  );
  const showPrices = !!offerPriceF;

  const voucherDiscount = isPremiumReservation ? premiumVoucherExtraDiscount : giftVoucherExtraDiscount
  const { canUsePrize, finalDiscount, finalPrice } = getVoucherPrice(offerPriceF, voucherDiscount, selectedPrize)

  const isBookDate = mode === ReservationModes.BOOK_DATE;

  const dateTimesArray = useMemo(
    () => dateTimesObjectToOrderedArray(dateTimes),
    [dateTimes]
  );
  const firstDateTime = dateTimesArray[0];

  const multipleSelectionsButHasOnlyOne =
    bookingType === 6 && dateTimesArray.length === 1;

  let dateString;
  if (isBookDate) {
    if (bookingType === 3) {
      // Single day choice and no time choice
      const d = new Date(date);
      dateString = dateString = t("summaryBanner.whenDay", {
        day: d.getDate(),
        month: t(`calendar.monthsShort.${d.getMonth()}`),
      });
    } else if (
      [1, 2].includes(bookingType) ||
      multipleSelectionsButHasOnlyOne
    ) {
      // Show the only day and time choice
      const d = new Date(firstDateTime.date);
      dateString = t("summaryBanner.whenDayTime", {
        day: d.getDate(),
        month: t(`calendar.monthsShort.${d.getMonth()}`),
        time: firstDateTime.time,
      });
    } else if (bookingType === 7) {
      if (transportInstant === TransportInstants.SCHEDULED) {
        const d = new Date(firstDateTime.date);
        dateString = t(`summaryBanner.transport.${transportInstant}`, {
          transportType,
          transportInstant,
          day: d.getDate(),
          month: t(`calendar.monthsShort.${d.getMonth()}`),
          time: firstDateTime.time,
        });
      } else {
        dateString = t(`summaryBanner.transport.${transportInstant}`, {
          transportType,
          transportInstant,
        });
      }
    } else if (bookingType === 6) {
      // Show the only/first day and time choice
      dateString = t("summaryBanner.toBeScheduled");
    }
  }

  const voucherCalcTotalElement =
    showPrices && !isBookDate ? (
      <span key="voucher-calc-total" css={styles.voucherCalcTotal}>
        <span>x</span>
        {finalDiscount > 0 ? (
          <>
            <span>
              ({formatPrice(offerPriceF, currency)}
              {" - "}
              <span className="extra-discount">
                {formatPrice(finalDiscount, currency, {
                  minFractionDigits: 0,
                })}
              </span>
              )
            </span>
          </>
        ) : (
          <span>{formatPrice(offerPriceF, currency)}</span>
        )}
        <span>=</span>
        <span>
          {formatPrice(voucher.numberOfPeople * finalPrice, currency)}
        </span>
      </span>
    ) : null;

  let pricingElement;

  if (shoppingCartFinalPrice) {
    pricingElement = (
      <div className="price-container">
        <div>
          <a href="#" className="price-after" onClick={onClick}>
            {formatPrice(shoppingCartFinalPrice, currency)}
          </a>
        </div>
      </div>
    );
  } else if (!voucherCalcTotalElement) {
    if (showPrices) {
      pricingElement = (
        <div className="price-container">
          <div>
            <a href="#" className="price-after" onClick={onClick}>
              {formatPrice(offerPriceF, currency)}
            </a>
          </div>
          {priceBeforeF && (
            <div>
              <a href="#" className="price-before" onClick={onClick}>
                {formatPrice(priceBeforeF, currency)}
              </a>
            </div>
          )}
        </div>
      );
    } else if (discount) {
      pricingElement = (
        <a href="#" className="discount" onClick={onClick}>
          {discount}%
        </a>
      );
    }
  }

  return (
    <div
      className="reservation-summary-wrapper lightgray-container"
      css={styles.wrapper}
    >
      <Container>
        {isBookDate && dateString && (
          <a href="#" className="icon-container when" onClick={onClick}>
            <IconWrapper name="calendar" />
            {dateString}
          </a>
        )}
        {!isTransported && (
          <a
            href="#"
            className="icon-container numberOfPeople"
            onClick={onClick}
          >
            {isBookDate ? (
              <>
                <IconWrapper name="people" />
                {t("summaryBanner.numberOfPeople", {
                  count: book.numberOfPeople,
                })}
              </>
            ) : (
              <>
                <IconWrapper name="mdiTicketPercent" size="1.7rem" />
                <Trans
                  i18nKey="summaryBanner.numberOfVouchers"
                  count={voucher.numberOfPeople}
                >
                  {[voucherCalcTotalElement]}
                </Trans>
              </>
            )}
          </a>
        )}
        {pricingElement}
      </Container>
    </div>
  );
}
