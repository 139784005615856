import api from "lib/api";

const PAYMENT_GET_MBWAY_STATUS = "PAYMENT_GET_MBWAY_STATUS";

const getHipayMbwayPaymentStatus = () => (dispatch, getState) => {
  const state = getState();

  if (state.context.allowGuestLogin && !state.user.token) {
    return dispatch({
      type: PAYMENT_GET_MBWAY_STATUS,
      payload: api.payment.getGuestHipayMbwayPaymentStatus({
        paymentId: state.payment.payment.id,
      })
    });
  }

  return dispatch({
    type: PAYMENT_GET_MBWAY_STATUS,
    payload: api.payment.getHipayMbwayPaymentStatus({
      paymentId: state.payment.payment.id,
      token: state.user.token,
    })
  });
};

getHipayMbwayPaymentStatus.action = PAYMENT_GET_MBWAY_STATUS;

export default getHipayMbwayPaymentStatus;
