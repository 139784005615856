import api from "lib/api"

const ADDRESSES_FETCH = "ADDRESSES_FETCH"

const fetchAddresses = () => (dispatch, getState) => {
  const state = getState()

  return dispatch({
    type: ADDRESSES_FETCH,
    payload: api.addresses.getAddresses({
      language: state.context.language,
      token: state.user.token,
    }),
  })
}

fetchAddresses.action = ADDRESSES_FETCH

export default fetchAddresses
