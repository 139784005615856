import api from "lib/api"

const PICKUP_POINTS_FETCH = "PICKUP_POINTS_FETCH"

const fetchPickupPoints = ({ shopDeliveryMethodIds, latitude, longitude }) => (dispatch, getState) => {
  const state = getState()

  return dispatch({
    type: PICKUP_POINTS_FETCH,
    payload: api.deliveries.getPickupPoints({
      language: state.context.language,
      entityId: state.entity.entityId,
      token: state.user.token,
      shopDeliveryMethodIds,
      latitude,
      longitude,
    }),
  })
}

fetchPickupPoints.action = PICKUP_POINTS_FETCH

export default fetchPickupPoints
