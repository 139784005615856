import api from "lib/api"

const ENTITY_CONFIGURATION_FETCH = "ENTITY_CONFIGURATION_FETCH"

const fetchEntityConfiguration = () => (dispatch, getState) => {
  const state = getState()

  const language = state.context.language
  const hostname = window.location.hostname

  if (hostname.includes('localhost')) {
    return
  }

  return dispatch({
    type: ENTITY_CONFIGURATION_FETCH,
    payload: api.entity.getConfiguration({
      language,
      hostname,
    }),
  })
}

fetchEntityConfiguration.action = ENTITY_CONFIGURATION_FETCH

export default fetchEntityConfiguration
