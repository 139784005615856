import React, { useCallback, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { css } from "@emotion/core";
import { Container, Col, Row, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import withErrorModal from "lib/hocs/with-error-modal";
import { triggerEvent } from "lib/analytics";
import { dateTimesInTheFutureAtLeast } from "lib/helpers/calendar";
import { getPreselectedNumberOfPeople } from "lib/helpers/utils";
import { TransportInstants, AllTransportInstants } from "lib/helpers/constants";
import selectors from "lib/redux/selectors";
import InputIncrementDecrement from "components/elements/input-increment-decrement";
import PrizeSelector from "components/elements/prize-selector";
import FormikEffect from "components/formik-effect";
import Calendar from "components/calendar";
import Footer from "../footer";

const MIN_DATE_TIME_SELECTIONS_CAMPAIGN_6 = 3;
const MAX_DATE_TIME_SELECTIONS = 10;

const styles = {
  numberOfPeopleRow: css({
    display: "flex",
    alignItems: "center",
    label: {
      margin: "0 1rem 0 0",
      flex: 1,
    },
  }),
  transportInstant: css({
    listStyle: "none",
    padding: 0,
    margin: 0,
    ".item-container": {
      minHeight: "2.5rem",
    },
  }),
};

const dateSchema = yup.string().matches(/^\d{4}-\d{2}-\d{2}$/);
const dateTimesSchema = (minSelections, maxSelections) => {
  const schema = yup
    .array(
      yup.object({
        date: yup.string().matches(/^\d{4}-\d{2}-\d{2}$/),
        times: yup.array(
          yup.object({
            time: yup.string().matches(/^\d{2}:\d{2}$/),
            selectedAt: yup.number().min(0),
          })
        ),
      })
    )
    .test("min", "Invalid number of time slots", function (values) {
      const selectionCount = values.reduce((accum, dateTimes) => {
        return accum + dateTimes.times.length;
      }, 0);
      if (selectionCount >= minSelections) {
        return true;
      }
      const analyticsLabels = ["zero options", "one option", "two options"];
      const analyticsLabel =
        analyticsLabels[selectionCount] || `${selectionCount} options`;
      return this.createError({ params: { analyticsLabel } });
    })
    .test("max", "Invalid number of time slots", (values) => {
      const selectionCount = values.reduce((accum, dateTimes) => {
        return accum + dateTimes.times.length;
      }, 0);
      return selectionCount <= maxSelections;
    })
    .test(
      "time-threshold",
      "No time entry more than 24 in the future",
      (values) => {
        if (minSelections <= 1) {
          return true;
        }
        const foundMatch = values.find((dateTimes) => {
          return dateTimesInTheFutureAtLeast(dateTimes, 24);
        });
        return !!foundMatch;
      }
    )
    .required();

  return schema;
};

/**
 * Flow Step 0 for Campaign reservations
 */
function BookDate({
  // Form status
  transportInstant,
  prizeId,
  numberOfPeople,
  selectedDayIsAvailable,
  date,
  dateTimes,
  onFormikProps,
  onFormStateChange,
  onNextStep,
  showModal,
}) {
  const { t } = useTranslation();
  const serviceId = useSelector(selectors.flow.serviceId);
  const merchantNotes = useSelector(selectors.flow.bookingMerchantNotes)
  const isTransported = useSelector(selectors.flow.isTransported);
  const enablePrizeSelection = false;
  const minimumMinutesBeforeBooking = useSelector(
    serviceId ? () => 0 : selectors.campaign.minimumMinutesBeforeBooking
  );
  const {
    productTitle,
    minNumberPeople,
    maxNumberPeople,
    bookingType,
  } = useSelector(selectors.product.info);

  const defaultNumberOfPeople = useSelector(selectors.product.defaultNumberOfPeople);
  const isInsideWorkingHours = useSelector(serviceId ? () => false : selectors.campaign.isInsideWorkingHours);
  const canShowAsap = minimumMinutesBeforeBooking <= 60 && isInsideWorkingHours;
  const transportInstantDef =
    transportInstant ||
    (canShowAsap ? TransportInstants.ASAP : TransportInstants.SCHEDULED);

  const { currency, offerPrice, priceBefore, discount } = useSelector(selectors.flow.selectedPriceInfo);

  const isTransportScheduled = transportInstantDef === TransportInstants.SCHEDULED;

  const handleValidSubmit = (values) => {
    triggerEvent("Purchase", "choose_day_hour_people", productTitle);
    onNextStep();
    return false;
  };

  const handleFormChange = useCallback(
    ({ nextValues }) => {
      onFormStateChange(nextValues);
    },
    [onFormStateChange]
  );

  const handlePrizeChange = useCallback(
    (prizeId) => {
      onFormStateChange({ prizeId });
    },
    [onFormStateChange]
  );

  const showBookingStartNote = !!merchantNotes && !!merchantNotes.bookingStartNote

  const showCalendar =
    [1, 2, 3, 6, 7].includes(bookingType) &&
    (!isTransported || isTransportScheduled);
  const showTimetable = [1, 2, 6, 7].includes(bookingType);
  const selectJustDay = showCalendar && !showTimetable;

  const minSelections = useMemo(() => {
    return bookingType === 6 ? MIN_DATE_TIME_SELECTIONS_CAMPAIGN_6 : 1;
  }, [bookingType]);

  const maxSelections = useMemo(() => {
    if ([1, 2, 7].includes(bookingType)) {
      return 1;
    } else if (bookingType === 6) {
      return MAX_DATE_TIME_SELECTIONS;
    }
  }, [bookingType]);

  const preSelectedNumberPeople = numberOfPeople || defaultNumberOfPeople;

  useEffect(function updateInitialStepState() {
    // Trigger a flow step state change because the preselected number of
    // people might never be changed by the user and would thus be null
    onFormStateChange({
      numberOfPeople: preSelectedNumberPeople,
      transportInstant: transportInstantDef,
    });
  }, []);

  const schema = useMemo(
    () =>
      yup.object({
        transportInstant: isTransported
          ? yup.string().oneOf(AllTransportInstants).required()
          : undefined,
        numberOfPeople: !isTransported
          ? yup
              .number()
              .integer()
              .min(minNumberPeople)
              .max(maxNumberPeople)
              .required()
          : undefined,
        selectedDayIsAvailable: selectJustDay
          ? yup.boolean().oneOf([true])
          : undefined,
        date: selectJustDay ? dateSchema : undefined,
        dateTimes:
          showCalendar && showTimetable
            ? dateTimesSchema(minSelections, maxSelections)
            : undefined,
      }),
    [
      isTransported,
      showCalendar,
      showTimetable,
      minSelections,
      maxSelections,
      minNumberPeople,
      maxNumberPeople,
    ]
  );

  return (
    <div>
      {!isTransported && enablePrizeSelection && (
        <PrizeSelector prizeId={prizeId} onPrizeIdChange={handlePrizeChange} title={t("reservation.usePrizesTitle")} />
      )}
      <Formik
        validateOnMount
        validationSchema={schema}
        onSubmit={handleValidSubmit}
        initialValues={{
          transportInstant: transportInstantDef,
          numberOfPeople: preSelectedNumberPeople,
          selectedDayIsAvailable,
          date,
          dateTimes,
        }}
      >
        {({
          handleSubmit,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          submitForm,
          isSubmitting,
          values,
          touched,
          errors,
        }) => {
          onFormikProps({
            submitForm: () => {
              triggerEvent(
                "Purchase",
                "Click_continue_booking_widget_first_step"
              );

              // Check if the form is valid when submitting so we can show
              // a special modal error. I know this is weird since Formik
              // validates the schema as well, but I couldn't find another way
              // to easily check if a form submission was invalid.
              schema
                .validate(values)
                .then(() => {
                  submitForm();
                })
                .catch((err) => {
                  // Assume the error is regarding the dates because the
                  // increment/decrement input only assumes valid values
                  const { path, type } = err;
                  if (selectJustDay) {
                    showModal(t("forms.errors.dateSelectionMissingDate"));
                  } else if (maxSelections === 1) {
                    showModal(t("forms.errors.dateSelectionMissingDateTime"));
                  } else if (path === "dateTimes") {
                    if (type === "min") {
                      showModal(
                        t("forms.errors.dateSelectionNotEnoughDateTimes")
                      );
                      triggerEvent(
                        "Purchase",
                        "error_not_enough_booking_options",
                        err.params.analyticsLabel
                      );
                    } else if (type === "time-threshold") {
                      showModal(
                        t("forms.errors.dateSelectionNotEnoughDateTimes")
                      );
                      triggerEvent(
                        "Purchase",
                        "error_not_enough_booking_options",
                        "<24 hour difference"
                      );
                    } else {
                      showModal(
                        t("forms.errors.dateSelectionMissingDateTimes")
                      );
                    }
                  }
                });
            },
            // Consider always valid so that we allow clicking the "next step"
            // button to trigger a validation
            isValid: true,
            submitLabel: t("flow.continue"),
            isSubmitting,
          });
          return (
            <Container>
              <FormikEffect onChange={handleFormChange} />
              <Form
                noValidate
                className="form padded-step-container"
                onSubmit={handleSubmit}
              >
                {isTransported && (
                  <Form.Group>
                    <Form.Label className="medium-text">
                      {t("reservation.chooseTransportInstantTitle")}
                    </Form.Label>
                    <ul
                      className="transport-instant-items"
                      css={styles.transportInstant}
                    >
                      {canShowAsap && (
                        <li>
                          <div className="item-container">
                            <div>
                              <Form.Check
                                custom
                                type="radio"
                                id={TransportInstants.ASAP}
                                name="transportInstant"
                                label={t(`transport.${TransportInstants.ASAP}`)}
                                checked={
                                  values.transportInstant ===
                                  TransportInstants.ASAP
                                }
                                onChange={() => {
                                  setFieldTouched("transportInstant");
                                  setFieldValue(
                                    "transportInstant",
                                    TransportInstants.ASAP
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </li>
                      )}
                      <li>
                        <div className="item-container">
                          <div>
                            <Form.Check
                              custom
                              type="radio"
                              id={TransportInstants.SCHEDULED}
                              name="transportInstant"
                              label={t(
                                `transport.${TransportInstants.SCHEDULED}`
                              )}
                              checked={
                                values.transportInstant ===
                                TransportInstants.SCHEDULED
                              }
                              onChange={() => {
                                setFieldTouched("transportInstant");
                                setFieldValue(
                                  "transportInstant",
                                  TransportInstants.SCHEDULED
                                );
                              }}
                            />
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Form.Group>
                )}
                {showBookingStartNote && (
                  <p className="merchant-notes">{merchantNotes.bookingStartNote}</p>
                )}
                {!isTransported && (
                  <Form.Group css={styles.numberOfPeopleRow}>
                    <Form.Label className="medium-text">
                      {t("reservation.numberOfPeople")}
                    </Form.Label>
                    <div>
                      <InputIncrementDecrement
                        noValidate
                        name="numberOfPeople"
                        min={minNumberPeople}
                        max={maxNumberPeople}
                        defaultValue={preSelectedNumberPeople}
                        onValueChange={(value) => {
                          setFieldTouched("numberOfPeople");
                          setFieldValue("numberOfPeople", value);
                        }}
                        value={values.numberOfPeople}
                        onBlur={handleBlur}
                        isInvalid={
                          touched.numberOfPeople && !!errors.numberOfPeople
                        }
                      />
                    </div>
                  </Form.Group>
                )}
                {showCalendar && (
                  <Form.Group>
                    {maxSelections > 1 ? (
                      <div className="medium-text">
                        {t("reservation.multipleDatesRequireConfirmation")}
                      </div>
                    ) : null}
                    <Calendar
                      showTimetable={showTimetable}
                      maxSelections={maxSelections}
                      date={values.date}
                      dateTimes={values.dateTimes}
                      pricing={{
                        currency,
                        offerPrice,
                        priceBefore,
                        discount,
                      }}
                      onDateChange={async (
                        date,
                        availableDay,
                        allowAutoSubmit
                      ) => {
                        setFieldTouched("date");
                        setFieldValue("date", date);
                        setFieldValue("selectedDayIsAvailable", availableDay);
                        if (availableDay && allowAutoSubmit && !showTimetable) {
                          // Campaign type 3, only date without time selection
                          await Promise.resolve();
                          triggerEvent(
                            "Purchase",
                            "Click_continue_booking_widget_first_step"
                          );
                          submitForm();
                        }
                      }}
                      onDateTimesChange={async (dateTimes) => {
                        setFieldTouched("dateTimes");
                        setFieldValue("dateTimes", dateTimes);
                        if (maxSelections === 1) {
                          // Auto-submit form to advance to the next step
                          // if we can only select a single time slot
                          // Workaround for: https://github.com/jaredpalmer/formik/issues/529
                          await Promise.resolve();
                          triggerEvent(
                            "Purchase",
                            "Click_continue_booking_widget_first_step"
                          );
                          submitForm();
                        }
                      }}
                    />
                  </Form.Group>
                )}
              </Form>

              <div className="padded-step-container">
                <Footer />
              </div>
            </Container>
          );
        }}
      </Formik>
    </div>
  );
}

export default withErrorModal(BookDate);
