const content = require("content-type");
const URL = require("url-parse");
const abortUmd = require("abort-controller/dist/abort-controller.umd.js");
const boom = require("./helpers/boom");
const fetch =
  typeof navigator !== "undefined" && navigator.product === "ReactNative"
    ? global.fetch
    : require("cross-fetch");

const REQUEST_TIMEOUT = 40000;
const MIDDLEWARE_BASE_URL = "https://api.mygon.com";

// We include the abort-controller version that is transpiled with Babel because
// we had issues adding it to our babel pipeline ourselves.
if (typeof window !== "undefined" && !window.AbortController) {
  window.AbortController = abortUmd.AbortController;
  window.AbortSignal = abortUmd.AbortSignal;
}

/**
 * An enhanced `fetch(url, options)`.
 *
 * A few notes about the `queryParams` option:
 * - Numeric and boolean values are converted to their string representation.
 *   Ex: `somekey: true` -> "?somekey=true"
 * - `null` values are transformed into an empty string.
 *   Ex: `somekey: null` -> "?somekey"
 * - `undefined` values are completely discarded.
 *
 * @param {Object} options - A parametrization object.
 * Refer to the `fetch()` documentation for further details.
 * @param {Object} options.queryParams - Specific to this implementation.
 * A key/value map of query parameters.
 */
module.exports = ({
  baseUrl = MIDDLEWARE_BASE_URL,
  queryParams = {
    clientApplication: "WEB",
    language: "en_EN"
  },
  userAgent,
  timeout = REQUEST_TIMEOUT
}) => {
  let currentQueryParams = queryParams;

  const fetchFunc = (uri, init) => {
    const controller = new AbortController();
    const req = new URL(uri, baseUrl);
    const args = {
      ...init,
      headers: {
        "content-type": "application/json",
        ...((init && init.headers) || {})
      },
      signal: controller.signal
    };

    if (userAgent) {
      args.headers["User-Agent"] = userAgent;
    }

    if (currentQueryParams || args.queryParams) {
      const params = {
        ...currentQueryParams,
        ...args.queryParams
      };
      const queryString = Object.keys(params)
        .map(k => {
          const val = params[k];
          if (typeof val === "undefined") {
            return false;
          }
          if (val === null) {
            return encodeURIComponent(k);
          }
          return `${encodeURIComponent(k)}=${encodeURIComponent(val)}`;
        })
        .filter(Boolean)
        .join("&");
      if (queryString) {
        req.query += (req.query.indexOf("?") === -1 ? "?" : "&") + queryString;
      }
      delete args.queryParams;
    }

    const abortTimeout = setTimeout(() => controller.abort(), timeout);

    return fetch(req.toString(), args)
      .then(res => {
        clearTimeout(abortTimeout);
        if (res.status < 400) {
          const rawContentType = res.headers.get("content-type");
          const contentType =
            rawContentType && content.parse(rawContentType).type;
          if (rawContentType && contentType === "application/json") {
            return res.json();
          }

          return res.text();
        }

        return res.json().then(payload => {
          throw boom.create(res.status, payload && payload.message, payload);
        });
      })
      .catch(err => {
        clearTimeout(abortTimeout);
        throw err;
      });
  };

  fetchFunc.updateQueryParams = queryParams => {
    currentQueryParams = {
      ...currentQueryParams,
      ...queryParams
    };
  };

  return fetchFunc;
};
