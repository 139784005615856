module.exports = ({ fetch }) => {
  return {
    book: ({ token, payload, clientApplication, providerId, language, channelManager }) =>
      fetch(`/mygon-middleware/rest/bookings`, {
        method: "post",
        headers: {
          token,
          "X-User-Language": language,
        },
        queryParams: { clientApplication, providerId, channelManager },
        body: JSON.stringify(payload),
      }),
    guestBook: ({ payload, clientApplication, providerId, language, channelManager }) =>
      fetch(`/mygon-middleware/rest/bookings/guest`, {
        method: "post",
        queryParams: { clientApplication, providerId, channelManager, language },
        body: JSON.stringify(payload),
      }),
  };
};
