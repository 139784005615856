import setUserInfo from "./set-user-info";
import sendSms from "./send-sms";
import register from "./register";
import login from "./login";
import loginWithFacebook from "./login-with-facebook";
import loginWithGoogle from "./login-with-google";
import loginWithApple from "./login-with-apple";
import fetchUser from "./fetch-user";
import fetchUserWithExternalAuth from "./fetch-user-with-external-auth";
import fetchPrizes from "./fetch-prizes";
import forgotPassword from "./forgot-password";

export default {
  setUserInfo,
  sendSms,
  register,
  login,
  loginWithFacebook,
  loginWithGoogle,
  loginWithApple,
  fetchUser,
  fetchUserWithExternalAuth,
  fetchPrizes,
  forgotPassword
};
