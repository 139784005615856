import api from "lib/api";

const CAMPAIGN_FETCH_PREMIUM_PLANS = "CAMPAIGN_FETCH_PREMIUM_PLANS";

const fetchPremiumPlans = () => (dispatch, getState) => {
  const state = getState();
  return dispatch({
    type: CAMPAIGN_FETCH_PREMIUM_PLANS,
    payload: api.campaign.getPremiumPlans({
      userId: state.user.id,
      token: state.user.token
    })
  });
};

fetchPremiumPlans.action = CAMPAIGN_FETCH_PREMIUM_PLANS;

export default fetchPremiumPlans;
