import React from "react";
import { css } from "@emotion/core";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import cssVars from "styles/variables.module.scss";

const styles = {
  wrapper: css({
    backgroundColor: cssVars.smart,
    color: cssVars.white,
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: "1.1rem",
    textAlign: "center",
    padding: "0.7rem"
  })
};

export default function SmartMemberHeading() {
  const { t } = useTranslation();

  return (
    <div css={styles.wrapper}>
      <Container>{t("smart.smartMember")}</Container>
    </div>
  );
}
