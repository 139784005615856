import api from "lib/api";
import { setLoggedInUser } from "lib/helpers/inter-frame-communication";

const USER_LOGIN = "USER_LOGIN";

const login = payload => (dispatch, getState) => {
  const state = getState();
  return dispatch({
    type: USER_LOGIN,
    payload: api.user.login({
      payload: {
        providerId: state.context.providerId,
        clientApplication: state.context.clientApplication,
        language: state.context.language,
        ...payload
      }
    })
  }).then(result => {
    // In case we're running as an iframe inside the mygon website, send a
    // message to the main website with the user info so that the user becomes
    // logged in in the main site as well.
    const { id, token } = result.value.data;
    setLoggedInUser({ id, token });

    return result;
  });
};
login.action = USER_LOGIN;

export default login;
