import fetchAddresses from "./fetch-addresses"
import createUpdateAddress from "./create-update-address"
import deleteAddress from "./delete-address"
import setAddressState from "./set-address-state"

export default {
  fetchAddresses,
  createUpdateAddress,
  deleteAddress,
  setAddressState,
}
