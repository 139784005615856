module.exports = ({ fetch }) => {
  return {
    getShoppingCart: ({ shopId, token, sessionId, language }) =>
      fetch(`/mygon-middleware/rest/client/shopping-cart`, {
        method: "get",
        queryParams: { shopId, token, sessionId, language },
      }),
    removeCartItem: ({ itemId, token, sessionId, language }) =>
      fetch(`/mygon-middleware/rest/client/shopping-cart/remove/${itemId}`, {
        method: "post",
        queryParams: { token, sessionId, language },
      }),
  };
};
