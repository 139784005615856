import allSelectors from "./index"

const selectors = {}

selectors.shoppingCart = (state) => state.shoppingCart.cart

selectors.shops = (state) => {
  return selectors.shoppingCart(state).shops
}

selectors.shopIds = (state) => {
  return selectors.shoppingCart(state).shops.map(shop => shop.id)
}

selectors.items = (state) => {
  return selectors.shops(state).reduce((accum, shop) => [...accum, ...shop.items], [])
}

selectors.pricing = (state) => {
  const { discount} = allSelectors.product.info(state);

  const deliveryCost = allSelectors.deliveries.deliveryCost(state)

  const itemsTotal = selectors.items(state)
    .reduce((accum, item) => accum + (item.price * item.quantity), 0);

  //TODO - discount

  const discountValue = discount ? itemsTotal * (Math.abs(discount) / 100) : 0;
  const itemsDiscounted = itemsTotal - discountValue;
  const final = parseFloat((itemsDiscounted + deliveryCost).toFixed(2));
  return {itemsTotal, final, discountValue, itemsDiscounted};
};

export default selectors;
