import React, {useCallback, useEffect, useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container } from "react-bootstrap";
import { css } from "@emotion/core";
import { IconWrapper } from "components/icons";
import { navigateTo } from "lib/helpers/inter-frame-communication";
import { getLocalizedWinPrizesUri } from "lib/helpers/i18n";
import { useTranslation, Trans } from "react-i18next";
import { triggerEvent } from "lib/analytics";
import withErrorModal from "lib/hocs/with-error-modal";
import actions from "lib/redux/actions";
import selectors from "lib/redux/selectors";
import {
  dateTimesObjectToOrderedArray,
  formatMilitaryTime,
  getJavaDateOffset,
} from "lib/helpers/calendar";
import {
  getParentUrl,
  formatPrice,
  stringPriceToFloat,
} from "lib/helpers/utils";
import cssVars from "styles/variables.module.scss";
import { mq } from "styles";
import ReservationSummaryHeading from "components/elements/reservation-summary-heading";
import {Contacts} from "../../lib/helpers/constants";

const styles = {
  successBanner: css({
    backgroundColor: cssVars.primary,
    color: "white",
    textAlign: "center",
    padding: "1.2rem 0",
    ".title-container": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      ".title": {
        fontSize: "1.4rem",
        fontWeight: 500,
      },
      ".icon-wrapper": {
        marginLeft: "1rem",
      },
      svg: {
        fill: "white",
      },
      marginBottom: "0.5rem",
    },
    ".description": {
      fontSize: "0.8rem",
      fontWeight: 500,
    },
  }),
  pointsContainer: css({
    color: cssVars.gray800,
    textAlign: "center",
    ".title": {
      fontSize: "1rem",
      fontWeight: 500,
      marginBottom: "0.5rem",
    },
    ".num-points": {
      color: cssVars.primary,
    },
    ".prizes-link a": {
      color: cssVars.gray800,
      fontSize: "0.9rem",
      fontWeight: "bold",
      textDecoration: "underline",
    },
  }),
  infoList: css(
    mq({
      listStyle: "none",
      padding: 0,
      margin: 0,
      ".icon-wrapper": {
        width: ["1.5rem", "2.5rem"],
        height: ["1.5rem", "2.5rem"],
        color: cssVars.primary,
      },
      li: {
        display: "flex",
        alignItems: "flex-start",
        fontSize: ["0.8rem", "1rem"],
        padding: "1rem 0",
        borderBottom: `1px solid ${cssVars.gray500}`,
        "&:last-of-type": {
          borderBottom: "none",
        },
        svg: {
          fill: cssVars.primary,
        },
        ".description": {
          marginLeft: "0.6rem",
          flex: 1,
          lineHeight: 1.4,
        },
        ".activationCode": {
          marginTop: '0.4rem',
          fontSize: "1.3rem",
          fontWeight: 500,
        },
        ".activationQrCode": {
          marginTop: '0.6rem',
          textAlign: 'center',
        },
        ".btn": {
          marginLeft: "1rem",
          fontSize: "1.1rem",
          flexBasis: 0,
          maxWidth: "40%",
          whiteSpace: ["normal", "nowrap"],
        },
      },
    })
  ),
  contactsContainer: css(
    mq({
      borderTop: `1px solid ${cssVars.gray500}`,
      padding: "1rem 0",
      ".icon-wrapper": {
        width: ["1.5rem", "2.5rem"],
        height: ["1.5rem", "2.5rem"],
      },
      ".description": {
        marginBottom: "0.7rem",
      },
      ".contacts-list": {
        listStyle: "none",
        padding: 0,
        display: "flex",
        justifyContent: "space-between",
        li: {
          flex: 1,
          display: "flex",
          alignItems: "center",
          svg: {
            fill: cssVars.primary,
          },
          a: {
            color: cssVars.gray800,
            fontWeight: "bold",
            marginLeft: "0.5rem",
            textDecoration: "underline",
          },
        },
      },
    })
  ),
};

/**
 * Flow Step 4 - Success confirmation
 */
function FlowStepBookingVoucherConfirmation({
  onFormikProps,
  showModal,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    mode,
    book,
    prefix,
    phone: userPhone,
  } = useSelector((state) => state.flow.steps);
  const serviceId = useSelector(selectors.flow.serviceId);
  const { startTime, endTime } = { startTime: "0000", endTime: "0000" };
  const {
    productTitle,
    bookingType,
    points,
    premiumPoints,
    activationCode,
    activationQrCode,
  } = useSelector(selectors.product.info);
  const premiumUser = useSelector(selectors.user.isPremium)
  const isCordovaAppClient = useSelector(selectors.context.isCordovaAppClient);
  const loggedIn = useSelector(selectors.user.loggedIn)

  useEffect(() => {
    onFormikProps({
      submitForm: null,
      submitLabel: null
    });
  }, []);

  const {
    phoneNumber: shopPhone,
    campaignAddress,
    shopName,
    promotionCode,
  } = useSelector(selectors.product.reservation);

  const { currency, offerPrice, priceBefore, discount } = useSelector(
    selectors.flow.selectedPriceInfo
  );
  const [offerPriceF, priceBeforeF] = stringPriceToFloat(
    offerPrice,
    priceBefore,
  );

  const { date, dateTimes, numberOfPeople } = book;
  // premium points are given regardless if user clicked free or premium booking
  const giftedPoints = (premiumUser ? premiumPoints : points) * numberOfPeople

  const firstDateTime = useMemo(() => {
    const dateTimesArray = dateTimesObjectToOrderedArray(dateTimes);
    return dateTimesArray[0];
  }, [dateTimes]);
  const dateObject = firstDateTime
    ? new Date(firstDateTime.date)
    : date
    ? new Date(date)
    : null;

  const handleSendSms = useCallback(async () => {
    const smsRequestPayload = {
      // Shop info
      shopName,
      phoneNumber: shopPhone,
      // Campaign info
      campaignType: bookingType,
      campaignTitle: productTitle,
      // User info
      mobileNumber: `${prefix}${userPhone}`,
      // Reservation info
      promoCode: promotionCode,
    };

    if ([1, 2, 3, 7].includes(bookingType)) {
      smsRequestPayload.bookDate = `${
        firstDateTime.date
      }T00:00:00${getJavaDateOffset()}`;
    }
    if ([1, 2, 7].includes(bookingType)) {
      smsRequestPayload.bookTime = firstDateTime.time;
    }
    try {
      await dispatch(actions.user.sendSms(smsRequestPayload));
      triggerEvent("Purchase", "SMS_booking_detail");
    } catch (err) {
      triggerEvent("Purchase", "Error_SMS_booking_detail", err.message);
      showModal(t("errorGeneric"));
    }
  }, []);

  const handleShare = useCallback(async () => {
    try {
      const pricingInfo = [
        priceBeforeF &&
          t("summary.share.priceBefore", {
            value: formatPrice(priceBeforeF, currency),
          }),
        offerPriceF &&
          t("summary.share.offerPrice", {
            value: formatPrice(offerPriceF, currency),
          }),
        discount && t("summary.share.discount", { value: discount }),
      ]
        .filter(Boolean)
        .join(". ");

      const when = t(`summary.share.whenByCampaign.${bookingType}`, {
        weekday: dateObject && dateObject.getDay(),
        day: dateObject && dateObject.getDate(),
        month: dateObject && dateObject.getMonth(),
        time: firstDateTime && firstDateTime.time,
        startTime: formatMilitaryTime(startTime),
        endTime: formatMilitaryTime(endTime),
        phone: shopPhone,
      });

      const titleProps = {
        weekday: dateObject && dateObject.getDay(),
        day: dateObject && dateObject.getDate(),
        month: dateObject && dateObject.getMonth(),
        time: firstDateTime && firstDateTime.time,
        shopName,
      };

      const textProps = {
        when,
        campaignTitle: productTitle,
        pricingInfo: pricingInfo || null,
        numberOfPeople,
        address: campaignAddress,
        shopPhone,
        shopName,
        returnObjects: true,
      };

      let title;
      const text = t("summary.share.text", textProps);
      if ([1, 2, 7].includes(bookingType)) {
        title = t("summary.share.titleWithDateTime", titleProps);
      } else if (bookingType === 3) {
        title = t("summary.share.titleWithDate", titleProps);
      } else {
        title = t("summary.share.title", titleProps);
      }

      await navigator.share({
        title,
        text: text.join("\n") + "\n\n",
        url: getParentUrl() || undefined,
      });

      triggerEvent("Purchase", "share_booking_details");
    } catch (err) {
      triggerEvent("Purchase", "Error_sharing_booking_details", err.message);
      showModal(t("errorGeneric"));
    }
  }, [shopName, firstDateTime]);

  const successDescription = mode === 'book' ? t(
    `summary.successByCampaign.${bookingType}.description`,
    {
      weekday: dateObject && dateObject.getDay(),
      day: dateObject && dateObject.getDate(),
      month: dateObject && dateObject.getMonth(),
      time: firstDateTime && firstDateTime.time,
      startTime: formatMilitaryTime(startTime),
      endTime: formatMilitaryTime(endTime),
      phone: shopPhone,
    }
  ) : t('summary.voucherPurchase.description')

  const viewRewardsUrl = `${process.env.MG_SITE_BASE_URL}${getLocalizedWinPrizesUri()}`;

  const handleViewRewards = useCallback(async (event) => {
    if (isCordovaAppClient) {
      event.preventDefault();
      try {
        await navigateTo(getLocalizedWinPrizesUri());
      } catch {
        window.open(viewRewardsUrl, '_blank').focus();
      }
    }
  }, [viewRewardsUrl]);


  return (
    <div>
      <ReservationSummaryHeading />
      <div className="success-banner" css={styles.successBanner}>
        <Container>
          <div className="title-container">
            {mode === 'voucher' ? (
              <div className="title">
                {t('summary.voucherPurchase.title')}
              </div>
            ) : (
              <div className="title">
                {t(`summary.successByCampaign.${bookingType}.title`)}
              </div>
            )}
            <IconWrapper name="mdiCheckCircle" size="2.25rem" />
          </div>
          {successDescription && (
            <div className="description">{successDescription}</div>
          )}
        </Container>
      </div>
      {(bookingType <= 6 && mode !== 'voucher') && Math.floor(giftedPoints) >= 1 && (
        <div
          className="points-container gray-container"
          css={styles.pointsContainer}
        >
          <Container>
            <div className="title">
              <Trans i18nKey="summary.infoPoints" count={Math.floor(giftedPoints)}>
                {[
                  <span key="num-points" className="num-points">
                    {{ points: Math.floor(giftedPoints) }}
                  </span>,
                ]}
              </Trans>
            </div>
            <div className="prizes-link">
              <a target="_blank" href={viewRewardsUrl} onClick={handleViewRewards}>
                {t("summary.actionSeeRewards")}
              </a>
            </div>
          </Container>
        </div>
      )}
      {mode !== 'voucher' && (
        <Container>
          <ul className="info-list" css={styles.infoList}>
            {(activationCode || activationQrCode) && (
              <li>
                <div>
                  <IconWrapper name="mdiBarcodeScan"/>
                </div>
                <div className="description">
                  {t("summary.infoActivationCode")}

                  {activationCode && (
                    <div className="activationCode">
                      {activationCode}
                    </div>
                  )}

                  {activationQrCode && (
                    <div className="activationQrCode">
                      <img
                        src={activationQrCode}
                        height="92"
                        width="92"
                        alt="Activation QR Code"
                      />
                    </div>
                  )}
                </div>
              </li>
            )}
            <li>
              <div>
                <IconWrapper name="email"/>
              </div>
              {bookingType === 7 ? (
                <div className="description">
                  {t("summary.infoEmailSentTransported")}
                </div>
              ) : (
                <div className="description">
                  {t("summary.infoEmailSent")}
                </div>
              )}
            </li>
            {loggedIn && (
              <li>
                <div>
                  <IconWrapper name="edit" />
                </div>
                {bookingType === 7 ? (
                  <div className="description">
                    {t("summary.infoHowToEditTransported")}
                  </div>
                ) : (
                  <div className="description">
                    {t("summary.infoHowToEdit")}
                  </div>
                )}
              </li>
            )}
            {bookingType <= 6 && (
              <li>
                <div>
                  <IconWrapper name="smartphone" />
                </div>
                <div className="description">
                  {t("summary.infoWantSms")}
                </div>
                <Button onClick={handleSendSms}>
                  {t("summary.actionReceiveSms")}
                </Button>
              </li>
            )}
            {!!navigator.share && bookingType <= 6 && (
              <li>
                <div>
                  <IconWrapper name="share" />
                </div>
                <div className="description">
                  {t("summary.infoShare")}
                </div>
                <Button onClick={handleShare}>
                  {t("summary.actionShare")}
                </Button>
              </li>
            )}
          </ul>
        </Container>
      )}
      {!serviceId && (
        <div css={styles.contactsContainer}>
          <Container>
            <div className="description">
              {t("summary.infoContacts")}:
            </div>
            <ul className="contacts-list">
              <li>
                <div>
                  <IconWrapper name="telephone" />
                </div>
                <a href={`tel:+351${Contacts.PHONE}`}>{Contacts.PHONE}</a>
              </li>
              <li>
                <div>
                  <IconWrapper name="email" />
                </div>
                <a href={`mailto:${Contacts.EMAIL}`}>{Contacts.EMAIL}</a>
              </li>
            </ul>
          </Container>
        </div>
      )}
    </div>
  );
}

export default withErrorModal(FlowStepBookingVoucherConfirmation);
