import api from "lib/api"
import {setReservationInfo} from "lib/helpers/inter-frame-communication"

const PRODUCT_ORDER = "PRODUCT_ORDER"

const order = ({ payload }) => (dispatch, getState) => {
  const state = getState()

  if (state.context.allowGuestLogin && !state.user.token) {
    return dispatch({
      type: PRODUCT_ORDER,
      payload: api.orders.guestOrder({
        clientApplication: state.context.clientPlatform || state.context.clientApplication,
        entityId: state.context.providerId,
        channel: state.context.channelManager,
        language: state.context.language,
        payload,
      }),
    }).then(result => {
      // In case we're running as an iframe inside the mygon website, send a
      // message to the main website with the reservation info.
      setReservationInfo(result.value.data)

      return result
    })
  }

  return dispatch({
    type: PRODUCT_ORDER,
    payload: api.orders.order({
      token: state.user.token,
      clientApplication: state.context.clientPlatform || state.context.clientApplication,
      entityId: state.context.providerId,
      channel: state.context.channelManager,
      language: state.context.language,
      payload,
    }),
  }).then(result => {
    // In case we're running as an iframe inside the mygon website, send a
    // message to the main website with the reservation info.
    setReservationInfo(result.value.data)

    return result
  })
}

order.action = PRODUCT_ORDER

export default order
