import { Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import actions from "../lib/redux/actions";
import { availableLocales } from "../lib/helpers/i18n";

export default function LocaleSelection() {
  const context = useSelector((state) => state.context)
  const dispatch = useDispatch();
  const currentLocale = availableLocales.find(l => l.code === context.language)

  const handleLocaleUpdate = (locale) => {
    dispatch(
      // this will update app localization, and reload data from API as set by the app state
      actions.context.setContext({
        language: locale
      })
    );
  };

  return (
    <div className="locale-dropdown">
      <Dropdown
        variant="link"
        className="d-flex align-items-center"
        drop="up"
        boundary="window"
      >
        <Dropdown.Toggle as="a" className="d-flex">
          <img
            src={currentLocale.image}
            height="22"
            width="22"
            alt={currentLocale.code}
          />
          <span className="locale-title">{currentLocale.language}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {availableLocales.map((localeObj) => (
            <Dropdown.Item
              key={localeObj.code}
              active={localeObj.code === currentLocale.code}
              onClick={() => handleLocaleUpdate(localeObj.code)}
            >
              <img
                src={localeObj.image}
                height="22"
                width="22"
                alt={localeObj.code}
              />
              <span className="locale-title">
                {localeObj.language}
              </span>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
