module.exports = ({ fetch }) => {
  return {
    get: ({ shopId, language }) =>
      fetch(`/mygon-middleware/rest/shop/${shopId}`, {
        method: "get",
        queryParams: {language},
      }),
    getCalendarSlots: (
      {
        shopMerchantServiceId,
        startDate = new Date(),
        untilMinutes = 43200, // 30 days
        language
    }) =>
      fetch(
        `/mygon-middleware/rest/v4/shopmerchantservices/${shopMerchantServiceId}/calendar/available-slots?startPeriod=${startDate.toISOString()}&untilMinutes=${untilMinutes}`,
        {
          method: "get",
          queryParams: { language },
        }
      ),
  };
};
