module.exports = ({fetch}) => {
  return {
    getAddresses: ({language, token}) =>
      fetch(`/mygon-middleware/rest/user/addresses`, {
        method: "get",
        queryParams: {language, token},
      }),
    createUpdateAddress: ({ language, token, payload }) =>
      fetch(`/mygon-middleware/rest/user/addresses/create`, {
        method: "post",
        body: JSON.stringify(payload),
        queryParams: {language, token },
      }),
    deleteAddress: ({language, token, id}) =>
      fetch(`/mygon-middleware/rest/user/addresses/delete/${id}`, {
        method: "post",
        queryParams: {language, token},
      }),
  }
}
