module.exports = ({ fetch }) => {
  return {
    get: ({ token, userId, language }) =>
      fetch(`mygon-middleware/rest/client/profile/${userId}`, {
        method: "get",
        queryParams: { token, language },
      }),

    /**
     *
     * @param {object} payload:
     * providerId: 4, persistence: true, clientApplication: web, language: pt_PT
     */
    getWithExternalAuthentication: ({
      externalToken,
      providerId,
    }) =>
      fetch(`mygon-middleware/rest/authentication/external`, {
        method: "post",
        headers: {
          authorization: externalToken,
          "content-type": "application/x-www-form-urlencoded",
        },
        queryParams: { providerId },
        body: new URLSearchParams({
          providerId
        }),
      }),

    /**
     * Perform user authentication
     *
     * @param options
     * @param options.payload
     * @param options.payload.username
     * @param options.payload.password
     * @param options.payload.providerId: 1
     * @param options.payload.persistence - true/false
     * @param options.payload.clientApplication - "WEB"
     * @param options.payload.language - "en_EN"
     */
    login: ({ payload }) =>
      fetch("/mygon-middleware/rest/authentication", {
        method: "post",
        // credentials: "include",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(payload),
      }),
    loginWithFacebook: ({ payload }) =>
      fetch(
        "/mygon-middleware/rest/client/authentication/signUpLoginFacebook",
        {
          method: "post",
          // credentials: "include",
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(payload),
        }
      ),
    loginWithGoogle: ({ payload }) =>
      fetch(
        "/mygon-middleware/rest/client/authentication/signUpLoginGoogle",
        {
          method: "post",
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(payload),
        }
      ),
    loginWithApple: ({ payload }) =>
      fetch(
        "/mygon-middleware/rest/client/authentication/signUpLoginApple",
        {
          method: "post",
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(payload),
        }
      ),

    /**
     * Register a new user
     * @param options.payload.username: <email>
     * @param options.payload.password: <password>
     * @param options.payload.countryId: 139
     * @param options.payload.districtId: 4
     * @param options.payload.location: Beja
     * @param options.payload.referralCode:
     * @param options.payload.providerId: 1
     * @param options.payload.language: en_EN
     *
     */
    register: ({ payload }) =>
      fetch("/mygon-middleware/rest/client/authentication/signUp", {
        method: "post",
        // credentials: "include",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(payload),
      }),
    checkEmailRegistered: ({ email }) =>
      fetch(
        `/mygon-middleware/rest/client/authentication/isRegisteredEmail/${encodeURIComponent(
          email
        )}`,
        {
          method: "get",
          // credentials: "include"
        }
      ),
    /**
     * Perform a password reset request
     *
     * @param options
     * @param options.payload
     * @param options.payload.username
     * @param options.payload.providerId: 1
     * @param options.payload.redirectUrl: "https%253A%252F%252Fwww.mygon.com%252Fen"
     */
    forgotPassword: ({ payload }) =>
      fetch("/mygon-middleware/rest/authentication/password/forgotPassword", {
        method: "post",
        // credentials: "include",
        body: JSON.stringify(payload),
      }),
    getPrizes: ({ userId, token }) =>
      fetch("/mygon-middleware/rest/client/prize/myprizes", {
        method: "get",
        queryParams: {
          userId,
          token,
          startIndex: 0,
          pageSize: 100,
        },
      }),
  };
};
