import { css } from "@emotion/core";
import { formatDate } from "lib/helpers/calendar";
import { formatPrice, stringPriceToFloat } from "lib/helpers/utils";
import withErrorModal from "lib/hocs/with-error-modal";
import selectors from "lib/redux/selectors";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { mq } from "styles";
import cssVars from "styles/variables.module.scss";

const styles = {
  list: css(
    mq({
      listStyle: "none",
      padding: 0,
      margin: 0,
      fontSize: "1.25rem",
      li: {
        cursor: "pointer",
        borderBottom: `1px solid ${cssVars.gray500}`,
        padding: ["10px", "0.5rem 0.5rem 0.5rem 22px"],
        display: "flex",
        justifyContent: "space-between",
        color: cssVars.gray600,
        "&:last-of-type": {
          borderBottom: "none",
        },
        "&.unavailable": {
          cursor: "default",
          color: cssVars.gray500,
        },
        "&.selected": {
          borderLeft: [
            `4px solid ${cssVars.primary}`,
            `10px solid ${cssVars.primary}`,
          ],
          paddingLeft: ["6px", "12px"],
          fontWeight: "bold",
          ".slot-time": {
            fontWeight: "bold",
            color: cssVars.gray800,
          },
        },
        ".price-info": {
          fontWeight: "bold",
          ".price-before": {
            color: cssVars.gray500,
            textDecoration: "line-through",
            fontSize: "0.8em",
            marginRight: "10px",
          },
          ".price-after": {
            color: cssVars.primary,
          },
        },
      },
    })
  ),
  noSlots: css({
    padding: "1rem",
    textAlign: "center",
  }),
};

/**
 * Represents a scheduling time table where we print multiple available time
 * slots given a start and an end time.
 *
 * @param {Object} props
 */
function Timetable({
  selectedDay,
  isDayAvailable,
  pricing,
  onSelectionChange,
  selections,
  maxSelections,
  remainingSelections,
  showModal,
  selectedWeekIncludesSelectedDay,
}) {
  const { t } = useTranslation();

  const now = useMemo(() => new Date(), []);
  const todaySelected = useMemo(() => selectedDay === formatDate(now), [
    selectedDay,
    now,
  ]);
  const [curSelections, setCurSelections] = useState(
    selections ||
      [
        // { time: "12:15", selectedAt: timestamp }
      ]
  );

  useEffect(() => {
    setCurSelections(selections || []);
  }, [selections]);

  const timetableSlotsSelector = useMemo(
    () =>
      selectors.product.timetableSlots({ dayYmd: selectedDay, todaySelected }),
    [selectedDay, todaySelected]
  );
  const timetableSlots = useSelector(timetableSlotsSelector);

  const slots = useMemo(
    () =>
      timetableSlots.map((slot) => ({
        ...slot,
        selected: curSelections.find((sel) => sel.time === slot.label),
      })),
    [curSelections, timetableSlots]
  );

  const handleSelect = useCallback(
    (time) => {
      let newSelections = [];
      const newTimeEntry = { time, selectedAt: Date.now() };

      if (curSelections.find((entry) => entry.time === time)) {
        // Was selected, unselecting
        newSelections = curSelections.filter(
          (selection) => selection.time !== time
        );
      } else if (maxSelections === 1) {
        // Single selection allowed, replace existing entry
        newSelections = [newTimeEntry];
      } else if (remainingSelections !== 0) {
        // Unlimited selections allowed
        newSelections = [...curSelections, newTimeEntry];
      } else {
        newSelections = curSelections;
        showModal(t("reservation.dateTimesLimitReached"));
      }

      setCurSelections(newSelections);
      onSelectionChange(newSelections);
    },
    [onSelectionChange, curSelections, slots, curSelections]
  );

  const { currency, offerPrice, priceBefore, discount } = pricing;
  const [offerPriceF, priceBeforeF] = stringPriceToFloat(
    offerPrice,
    priceBefore
  );

  const pricingElement = useMemo(() => {
    const showPrices = !!offerPriceF;
    const showDiscount = !showPrices && discount;
    if (!showPrices && !showDiscount) {
      return null;
    }
    return (
      <div className="price-info">
        {showPrices ? (
          <>
            {priceBeforeF && (
              <span className="price-before">
                {formatPrice(priceBeforeF, currency)}
              </span>
            )}
            <span className="price-after">
              {formatPrice(offerPriceF, currency)}
            </span>
          </>
        ) : (
          <span className="discount">{discount}%</span>
        )}
      </div>
    );
  }, [currency, offerPriceF, priceBeforeF, discount]);

  const hasAvailableSlots = useMemo(() => {
    return isDayAvailable && slots.some((slot) => slot.enabled);
  }, [isDayAvailable, slots]);

  return hasAvailableSlots ? (
    <ul css={styles.list}>
      {slots.map(
        (slot) =>
          slot.enabled && (
            <li
              key={slot.label}
              onClick={slot.enabled ? () => handleSelect(slot.label) : null}
              className={[
                slot.selected && "selected",
                !slot.enabled && "unavailable",
              ]
                .filter(Boolean)
                .join(" ")}
            >
              <span className="slot-time">{slot.label} </span>
              {slot.enabled && <span>{pricingElement}</span>}
            </li>
          )
      )}
    </ul>
  ) : (
    <div css={styles.noSlots} className="empty-timetable-container">
      {selectedWeekIncludesSelectedDay
        ? t("calendar.dateNotAvailable")
        : t("calendar.dateNotAvailableWeekOffsetted")}
    </div>
  );
}

export default withErrorModal(Timetable);
