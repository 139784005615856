const FLOW_SET_STEP_STATE = "FLOW_SET_STEP_STATE";
const setStepState = ({ data }) => {
  return {
    type: FLOW_SET_STEP_STATE,
    payload: { data }
  };
};
setStepState.action = FLOW_SET_STEP_STATE;

export default setStepState;
