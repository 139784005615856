import api from "lib/api";

const USER_FETCH = "USER_FETCH";

const fetchUser = ({ userId, token }) => (dispatch, getState) => {
  const state = getState();
  return dispatch({
    type: USER_FETCH,
    payload: api.user.get({
      token: token || state.user.token,
      userId: userId || state.user.id,
      language: state.context.language,
    }),
    meta: {
      token: token || state.user.token
    }
  });
};
fetchUser.action = USER_FETCH;

export default fetchUser;
