import api from "lib/api"

const DELIVERY_COUNTRIES_FETCH = "DELIVERY_COUNTRIES_FETCH"

const fetchDeliveryCountries = ({ }) => (dispatch, getState) => {
  const state = getState()

  return dispatch({
    type: DELIVERY_COUNTRIES_FETCH,
    payload: api.deliveries.getDeliveryCountries({
      language: state.context.language,
      entityId: state.entity.entityId,
      token: state.user.token,
      sessionId: state.user.sessionId,
    }),
  })
}

fetchDeliveryCountries.action = DELIVERY_COUNTRIES_FETCH

export default fetchDeliveryCountries
