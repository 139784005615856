import {formatDate, getDaysArray, getTimeInfo,} from "lib/helpers/calendar";
import {isCampaignRestaurant} from "lib/helpers/utils";
import allSelectors from "./index";
import {TransportTypes} from "../../helpers/constants";

const selectors = {};

const BookingTypeMapping = {
  NORMAL: 1,
  AWAITS_AVAILABILITY: 2,
  NO_BOOK_HOUR: 3,
  INFO: 4,
  DIGITAL_CUPON: 5, // Yes, misspelled ¯\_(ツ)_/¯
  DIGITAL_COUPON: 5, // Not used, just here in case someone ever fixes the typo
  MULTIPLE_DATES_SUGGESTED_BY_CLIENT: 6,
  TAKEAWAY_DELIVERY: 7,
};

selectors.info = (state) => {
  const serviceId = allSelectors.flow.serviceId(state);
  const campaignView = allSelectors.campaign.campaignView(state)

  if (serviceId) {
    const { name: shopName } = state.shop;

    const serviceInfo = allSelectors.shop.shopMerchantService(serviceId)(state);
    if (!serviceInfo) {
      throw new Error("Service not found");
    }

    const {
      serviceName: productTitle,
      bookingConfig,
      minimumTimeBeforeBooking,
      price,
    } = serviceInfo;

    const { isTakeaway, isDelivery, minPartySize, maxPartySize } =
      bookingConfig || {};

    const minimumMinutesBeforeBooking = Math.ceil(
      (minimumTimeBeforeBooking || 0) / 60
    );
    const minMinutesOrderPreparation = 30;

    return {
      campaignId: null,
      shopName,
      productTitle,
      minNumberPeople: minPartySize,
      maxNumberPeople: maxPartySize,
      price,
      bookingType:
        BookingTypeMapping[bookingConfig.bookingType] ||
        BookingTypeMapping.NORMAL,
      currency: "€",
      isTakeaway,
      isDelivery,
      offer: false,
      isFreeOffer: false,
      merchantStatus: null,
      minimumMinutesBeforeBooking,
      minMinutesBeforeTimeSelection: Math.max(
        minimumMinutesBeforeBooking,
        minMinutesOrderPreparation
      ),
      points: 0, // always 0 for services
      deliveryCost: 3,
      discount: 0, // always 0 for services
    };
  } else if (campaignView) {
    const {
      campaignId,
      campaignTitle: productTitle,
      campaignType,
      minNumberPeople,
      maxNumberPeople,
      currency,
      isTakeaway,
      isDelivery,
      offer,
      isFreeOffer,
      merchantStatus,
      points,
      premiumPoints,
      discount,
      activationCode,
      activationQrCode,
    } = campaignView;

    const { shopName, deliveryCost } = allSelectors.campaign.info(state);
    const minimumMinutesBeforeBooking = allSelectors.campaign.minimumMinutesBeforeBooking(
      state
    );
    const minMinutesBeforeTimeSelection = allSelectors.campaign.minMinutesBeforeTimeSelection(
      state
    );

    return {
      campaignId,
      shopName,
      productTitle,
      minNumberPeople,
      maxNumberPeople,
      bookingType: campaignType,
      currency,
      isTakeaway,
      isDelivery,
      offer,
      isFreeOffer,
      merchantStatus,
      minimumMinutesBeforeBooking,
      minMinutesBeforeTimeSelection,
      points,
      premiumPoints,
      deliveryCost,
      discount,
      activationCode,
      activationQrCode,
    };
  } else {
    const {
      name,
    } = state.shop;

    return {
      campaignId: null,
      shopName: name,
      currency: "€",
      offer: false,
      isFreeOffer: false,
      merchantStatus: null,
      points: 0, // TODO: points for products
      discount: 0, // TODO: discount for products
    }
  }
};

selectors.reservation = (state) => state.product.reservation;

selectors.priceListView = (state) => {
  const serviceId = allSelectors.flow.serviceId(state);
  return serviceId
    ? allSelectors.shop.priceListView(state)
    : allSelectors.campaign.priceListView(state);
};

selectors.addressView = (state) => {
  const serviceId = allSelectors.flow.serviceId(state);
  return serviceId
    ? allSelectors.shop.addressView(state)
    : allSelectors.campaign.addressView(state);
};

selectors.schedule = (state) => {
  const serviceId = allSelectors.flow.serviceId(state);

  if (serviceId) {
    const allSlots = allSelectors.shop.calendarSlots(serviceId)(state);
    const firstSlot = allSlots[0];
    const lastSlot = allSlots[allSlots.length - 1];

    let startDate = new Date();
    let endDate = new Date();
    let nonAvailableDays = [];

    if (firstSlot && lastSlot) {
      startDate = new Date(firstSlot.startTime);
      endDate = new Date(lastSlot.startTime);
      const rangeDaysYmd = getDaysArray(startDate, endDate, true);
      const allDaysYmd = allSlots.map((slot) => formatDate(slot.startTime));
      const uniqueDaysYmd = [...new Set(allDaysYmd)];

      nonAvailableDays = rangeDaysYmd.filter(
        (dayYmd) => !uniqueDaysYmd.includes(dayYmd)
      );
    }

    return {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      nonAvailableDays,
    };
  } else {
    const {
      startDate,
      endDate,
      nonAvailableDays,
    } = allSelectors.campaign.schedule(state);
    return {
      startDate,
      endDate,
      nonAvailableDays,
    };
  }
};

selectors.timetableSlots = ({ dayYmd, todaySelected }) => (state) => {
  const serviceId = allSelectors.flow.serviceId(state);
  const numberOfPeople = allSelectors.flow.numberOfPeople(state)

  let allSlots

  if (serviceId) {
    allSlots = allSelectors.shop.calendarSlots(serviceId)(state);
  } else {
    const campaignId = allSelectors.flow.campaignId(state)
    allSlots = allSelectors.campaign.calendarSlots(campaignId)(state)
  }

  return allSlots
    .filter(s => s.capacity >= numberOfPeople)
    .map((slot) => {
      const date = new Date(slot.startTime);
      const dateYmd = formatDate(date);
      if (dateYmd !== dayYmd) {
        return null;
      }

      const militaryTime = (date.getHours()) * 100 + date.getMinutes();
      const timeInfo = getTimeInfo(militaryTime);

      return {
        enabled: !todaySelected || timeInfo.time >= militaryTime,
        time: timeInfo.time,
        durationSec: slot.durationSec,
        label: timeInfo.label,
      };
    })
    .filter(Boolean);
};

/**
 * The pre-selected number of people for the reservation is the minimum number
 * of people for the campaign/service, except in the case of campaigns, for
 * restaurants where it is at least 2 (unless the max number is actually 1).
 */
selectors.defaultNumberOfPeople = (state) => {
  const serviceId = allSelectors.flow.serviceId(state);
  const { minNumberPeople, maxNumberPeople } = selectors.info(state);

  if (serviceId) {
    return minNumberPeople;
  } else {
    const { campaign } = state;
    if (!campaign) {
      return 1;
    }
    return isCampaignRestaurant(campaign)
      ? Math.max(Math.min(2, maxNumberPeople), minNumberPeople)
      : minNumberPeople;
  }
};

export default selectors;
