import React, { useCallback, useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { css } from "@emotion/core";
import { IconWrapper } from "components/icons";
import cssVars from "styles/variables.module.scss";

const styles = {
  container: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    ".decrement": {
      fill: cssVars.gray600,
      zIndex: 3
    },
    ".increment": {
      fill: cssVars.primary
    }
  }),
  input: css({
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "center",
    borderRadius: "unset",
    borderColor: "#ccc",
    borderWidth: "1px 0 1px 0",
    "&.is-invalid": {
      backgroundImage: "none",
      padding: "0.375rem 0.75rem"
    },
    // Hide the up/down buttons in the numeric input field
    MozAppearance: "textfield",
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none"
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none"
    }
  })
};

export default function InputIncrementDecrement({
  min,
  max,
  defaultValue = 0,
  onValueChange,
  ...props
}) {
  const inputStyle = useMemo(() => {
    // Width = max num digits + 1 in em
    const widthEm = Math.max(2, String(max).length) + 1;
    return css({ width: `${widthEm}em`, height: "auto" });
  }, [max]);

  const keepInBounds = useCallback(
    val => {
      // Allow empty value for easier editing with the keyboard
      if (val === "") return val;
      return Math.max(Math.min(val, max), min);
    },
    [min, max]
  );

  const [value, setValue] = useState(
    typeof defaultValue !== "undefined" ? defaultValue : min
  );

  const handleChange = useCallback(
    e => {
      const newValue = keepInBounds(e.target.value);
      setValue(newValue);
      onValueChange(newValue);
    },
    [value, keepInBounds]
  );

  const handleIncDec = useCallback(
    change => {
      const newValue = keepInBounds(value + change);
      setValue(newValue);
      onValueChange(newValue);
    },
    [value, keepInBounds]
  );

  return (
    <span css={styles.container}>
      <Button
        variant="inc-dec"
        className="decrement"
        aria-label="Decrement"
        disabled={value === min || min === max}
        onClick={() => handleIncDec(-1)}
      >
        <IconWrapper name="mdiMinus" size="2.25rem" />
      </Button>
      <Form.Control
        css={[styles.input, inputStyle]}
        type="number"
        pattern="[0-9]*"
        inputMode="numeric"
        value={value}
        onChange={handleChange}
        {...props}
      />
      <Button
        variant="inc-dec"
        className="increment"
        aria-label="Increment"
        disabled={value === max || min === max}
        onClick={() => handleIncDec(1)}
      >
        <IconWrapper name="mdiPlus" size="2.25rem" />
      </Button>
    </span>
  );
}
