import api from "lib/api";
import { setReservationInfo } from "lib/helpers/inter-frame-communication";

const CAMPAIGN_BOOK = "CAMPAIGN_BOOK";

const book = ({ clientApplication, channelManager, payload }) => (dispatch, getState) => {
  const state = getState();
  return dispatch({
    type: CAMPAIGN_BOOK,
    payload: api.campaign.book({
      userId: state.user.id,
      token: state.user.token,
      payload,
      clientApplication,
      language: state.context.language,
      channelManager
    })
  }).then(result => {
    // In case we're running as an iframe inside the mygon website, send a
    // message to the main website with the reservation info.
    setReservationInfo(result.value.data);

    return result;
  });
};

book.action = CAMPAIGN_BOOK;

export default book;
