import React from "react";
import { useDispatch } from "react-redux";
import { Container, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import withErrorModal from "lib/hocs/with-error-modal";
import { useTranslation } from "react-i18next";
import actions from "lib/redux/actions";
import Title from "./title";

const schema = yup.object({
  email: yup
    .string()
    .email()
    .required()
});

function ForgotPassword({
  email,
  onBack,
  onFormikProps,
  onComplete,
  showModal
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleValidSubmit = async ({ email }) => {
    try {
      await dispatch(
        actions.user.forgotPassword({
          username: email
        })
      );
      onComplete({ email });
    } catch (err) {
      showModal(err.message);
      return false;
    }
  };

  return (
    <Container>
      <Formik
        validateOnMount
        validationSchema={schema}
        onSubmit={handleValidSubmit}
        initialValues={{ email }}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          submitForm,
          values,
          touched,
          errors,
          isValid,
          isSubmitting
        }) => {
          onFormikProps({
            onBack,
            submitForm,
            submitLabel: t("flow.send"),
            isValid,
            isSubmitting
          });
          return (
            <Form
              noValidate
              className="form padded-step-container"
              onSubmit={handleSubmit}
            >
              <Form.Group>
                <Title icon="mdiLockQuestion">
                  {t("forms.titles.forgotPassword")}
                </Title>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  className="rounded-underline"
                  type="email"
                  name="email"
                  placeholder={t("forms.email")}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.email && !!errors.email}
                />
                {touched.email && (
                  <Form.Control.Feedback type="invalid">
                    {t("forms.errors.emailInvalid")}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
}

export default withErrorModal(ForgotPassword);
