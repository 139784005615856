import React, { useMemo } from "react";
import { css } from "@emotion/core";
import { useSelector } from "react-redux";
import { Badge, Col, Form, Container } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import selectors from "lib/redux/selectors";
import { formatPrice, stringPriceToFloat } from "lib/helpers/utils";
import { ReservationTypes, AllReservationTypes } from "lib/helpers/constants";
import cssVars from "styles/variables.module.scss";

const styles = {
  reservationTypes: css({
    listStyle: "none",
    padding: 0,
    margin: 0,
    li: {
      borderBottom: `1px solid ${cssVars.gray500}`,
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
    },
    ".item-container": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: "3.5rem",
    },
    ".custom-control": {
      display: "inline-block",
      marginRight: "1rem",
    },
    ".badge": {
      fontSize: "0.9rem",
    },
    ".prices": {
      textAlign: "right",
      fontSize: "1.6rem",
      fontWeight: 500,
      ".offer-normal": {
        color: cssVars.primary,
      },
      ".offer-smart": {
        color: cssVars.smart,
      },
      ".before": {
        color: cssVars.gray600,
        fontSize: "0.8rem",
        textDecoration: "line-through",
      },
    },
  }),
  paymentDescription: css({
    textAlign: "center",
  }),
};

const schema = yup.object({
  reservationType: yup.string().oneOf(AllReservationTypes).required(),
});

/**
 * Flow Step 0 for Campaign reservations
 */
export default function FlowStep0Campaign({
  stepState,
  updateStepState,
  onFormikProps,
  onNextStep,
  onPrevStep,
}) {
  const { t } = useTranslation();

  const hasSmartPrice = useSelector(selectors.campaign.hasSmartPrice);
  const normalSelector = selectors.flow.priceInfoCreator(
    null,
    ReservationTypes.NORMAL
  );
  const smartSelector = selectors.flow.priceInfoCreator(
    null,
    ReservationTypes.SMART
  );

  const normalPriceInfo = useSelector(normalSelector);
  const smartPriceInfo = useSelector(smartSelector);

  const { currency, discount: normalDiscount } = normalPriceInfo;
  const { discount: smartDiscount } = smartPriceInfo;
  const [
    priceBeforeF,
    normalOfferPriceF,
    smartOfferPriceF,
  ] = stringPriceToFloat(
    normalPriceInfo.priceBefore,
    normalPriceInfo.offerPrice,
    smartPriceInfo.offerPrice
  );

  const handleValidSubmit = async ({ reservationType }) => {
    await new Promise((resolve) => setTimeout(resolve, 500));
    updateStepState({ reservationType });
    onNextStep();
    return false;
  };

  return (
    <div>
      <Formik
        validationSchema={schema}
        onSubmit={handleValidSubmit}
        initialValues={{ reservationType: stepState.reservationType }}
      >
        {({
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          submitForm,
          isSubmitting,
          values,
          touched,
          isValid,
          errors,
        }) => {
          onFormikProps({
            submitForm,
            isValid,
            isSubmitting,
            submitLabel: t("flow.continue"),
          });
          return (
            <Form noValidate className="form" onSubmit={handleSubmit}>
              <Form.Group>
                <ul
                  className="reservation-type-items"
                  css={styles.reservationTypes}
                >
                  <li>
                    <Container className="item-container">
                      <div>
                        <Form.Check
                          custom
                          type="radio"
                          id={ReservationTypes.NORMAL}
                          name="reservationType"
                          label={t(`reservation.${ReservationTypes.NORMAL}`)}
                          checked={
                            values.reservationType === ReservationTypes.NORMAL
                          }
                          onChange={() => {
                            setFieldTouched("reservationType");
                            setFieldValue(
                              "reservationType",
                              ReservationTypes.NORMAL
                            );
                          }}
                        />
                        {normalDiscount && (
                          <Badge variant="primary">{normalDiscount}%</Badge>
                        )}
                      </div>
                      {!!normalOfferPriceF && (
                        <div className="prices">
                          <div className="offer-normal">
                            {formatPrice(normalOfferPriceF, currency)}
                          </div>
                          {priceBeforeF && (
                            <div className="before">
                              {t("reservation.priceBefore", {
                                price: formatPrice(priceBeforeF, currency),
                              })}
                            </div>
                          )}
                        </div>
                      )}
                    </Container>
                  </li>
                  {hasSmartPrice && (
                    <li>
                      <Container className="item-container">
                        <div>
                          <Form.Check
                            custom
                            type="radio"
                            id={ReservationTypes.SMART}
                            name="reservationType"
                            label={t(`reservation.${ReservationTypes.SMART}`)}
                            checked={
                              values.reservationType === ReservationTypes.SMART
                            }
                            onChange={() => {
                              setFieldTouched("reservationType");
                              setFieldValue(
                                "reservationType",
                                ReservationTypes.SMART
                              );
                            }}
                          />
                          {smartDiscount && (
                            <Badge variant="smart">{smartDiscount}%</Badge>
                          )}
                        </div>
                        {!!smartOfferPriceF && (
                          <div className="prices">
                            <div className="offer-smart">
                              {formatPrice(smartOfferPriceF, currency)}
                            </div>
                            {priceBeforeF && (
                              <div className="before">
                                {t("reservation.priceBefore", {
                                  price: formatPrice(priceBeforeF, currency),
                                })}
                              </div>
                            )}
                          </div>
                        )}
                      </Container>
                    </li>
                  )}
                </ul>
                <div className="gray-container" css={styles.paymentDescription}>
                  <Container>
                    <h4>{t("reservation.freeReservation")}</h4>
                    <div>{t("reservation.payAfter")}</div>
                  </Container>
                </div>
              </Form.Group>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
