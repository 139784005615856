const selectors = {};

// put all subcategories into a single list
const subCategoriesById = (categories) => {
  if (categories && categories.length > 0) {
    return categories.map(c => c.subcategoryList).reduce((a, b) => a.concat(b))
  }
};

selectors.allSubCategoriesById = (state) => subCategoriesById(state.categories.categories);

export default selectors;
