import api from "lib/api";
import { setReservationInfo } from "lib/helpers/inter-frame-communication";

const PRODUCT_BOOK = "PRODUCT_BOOK";

const book = ({ clientApplication, providerId, channelManager, payload }) => (dispatch, getState) => {
  const state = getState()

  if (state.context.allowGuestLogin && !state.user.token) {
    return dispatch({
      type: PRODUCT_BOOK,
      payload: api.product.guestBook({
        clientApplication,
        providerId: state.context.providerId,
        channelManager: state.context.channelManager,
        language: state.context.language,
        payload
      })
    }).then(result => {
      // In case we're running as an iframe inside the mygon website, send a
      // message to the main website with the reservation info.
      setReservationInfo(result.value.data);

      return result;
    });
  }

  return dispatch({
    type: PRODUCT_BOOK,
    payload: api.product.book({
      token: state.user.token,
      clientApplication,
      providerId,
      channelManager,
      language: state.context.language,
      payload
    })
  }).then(result => {
    // In case we're running as an iframe inside the mygon website, send a
    // message to the main website with the reservation info.
    setReservationInfo(result.value.data);

    return result;
  });
};

book.action = PRODUCT_BOOK;

export default book;
