import book from "./book";
import fetchCampaign from "./fetch-campaign";
import fetchPremiumPlans from "./fetch-premium-plans";
import fetchSchedule from "./fetch-schedule";
import getHiPayPaymentLink from "./get-hipay-payment-link";
import fetchCalendarSlots from "./fetch-calendar-slots";

export default {
  book,
  fetchCampaign,
  fetchPremiumPlans,
  fetchSchedule,
  fetchCalendarSlots,
  getHiPayPaymentLink,
};
