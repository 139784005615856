import React from "react";
import useModal from "lib/hooks/use-modal";

const withErrorModal = WrappedComponent => props => {
  const [modal, showModal] = useModal();

  return (
    <>
      {modal}
      <WrappedComponent showModal={showModal} {...props} />
    </>
  );
};

export default withErrorModal;
