import { useEffect } from "react";
import { connect } from "formik";
import { usePrevious } from "lib/hooks/use-previous";

const FormikEffect = ({ onChange, formik }) => {
  const { values } = formik;
  const prevValues = usePrevious(values);

  useEffect(() => {
    // Don't run effect on form init
    if (prevValues) {
      onChange({ prevValues, nextValues: values, formik });
    }
  }, [values]);

  return null;
};

export default connect(FormikEffect);
