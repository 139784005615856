const REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART"

const removeItemFromCart = (itemId) => {
  return {
    type: REMOVE_ITEM_FROM_CART,
    payload: itemId
  }
}

removeItemFromCart.action = REMOVE_ITEM_FROM_CART

export default removeItemFromCart
