import React from "react";
import { css } from "@emotion/core";
import PoweredByMygon from "./powered-by-mygon";
import { useSelector } from "react-redux";
import LocaleSelection from "./locale-selection";

const styles = {
  footerWrapper: css({
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  }),
};

export default function Footer() {
  const context = useSelector((state) => state.context);

  // show powered by mygon if not originating from mygon.com
  // mygon.com uses clientPlatform=WEB/Android/Ios
  const showPoweredByMygon = context.clientPlatform === 'WIDGET'

  return showPoweredByMygon ? (
    <div css={styles.footerWrapper}>
      <LocaleSelection />

      <PoweredByMygon />
    </div>
  ) : ''
}
