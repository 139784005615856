const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART"

const addItemToCart = (item) => {
  return {
    type: ADD_ITEM_TO_CART,
    payload: item
  }
}

addItemToCart.action = ADD_ITEM_TO_CART

export default addItemToCart
