import api from "lib/api"

const CREATE_UPDATE_ADDRESS = "CREATE_UPDATE_ADDRESS"

const createUpdateAddress = ({ id, addressName, countryId, city, postalCode, street, floorDoor, company, vatId, }) => (dispatch, getState) => {
  const state = getState()

  return dispatch({
    type: CREATE_UPDATE_ADDRESS,
    id,
    payload: api.addresses.createUpdateAddress({
      language: state.context.language,
      token: state.user.token,
      payload: {
        id,
        addressName,
        countryId,
        city,
        postalCode,
        street,
        floorDoor,
        company,
        vatId,
      },
    }),
  })
}

createUpdateAddress.action = CREATE_UPDATE_ADDRESS

export default createUpdateAddress
