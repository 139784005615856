import flow from "./flow"
import campaign from "./campaign"
import shop from "./shop"
import user from "./user"
import context from "./context"
import product from "./product"
import categories from "./categories"
import payment from "./payment"
import shoppingCart from "./shopping-cart"
import entity from './entity'
import deliveries from './deliveries'
import addresses from "./addresses"
import orders from "./orders"

export default {
  flow,
  campaign,
  shop,
  user,
  context,
  product,
  categories,
  payment,
  shoppingCart,
  entity,
  deliveries,
  addresses,
  orders,
}
