import facepaint from "facepaint";

// CSS media queries helpers configured with the default Bootstrap breakpoints
// Import this to create CSS rules with different properties for different
// screen sizes
export const mq = facepaint([
  "@media(min-width: 576px)",
  "@media(min-width: 768px)",
  "@media(min-width: 992px)",
  "@media(min-width: 1200px)"
]);
