const selectors = {};

const categoriesById = (categories) => {
  return (categories || []).reduce((accum, category) => {
    let subcats;
    if (!category) return {};
    if (category.subcategories) {
      subcats = categoriesById(category.subcategories);
    }
    const id = category.categoryId || category.subcategoryId;
    const name = category.categoryName || category.subcategoryName;
    return {
      ...accum,
      ...subcats,
      [id]: {
        id,
        name,
      },
    };
  }, {});
};

selectors.priceListView = (state) => state.shop.priceListView;
selectors.addressView = (state) => state.shop.addressView;
selectors.address = (state) => {
  const addressView = state.shop.addressView

  let address = [
    addressView.address1,
    addressView.address2,
    addressView.address3,
  ].filter(a => !!a).join(' ')

  address += ', ' + addressView.zipcode

  if (addressView.zone) {
    address += ' ' + addressView.zone
  } else if (addressView.county) {
    address += ' ' + addressView.county
  } else {
    address += ' ' + addressView.district
  }

  return address
}
selectors.shopMerchantServices = (state) => state.shop.shopMerchantServices;
selectors.categoriesById = (state) => categoriesById(state.shop.categoryView.categories);
selectors.images = (state) => state.shop.shopImages ?? []
selectors.allProducts = (state) => {
  const priceList = selectors.priceListView(state)
  return !!priceList ? priceList.sections.reduce((a, b) => [...a, ...b.items], []) : []
}

selectors.shopMerchantService = (serviceId) => (state) => {
  const services = selectors.shopMerchantServices(state);
  return services.find((service) => service.id === serviceId);
};

selectors.calendarSlots = (serviceId) => (state) => {
  return state.shop.calendarSlots[serviceId];
};

export default selectors;
