import Icon from "@mdi/react";
import {
  mdiNote,
  mdiChevronDown,
  mdiChevronUp,
  mdiChevronLeft,
  mdiChevronRight,
  mdiCheckCircle,
  mdiCloseCircle,
  mdiLockQuestion,
  mdiLoginVariant,
  mdiAccountCardDetailsOutline,
  mdiMinus,
  mdiPlus,
  mdiBarcodeScan,
  mdiTicketPercent,
  mdiMagnify,
  mdiMapMarker,
  mdiHome,
  mdiAccountOutline,
  mdiPhoneOutline,
} from "@mdi/js";
import Calendar from "./calendar.svg";
import Edit from "./edit.svg";
import Email from "./email.svg";
import Share from "./share.svg";
import People from "./people.svg";
import Smartphone from "./smartphone.svg";
import Telephone from "./telephone.svg";
import Voucher from "./voucher.svg";
import FacebookF from "./facebook-f.svg";
import Google from "./google.svg";
import Apple from "./apple.svg";

const icons = {
  calendar: Calendar,
  edit: Edit,
  email: Email,
  share: Share,
  people: People,
  smartphone: Smartphone,
  telephone: Telephone,
  voucher: Voucher,
  facebookF: FacebookF,
  apple: Apple,
  google: Google,
  mdiNote,
  mdiChevronUp,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiCheckCircle,
  mdiCloseCircle,
  mdiLockQuestion,
  mdiLoginVariant,
  mdiAccountCardDetailsOutline,
  mdiMinus,
  mdiPlus,
  mdiTicketPercent,
  mdiMagnify,
  mdiMapMarker,
  mdiBarcodeScan,
  mdiHome,
  mdiAccountOutline,
  mdiPhoneOutline,
};

/**
 * Wrap the SVG icons because we can't use `rem` width/height in Firefox Mobile
 * https://bugzilla.mozilla.org/show_bug.cgi?id=1231147
 */
export const IconWrapper = ({
  name,
  size,
  width,
  height,
  className,
  ...props
}) => {
  let component;

  let w = width || size;
  let h = height || size;

  const wrapperClassName = ["icon-wrapper", className]
    .filter(Boolean)
    .join(" ");

  const isMdiIcon = name.indexOf("mdi") === 0;
  if (isMdiIcon) {
    component = (
      <Icon
        width="100%"
        height="100%"
        path={icons[name]}
        {...props}
      />
    );
  } else {
    const Component = icons[name];
    component = <Component width="100%" height="100%" {...props} />;
  }
  return (
    <span
      className={wrapperClassName}
      style={{ width: w, height: h, display: "inline-block" }}
    >
      {component}
    </span>
  );
};

export { Calendar, Edit, Email, Share, People, Smartphone, Telephone, Voucher };
