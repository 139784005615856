import React, {useCallback, useEffect, useMemo, useState} from "react"
import {css} from "@emotion/core"
import {useDispatch, useSelector} from "react-redux"
import {useTranslation} from "react-i18next"
import selectors from "lib/redux/selectors"
import actions from "../../lib/redux/actions"
import {calculateDistanceInMeters, getUserLocation} from "../../lib/helpers/location"
import {IconWrapper} from "../icons"
import cssVars from "../../styles/variables.module.scss"
import {Form, Spinner} from "react-bootstrap"

const styles = {
  container: css({
    display: "flex",
    fontSize: "0.9rem",
    columnGap: "0.6rem",
  }),
  searchFieldContainer: css({
    flexGrow: 1,
    padding: "0.3rem",
    border: `1px solid ${cssVars.gray500}`,
    borderRadius: "0.5rem",
    position: "relative",
    display: "flex",
    alignItems: "center",
    "input": css({
      border: 'none',
      width: "100%",
    }),
    ".icon-wrapper": css({
      position: "absolute",
      right: 6,
      color: cssVars.green,
    }),
  }),
  searchNearMe: css({
    width: "40%",
    border: `2px solid ${cssVars.green}`,
    color: cssVars.green,
    backgroundColor: "white",
    borderRadius: "0.5rem",
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&.active": css({
      backgroundColor: cssVars.green,
      color: "white",
    }),
  }),
  pickupPointsContainer: css({
    marginTop: "0.6rem",
    width: "100%",
  }),
  spinnerContainer: css({
    position: "absolute",
    zIndex: 10,
    width: '100%',
    minHeight: '200px',
    maxHeight: '100%',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255,255,255,.8)",
  }),
  pickupPointsList: css({
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
    position: 'relative',
    alignItems: "center",
    flexDirection: "column",
    placeItems: "start",
    rowGap: "0.6rem",
    ".pickupPoint": css({
      width: "100%",
      ".custom-control-label": css({
        width: "100%",
        "&::before, &::after": css({
          top: "0",
          bottom: "0",
          margin: "auto 0",
        }),
        ".name": css({
          fontWeight: 500,
          marginBottom: 0,
          fontSize: "1rem",
        }),
        ".address": css({
          fontSize: "0.8rem",
          marginBottom: 0,
        }),
      })
    }),
  }),
}

export default function PickupPointSearch({ pickupPointId, onSetPickupPoint }) {

  const {t} = useTranslation()

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const [search, setSearch] = useState('')
  const [filteringByLocation, setFilteringByLocation] = useState(false)
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
  })
  const dispatch = useDispatch()

  const selectedShopDeliveryMethodIds = useSelector(selectors.deliveries.selectedDeliveryMethodIds)
  const pickupPoints = useSelector(selectors.deliveries.pickupPoints)

  const pickupPointList = useMemo(() => {
    return pickupPoints.map(pickupPoint => {

        if (location.latitude && location.longitude) {
          const distanceInMeters = calculateDistanceInMeters(location.latitude, location.longitude, pickupPoint.latitude, pickupPoint.longitude)

          const distance = distanceInMeters > 1000
            ? `${(distanceInMeters / 1000).toFixed(1)} km`
            : `${distanceInMeters} m`

          return {
            ...pickupPoint,
            distance,
          }
        }

        return pickupPoint
      })
      .sort((a, b) => {
        if (location.latitude && location.longitude) {
          return a.distance - b.distance
        }

        return a.id - b.id
      })
  }, [pickupPoints, location])

  const getLocationCoordinates = useCallback(() => {
    if (filteringByLocation) {
      setFilteringByLocation(false)
      searchPickupPoints()
      return
    }

    getUserLocation().then((location) => {
      setFilteringByLocation(true)
      setLocation(location)
      searchPickupPoints(location)
    }).catch(e => {
      console.error('get user location error', e)
    })
  })

  useEffect(() => {
    searchPickupPoints(location)
  }, [])

  const searchPickupPoints = useCallback((location) => {
    if (selectedShopDeliveryMethodIds && selectedShopDeliveryMethodIds.length > 0) {

      const filters = {
        shopDeliveryMethodIds: selectedShopDeliveryMethodIds.join(','),
      }

      if (location) {
        filters.latitude = location.latitude
        filters.longitude = location.longitude
      }

      setIsLoading(true)
      dispatch(actions.deliveries.fetchPickupPoints(filters))
        .then(response => {
          setIsLoading(false)
          setError(null)
        })
        .catch(e => {
          setIsLoading(false)
          setError(e)
        })
    }
  }, [location, selectedShopDeliveryMethodIds])

  return (
    <>
      <div css={styles.container}>
        <div css={styles.searchFieldContainer}>
          <input
            value={search}
            placeholder={t('forms.location')}
            onChange={(e) => setSearch(e.target.value)}
          />

          <IconWrapper name="mdiMagnify" size="1.6rem"/>
        </div>

        <button
          type="button"
          css={styles.searchNearMe}
          className={filteringByLocation ? 'active' : ''}
          onClick={() => getLocationCoordinates()}
        >
          <IconWrapper name="mdiMapMarker" size="1.5rem"/>

          <span>{t('forms.nearMe')}</span>
        </button>
      </div>
      <div css={styles.pickupPointsContainer}>
        {isLoading && (
          <div css={styles.spinnerContainer}>
            <Spinner animation="border" role="status">
              <span className="sr-only">{t("loading")}...</span>
            </Spinner>
          </div>
        )}
        {!isLoading && !error && pickupPointList.length === 0 && (
          <div css={styles.spinnerContainer}>
            <p>{t('delivery.pickupPoints.noResults')}</p>
          </div>
        )}
        {!isLoading && error && (
          <div css={styles.spinnerContainer}>
            <p className="text-danger">{t('delivery.pickupPoints.searchError')}</p>
          </div>
        )}
        <Form.Group
          as="ul"
          css={styles.pickupPointsList}
        >
          {pickupPointList.map((pickupPoint) => (
            <Form.Check
              custom
              as="li"
              type="radio"
              id={pickupPoint.id}
              key={pickupPoint.id}
              name="pickupPoint"
              className="pickupPoint"
            >
              <Form.Check.Input
                type="radio"
                checked={pickupPointId === pickupPoint.id}
                onChange={() => {
                  onSetPickupPoint(pickupPoint)
                }}
              />
              <Form.Check.Label>
                <p className="name">{pickupPoint.name}</p>
                <p
                  className="address">{pickupPoint.address}, {pickupPoint.postalCode} {pickupPoint.city} {pickupPoint.distance && (`(${pickupPoint.distance})`)}</p>
              </Form.Check.Label>
            </Form.Check>
          ))}
        </Form.Group>
      </div>
    </>
  )
}
