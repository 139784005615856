import fetchDeliveryMethods from "./fetch-delivery-methods"
import fetchDeliveryCountries from "./fetch-delivery-countries"
import fetchDeliveryRates from "./fetch-delivery-rates"
import fetchPickupPoints from "./fetch-pickup-points"

export default {
  fetchDeliveryMethods,
  fetchDeliveryCountries,
  fetchDeliveryRates,
  fetchPickupPoints,
}
