import React, {useCallback, useEffect, useState} from "react";
import withErrorModal from "lib/hocs/with-error-modal";
import {Col, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {addHours, formatDate, getJavaDateOffset} from "lib/helpers/calendar";
import actions from "lib/redux/actions";
import {PaymentMethods} from "lib/helpers/constants";
import {triggerEvent} from "lib/analytics";
import {getParentUrl} from "lib/helpers/utils";
import selectors from "../../../lib/redux/selectors";


function HipayMbPayment({ onFormikProps, showModal }) {
  const { t } = useTranslation();
  const campaign = useSelector((state) => state.campaign);
  const flow = useSelector((state) => state.flow.steps);
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const isPremiumReservation = useSelector(selectors.flow.isPremiumReservation)

  // Get the URL to the payment processing page (HiPay) so that users can
  // complete the payment of the voucher(s) they are purchasing once they click
  // the submit button.
  const getPaymentUrl = async () => {
    // Since we're effectively only sending full day information as the payment
    // link expiration date, make sure we give at least 2 hours for people to
    // complete payment. Otherwise, a payment at 23:59 would only allow for one
    // minute to be completed.
    const expireDate = addHours(new Date(), 2);
    const { campaignView } = campaign.info;
    const { campaignId, campaignType } = campaignView;
    const { numberOfPeople, needVatInvoice, name, vatId, prizeId } = flow.voucher;
    const { email } = flow

    const result = await dispatch(
      actions.campaign.getHiPayPaymentLink({
        expireDate: `${formatDate(expireDate)}T00:00:00${getJavaDateOffset()}`,
        invoiceName: needVatInvoice ? name : "",
        invoiceFiscalId: needVatInvoice ? vatId : "",
        onBehalfOfName: "",
        campaignId: String(campaignId),
        campaignTypeId: campaignType,
        quantityPurchased: numberOfPeople,
        returnUrl: getParentUrl() || "",
        prizeTransactionId: prizeId,
        paymentMethod: PaymentMethods.HIPAY_MB,
        paymentReservationType: isPremiumReservation ? 'premium' : 'voucher',
        email: email,
      })
    );

    const { id, urlPaymentPlatform } = result.action.payload.data;
    return {
      paymentId: id,
      paymentUrl: urlPaymentPlatform,
    };
  };

  const handlePay = useCallback(async () => {
    setSubmitting(true);

    try {
      const { paymentId, paymentUrl } = await getPaymentUrl();

      // Redirect the top browser window to HiPay's payment page
      triggerEvent(
        "Purchase",
        "buy_as_gift_click_go_to_payment_gateway",
        "MB",
        {
          dimension10: paymentId,
        }
      );

      window.top.location.href = paymentUrl;

      // Keep setSubmitting=true so that we don't allow new form submissions
      // while the redirection is happening.
    } catch (err) {
      showModal(err.message);
      setSubmitting(false);
    }
  }, []);

  useEffect(
    function updateFormikProps() {
      onFormikProps({
        submitForm: handlePay,
        submitLabel: t("flow.continue"),
        isValid: true,
        isSubmitting: submitting,
      });
    },
    [submitting]
  );

  return (
    <div>
      <Row>
        <Col>
          <span className="medium-text">{t('paymentMethods.hipayMb.notice')}</span>
        </Col>
      </Row>
    </div>
  )
}

export default withErrorModal(HipayMbPayment);
