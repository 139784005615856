import { css } from "@emotion/core";
import { createDate, formatDate, isDateAvailable } from "lib/helpers/calendar";
import selectors from "lib/redux/selectors";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import cssVars from "styles/variables.module.scss";
import ChosenDatesList from "./chosen-dates-list";
import Timetable from "./timetable";
import WeekDaySelector from "./week-day-selector";

const styles = {
  calendarContainer: css({
    ".week-day-container": {
      borderStyle: "solid",
      borderColor: cssVars.gray500,
      borderWidth: "1px",
    },
    ".timetable-container": {
      borderStyle: "solid",
      borderColor: cssVars.gray500,
      borderWidth: "0 1px 1px 1px",
    },
  }),
};

/**
 * Flow Step 0 for Campaign reservations
 */
export default function Calendar({
  showTimetable,
  maxSelections,
  pricing,
  onDateChange,
  onDateTimesChange,
  // Flow state
  date,
  dateTimes,
}) {
  const [
    selectedWeekIncludesSelectedDay,
    setSelectedWeekIncludesSelectedDay,
  ] = useState(0);
  const { startDate, endDate, nonAvailableDays } = useSelector(
    selectors.product.schedule
  );
  const [activeDate, setActiveDate] = useState(
    date || formatDate(startDate || new Date())
  );
  const [chosenDateTimes, setChosenDateTimes] = useState(
    dateTimes ||
      [
        // Format:
        // {
        //   date: "2019-12-31",
        //   times: [ { time: "12:30", selectedAt: timestamp } ]
        // }
      ]
  );

  const remainingSelections = useMemo(() => {
    return chosenDateTimes.reduce((accum, entry) => {
      return accum - entry.times.length;
    }, maxSelections);
  }, [maxSelections, chosenDateTimes]);

  const handleTimeSelectionChange = useCallback(
    (times) => {
      // Receive the times for the given date. The received times fully replace
      // the times that already exist for the active date or a new entry is
      // created.
      if (!activeDate) return;
      const baseDateTimes = maxSelections !== 1 ? chosenDateTimes : [];
      const dateTimeEntry = baseDateTimes.find(
        (entry) => entry.date === activeDate
      );

      const newChosenDateTimes = baseDateTimes.filter(
        (entry) => entry.date !== activeDate
      );
      if (times && times.length) {
        newChosenDateTimes.push({
          ...dateTimeEntry,
          date: activeDate,
          times,
        });
      }
      setChosenDateTimes(newChosenDateTimes);
      return newChosenDateTimes;
    },
    [chosenDateTimes, activeDate]
  );

  const handleRemoveDateTime = useCallback(
    (date, time) => {
      const dateTimeEntry = chosenDateTimes.find(
        (entry) => entry.date === date
      );
      if (!dateTimeEntry) return chosenDateTimes;
      const newDateTimeEntry = {
        ...dateTimeEntry,
        times: dateTimeEntry.times.filter((entry) => entry.time !== time),
      };

      const newChosenDateTimes = chosenDateTimes.filter(
        (entry) => entry.date !== date
      );

      if (newDateTimeEntry.times.length) {
        newChosenDateTimes.push(newDateTimeEntry);
      }

      setChosenDateTimes(newChosenDateTimes);
      return newChosenDateTimes;
    },
    [chosenDateTimes]
  );

  const calendarScheduleProps = useMemo(() => {
    return {
      startDay: formatDate(createDate(startDate)),
      endDay: formatDate(createDate(endDate)),
      unavailableDays: nonAvailableDays.map((d) => formatDate(createDate(d))),
    };
  }, [startDate, endDate, nonAvailableDays]);

  const currentDateIsAvailable = useMemo(
    () =>
      isDateAvailable(
        activeDate,
        calendarScheduleProps.startDay,
        calendarScheduleProps.endDay,
        calendarScheduleProps.unavailableDays
      ),
    [activeDate, calendarScheduleProps]
  );

  const activeDateTimes = useMemo(() => {
    const entry = chosenDateTimes.find((entry) => entry.date === activeDate);
    return entry ? entry.times : [];
  }, [chosenDateTimes, activeDate]);

  useEffect(function setAvailableDay() {
    onDateChange(activeDate, currentDateIsAvailable, false);
  }, []);

  return (
    <div className="calendar-container" css={styles.calendarContainer}>
      {maxSelections !== 1 && (
        <div>
          <ChosenDatesList
            dateTimes={chosenDateTimes}
            onRemove={(date, time) => {
              const dateTimes = handleRemoveDateTime(date, time);
              onDateTimesChange(dateTimes);
            }}
          />
        </div>
      )}
      <div className="week-day-container">
        <WeekDaySelector
          {...calendarScheduleProps}
          date={activeDate}
          dateTimes={chosenDateTimes}
          onSelectedDayAndWeekMatchChange={setSelectedWeekIncludesSelectedDay}
          onDateChange={(date, availableDay, allowAutoSubmit) => {
            setActiveDate(date);
            onDateChange(date, availableDay, allowAutoSubmit);
          }}
        />
      </div>
      {showTimetable && (
        <div className="timetable-container">
          <Timetable
            isDayAvailable={currentDateIsAvailable}
            selectedWeekIncludesSelectedDay={selectedWeekIncludesSelectedDay}
            selectedDay={activeDate}
            selections={activeDateTimes}
            pricing={pricing}
            onSelectionChange={async (selections) => {
              const dateTimes = handleTimeSelectionChange(selections);
              onDateTimesChange(dateTimes);
            }}
            maxSelections={maxSelections}
            remainingSelections={remainingSelections}
          />
        </div>
      )}
    </div>
  );
}
