import React from "react";
import { Button } from "react-bootstrap";
import { css } from "@emotion/core";
import { useTranslation } from "react-i18next";
import { formatPrice, stringPriceToFloat } from "lib/helpers/utils";
import cssVars from "styles/variables.module.scss";

const styles = {
  planContainer: css({
    textAlign: "center",
    ".plan-details": {
      padding: "0 0.3rem",
      border: `5px solid ${cssVars.gray500}`
    },
    ".duration": {
      color: cssVars.black,
      fontSize: "1.2rem",
      fontWeight: "bold",
      padding: "0.5rem 0",
      textTransform: "uppercase",
      borderBottom: `1px solid ${cssVars.gray500}`
    },
    ".price-total": {
      color: cssVars.smart,
      paddingTop: "0.5rem",
      fontWeight: "bold",
      fontSize: "1.5rem"
    },
    ".price-month": {
      color: cssVars.gray600,
      fontSize: "0.8rem"
    },
    ".btn": {
      margin: "0.7rem 0",
      padding: "0.2em 1.2em",
      fontSize: "0.85rem",
      minWidth: "6rem"
    }
  }),
  bestPlan: css({
    ".plan-details": {
      borderColor: cssVars.smart
    },
    ".best-label": {
      backgroundColor: cssVars.smart,
      color: cssVars.white,
      fontSize: "0.8rem",
      fontWeight: 500,
      paddingTop: "0.1rem",
      paddingBottom: "0.35rem"
    }
  })
};

export default function SmartPlanItem({
  id, // 1
  description, // "1 Mês"
  period, // 30,
  useLimit, // 1
  months, // 1
  price, // "10"
  totalPrice, // "10"
  urlHiPay, // "https://payment.hipay.com/index/mapi/id/abcdefg",
  isBest
}) {
  const { t } = useTranslation();

  const handleClickBuy = () => {
    window.top.location.href = urlHiPay;
  };

  return (
    <div css={[styles.planContainer, isBest && styles.bestPlan]}>
      <div className="plan-details">
        <div className="duration">{description}</div>
        <div className="price-total">{`${totalPrice} €`}</div>
        <div className="price-month">
          {t("smart.pricePerMonth", { price: `${price} €` })}
        </div>
        <Button variant="smart-pill" onClick={handleClickBuy}>
          {t("smart.buy")}
        </Button>
      </div>
      {isBest && <div className="best-label">{t("smart.bestChoice")}</div>}
    </div>
  );
}
