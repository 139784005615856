import {tryGetLocationCoordinates} from "./inter-frame-communication"

export const getUserLocation = () => {
  return new Promise((resolve, reject) => {
    tryGetLocationCoordinates()
      .then((position) => {
        if (position.latitude && position.longitude) {
          resolve({
            latitude: position.latitude,
            longitude: position.longitude,
          })
        }
      }).catch(() => {

      if (!navigator.geolocation) {
        //TODO: error or ignore?
        reject()
        return
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position.coords.latitude && position.coords.longitude) {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            })
          }
        },
        (error) => {
          //TODO: error or ignore?
          reject()
        },
      )
    })
  })
}

export const calculateDistanceInMeters = (lat1, lon1, lat2, lon2) => {
  const EARTH_RADIUS = 6371000 // Radius of the Earth in meters

  const dLat = toRadians(lat2 - lat1)
  const dLon = toRadians(lon2 - lon1)
  const a = Math.sin(dLat / 2)
    * Math.sin(dLat / 2)
    + Math.cos(toRadians(lat1))
    * Math.cos(toRadians(lat2))
    * Math.sin(dLon / 2)
    * Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distanceInMeters = EARTH_RADIUS * c

  // don't show too precise distance which may be inaccurate
  // return rounded to closest 50 meters in distance
  return Math.round(distanceInMeters / 50) * 50
}

const toRadians = (degrees) =>  {
  return degrees * Math.PI / 180.0
}
