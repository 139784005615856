import dayjs from "dayjs";

const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

const MS_PER_DAY = 1000 * 60 * 60 * 24;

/** Pads a time element (h or m) to 2 digits. Ex: 3 -> "03". */
export const pad2 = (timeElement) => String(timeElement).padStart(2, "0");

/**
 * Creates the expected Java format timezone string. For instance, for Germany
 * during non-DST (winter) time, we'd get "+01:00".
 */
export const getJavaDateOffset = () => {
  const offsetMinutes = new Date().getTimezoneOffset() * -1;
  const symbol = offsetMinutes >= 0 ? "+" : "-";
  const [hh, mm] = [
    pad2(Math.floor(offsetMinutes / 60)),
    pad2(offsetMinutes % 60),
  ];
  return `${symbol}${hh}:${mm}`;
};

/**
 * Creates  a new Date() instance given a string and time representation.
 * If it is a date representation as received by the Middleware, a Java date
 * string representation, it will be converted to a ISO 8601 format.
 *
 * Note that JS can usually parse the Java date string format but it fails to do
 * so in iPhones.
 *
 * @param {*} dateString
 */
export const createDate = (dateString) => {
  // Java Dates:       2020-04-06T23:39:47.387+0000
  // JavaScript Dates: 2020-04-06T23:39:47.387Z
  const asISOString = String(dateString).replace(/([+].*)/, "Z");
  return new Date(asISOString);
};

export const createJavaDate = (dateYmd, timeHm) => {
  const [hours, minutes] = timeHm.split(":");
  const hoursPadded = pad2(hours);
  const minutesPadded = pad2(minutes);
  const paddedTimeHm = [hoursPadded, minutesPadded].join(":");
  return `${dateYmd}T${paddedTimeHm}:00${getJavaDateOffset()}`;
};

export const addMinutes = (d, minutes) => {
  const date = new Date(d);
  date.setTime(date.getTime() + minutes * 60 * 1000);
  return date;
};

export const addHours = (d, hours) => {
  const date = new Date(d);
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);
  return date;
};

export const addDays = (d, days) => {
  const date = new Date(d);
  date.setDate(date.getDate() + days);
  return date;
};

export const addYears = (d, years) => {
  const date = new Date(d);
  date.setFullYear(date.getFullYear() + years);
  return date;
};

export const getMonday = (d) => {
  const date = new Date(d);
  const day = date.getDay();
  // Adjust when day is sunday
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  date.setDate(diff);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getDatesOfTheWeek = (d, weeksOffset) => {
  const date = addDays(d, weeksOffset * 7);
  const mondayDate = getMonday(date);
  return [...Array(7)].map((v, index) => addDays(mondayDate, index));
};

export const getDatesWeeksOffset = (d1, d2) => {
  const datesOfTheWeek1 = getDatesOfTheWeek(d1, 0);
  const datesOfTheWeek2 = getDatesOfTheWeek(d2, 0);
  const monday1 = datesOfTheWeek1[0];
  const monday2 = datesOfTheWeek2[0];
  const mondaysDiffDays = dateDiffInDays(monday1, monday2);

  return Math.floor(mondaysDiffDays / 7);
};

/**
 * Returns all the days (in the YYYY-MM-DD format) between two dates (Date
 * objects).
 */
export const getDaysArray = (s, e, inclusive = true) => {
  const start = new Date(s);
  start.setHours(1);
  const end = new Date(e);
  end.setHours(23);
  for (var a = [], d = start; d <= end; d.setDate(d.getDate() + 1)) {
    a.push(formatDate(d));
  }
  if (inclusive) {
    return a;
  }
  return a.slice(1, -1);
};

/**
 * Formats a date object in the "YYYY-MM-DD" format and returns it.
 * @param {Date} d - The date object to format
 */
export const formatDate = (d) => {
  const date = new Date(d);
  let month = "" + (date.getMonth() + 1);
  let day = "" + date.getDate();
  let year = date.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const formatTime = (time) => {
  return time && time.replace(":", "h");
};

export const formatMilitaryTime = (time) => {
  const timeStr = String(time);
  const parts = [timeStr.slice(0, 2), timeStr.slice(2)];
  return parts.join("h");
};

export const dateDiffInDays = (d1, d2) => {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate());
  const utc2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate());
  return Math.floor((utc2 - utc1) / MS_PER_DAY);
};

export const isDateAvailable = (d, startDay, endDay, unavailableDays) => {
  const dateYmd = formatDate(d);
  return (
    dateYmd >= startDay &&
    dateYmd <= endDay &&
    !unavailableDays.includes(dateYmd)
  );
};

export const getBookingStartTimeAsap = (timeToPrepInMinutes) => {
  const dWithTimeToPrep = addMinutes(new Date(), timeToPrepInMinutes);
  const dateYmd = formatDate(dWithTimeToPrep);

  const hoursPadded = pad2(dWithTimeToPrep.getHours());
  const minutesPadded = pad2(dWithTimeToPrep.getMinutes());
  const paddedTimeHm = [hoursPadded, minutesPadded].join(":");

  return `${dateYmd}T${paddedTimeHm}:00${getJavaDateOffset()}`;
};

/**
 * Checks if a given time is inside the given working hours but takes into
 * account the time it takes to prepare the order. So, the time is actually
 * shifted the number of minutes it takes to prepare the order.
 */
export const matchesWorkingHours = (d, startTime, endTime, timeToPrep = 0) => {
  const dTime = d.getHours() * 100 + d.getMinutes();
  const dTimeInclPrep = addMinutesToHhmm(dTime, timeToPrep);
  const dTimeTomorrowInclPrep = dTimeInclPrep + 2400;

  const start = parseInt(startTime, 10) || 0;
  let end = parseInt(endTime, 10) || 2400;
  const goesThroughMidnight = end < start;
  if (goesThroughMidnight) {
    end += 2400;
  }

  return (
    (start <= dTimeInclPrep && dTimeInclPrep <= end) ||
    (start <= dTimeTomorrowInclPrep && dTimeTomorrowInclPrep <= end)
  );
};

/**
 * Transforms the following:
 *
 *     [
 *       {
 *         date: "2020-01-17",
 *         times: [{ time: "11:30", selectedAt: <timestamp in ms> }]
 *       },
 *       {
 *         date: "2020-01-18",
 *         times: [{ time: "12:30", selectedAt: <timestamp in ms> }]
 *       }
 *     ]
 *
 * Into the following:
 *
 *     [
 *       { date: "2017-01-18", time: "12:30", selectedAt: <timestamp in ms> },
 *       { date: "2017-01-17", time: "11:30", selectedAt: <timestamp in ms> }
 *     ]
 *
 * Ordered by ascending `selectedAt` values.
 *
 * @param {*} dateTimes - A dateTimes object
 * @returns {Array} - An array with sorted date and time entires.
 */
export const dateTimesObjectToOrderedArray = (dateTimes) => {
  const dateTimesArray = dateTimes.map((entry) =>
    entry.times.map((timeEntry) => ({
      date: entry.date,
      time: timeEntry.time,
      selectedAt: timeEntry.selectedAt,
    }))
  );
  return dateTimesArray.flat().sort((a, b) => {
    const selectedAtA = a.selectedAt;
    const selectedAtB = b.selectedAt;
    if (selectedAtA < selectedAtB) {
      return -1;
    }
    if (selectedAtA > selectedAtB) {
      return 1;
    }
    return 0;
  });
};

/**
 * Checks if dateTimes object contains at least one date/time that is
 * at least `hours` hours in the future.
 * @param {array} dateTimes
 * @param {number} hours
 */
export const dateTimesInTheFutureAtLeast = (dateTimes, hours) => {
  const {date, times} = dateTimes;

  const foundMatch = times.find((timeEntry) => {
    const dateTime = `${date} ${timeEntry.time}`;
    const now = Date.now();
    const d = new Date(dateTime).getTime();
    const diffInMs = d - now;
    return diffInMs >= hours * 60 * 60 * 1000;
  });

  return !!foundMatch;
};

/**
 * Given a time in hhmm format, as a number, such as 1630, add an arbitrary
 * number of minutes.
 */
const addMinutesToHhmm = (hhmm, minutes, allowOver24h = false) => {
  const [h, m] = [Math.floor(hhmm / 100), hhmm % 100];
  const [hToSum, mToSum] = [Math.floor((m + minutes) / 60), minutes % 60];
  const hSummed = allowOver24h ? h + hToSum : (h + hToSum) % 24;
  const mSummed = (m + mToSum) % 60;
  return hSummed * 100 + mSummed;
};

/**
 * Given a time already in military format but that can also be larger than
 * 2400, returns the equivalent time (since midnight) and a label in the regular
 * hh:mm format.
 *
 * @param {number} time Ex: 900, 1800, 2330, 2430
 */
export const getTimeInfo = (time) => {
  let hours;
  let minutes;
  if (time >= 2400) {
    hours = Math.floor((time - 2400) / 100);
    minutes = time - 2400 - 100 * hours;
  } else {
    hours = Math.floor(time / 100);
    minutes = time - 100 * hours;
  }
  const hoursPadded = pad2(hours);
  const minutesPadded = pad2(minutes);
  const militaryTime = hours * 100 + minutes;
  const label = `${hoursPadded}:${minutesPadded}`;

  return {
    minutes,
    hours,
    time: militaryTime,
    label,
  };
};
