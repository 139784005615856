import React, { useState } from "react";
import { css } from "@emotion/core";
import { useTranslation } from "react-i18next";
import { Button, Container, Form } from "react-bootstrap";
import { formatPrice } from "lib/helpers/utils";
import cssVars from "styles/variables.module.scss";
import { mdiBorderNone } from "@mdi/js";

const styles = {
  container: css({
    ".container": {
      borderBottom: `1px solid ${cssVars.gray500}`,
    },
    ".menu-item-wrapper": {
      display: "flex",
      alignItems: "stretch",
      padding: "1rem 0",
    },
    ".naming-col": {
      flex: 1,
      ".name": {
        fontSize: "1.1rem",
        fontWeight: 500,
        color: cssVars.black,
      },
      ".description": {
        fontSize: "0.85rem",
        marginTop: "1rem",
      },
    },
    ".end-cols": {
      paddingLeft: "0.5rem",
      ".price": {
        fontSize: "1.4rem",
        fontWeight: 500,
        color: cssVars.gray800,
      },
      ".add-button": {
        fontSize: "1.4rem",
        fontWeight: 500,
        border: "none",
        color: "white",
        backgroundColor: cssVars.primary,
      },
    },
  }),
};

export default function MenuItem({ item, onAction }) {
  const { t } = useTranslation();

  const {
    name,
    description,
    price,
    currency,
    takeawayDeliveryAvailable,
  } = item;

  const canAddToCart = !!(onAction && takeawayDeliveryAvailable);

  return (
    <div className="menu-item-container" css={styles.container}>
      <Container>
        <div className="menu-item-wrapper">
          <div className="naming-col">
            <div className="name">{name}</div>
            <div className="description">{description}</div>
          </div>
          <div className="end-cols">
            <div className="price">{formatPrice(price, currency)}</div>
          </div>
          {canAddToCart && (
            <div className="end-cols">
              <Button
                css={styles.submitButton}
                onClick={() => onAction(item)}
              >
                {t("cart.add")}
              </Button>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}
