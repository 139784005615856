import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "@emotion/core";
import { useTranslation } from "react-i18next";
import { Container, Form } from "react-bootstrap";
import actions from "lib/redux/actions";
import selectors from "lib/redux/selectors";

const styles = {
  container: css({
    paddingTop: "0.5rem",
    paddingBottom: 0,
    ".container": {
      display: "flex",
      flexWrap: "wrap"
    },
    ".title, .custom-control": {
      marginBottom: "0.5rem",
      marginRight: "1rem"
    },
    label: {
      fontWeight: "bold"
    }
  })
};

/**
 * The gray bar where logged in users can select to use a prize (discount).
 */
export default function PrizeSelector({ prizeId, onPrizeIdChange, title }) {
  const { t } = useTranslation();
  const loggedIn = useSelector(selectors.user.loggedIn);
  const serviceId = useSelector(selectors.flow.serviceId);
  const prizes = useSelector(selectors.user.prizes);
  const dispatch = useDispatch();

  const [selectedPrizeId, setSelectedPrizeId] = useState(prizeId || null);

  useEffect(
    function fetchPrizes() {
      if (!loggedIn) return;
      dispatch(actions.user.fetchPrizes()).catch(err => {
        // Ignore errors because the request is not user-initiated and an error
        // popup would confuse users. Just don't show any prizes.
      });
    },
    [loggedIn]
  );

  if (!prizes || !prizes.length || !!serviceId) {
    return null;
  }

  return (
    <div
      className="prizes-selector-wrapper gray-container"
      css={styles.container}
    >
      <Container>
        <div className="title">{title}</div>
        {prizes.map(prize => (
          <Form.Check
            key={prize.id}
            id={prize.id}
            custom
            inline
            label={prize.prizeTitle}
            type="checkbox"
            checked={prize.id === selectedPrizeId}
            onChange={e => {
              const newPrizeId = e.target.checked ? prize.id : null;
              setSelectedPrizeId(newPrizeId);
              onPrizeIdChange(newPrizeId);
            }}
          />
        ))}
      </Container>
    </div>
  );
}
