import api from "lib/api";

const SHOP_FETCH = "SHOP_FETCH";

const fetchShop = ({ shopId }) => (dispatch, getState) => {
  const state = getState()
  return dispatch({
    type: SHOP_FETCH,
    payload: api.shop.get({
      shopId,
      language: state.context.language,
    })
  });
}

fetchShop.action = SHOP_FETCH;

export default fetchShop;
