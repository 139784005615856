const selectors = {};

selectors.isMobileAppClient = (state) =>
  String(state.context.clientApplication).toLowerCase() !== "web";

selectors.isCordovaAppClient = (state) =>
  String(state.context.clientApplication).toLowerCase() === "web" &&
  String(state.context.clientPlatform).toLowerCase() !== "web";

export default selectors;
