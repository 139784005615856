import React from "react"
import { css } from "@emotion/core";
import {Container} from "react-bootstrap"
import { useTranslation } from "react-i18next";
import { formatPrice } from "lib/helpers/utils";
import {useSelector} from "react-redux"
import selectors from "lib/redux/selectors";

const styles = {
  total: css({
    ".price-before": {
      textDecoration: "line-through",
      textAlign: "right",
    },
    ".discount": {
      textAlign: "right",
    },
    ".pricing-line": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "&.total": {
        fontWeight: "bold",
        fontSize: "1.2rem",
      },
    },
    ".title": {
      fontSize: "1rem",
    },
    ".value": {
      fontSize: "1rem",
    },
  }),
}

/**
 * Flow Step 0 for Takeaway&Delivery and Product checkout
 */
export default function SummaryTotal({ }) {

  const { t } = useTranslation();

  const { discount } = useSelector(selectors.flow.selectedPriceInfo);
  const deliveryCost = useSelector(selectors.deliveries.deliveryCost)
  const pricing = useSelector(selectors.shoppingCart.pricing);

  return (
    <div className="gray-container" css={styles.total}>
      <Container>
        {!!discount && (
          <div className="pricing-line">
            <div className="title">{t("cart.subtotal")}</div>
            <div className="value">
              {formatPrice(pricing.itemsTotal, "€", {
                minFractionDigits: 2,
              })}
            </div>
          </div>
        )}
        {(discount && pricing.discountValue) && (
          <div className="pricing-line">
            <div className="title">{t("cart.discount")}</div>
            <div className="value">
              ({discount}%){" "}
              {formatPrice(-pricing.discountValue, "€", {
                minFractionDigits: 2,
              })}
            </div>
          </div>
        )}
        {!!deliveryCost && (
          <div className="pricing-line">
            <div className="title">{t("cart.deliveryCost")}</div>
            <div className="value">
              {formatPrice(deliveryCost, "€", {
                minFractionDigits: 2,
              })}
            </div>
          </div>
        )}
        <div className="pricing-line total">
          <div className="title">{t("cart.total")}</div>
          <div className="value">
            {formatPrice(pricing.final, "€", {
              minFractionDigits: 2,
            })}
          </div>
        </div>
      </Container>
    </div>
  );
}
