const userSetup = require("./user");
const campaignSetup = require("./campaign");
const shopSetup = require("./shop");
const productSetup = require("./product");
const fetchSetup = require("./fetch");
const categoriesSetup = require("./categories")
const paymentSetup = require('./payment')
const shoppingCartSetup = require('./shopping-cart')
const entitySetup = require('./entity')
const deliveriesSetup = require('./deliveries')
const addressesSetup = require('./addresses')
const ordersSetup = require('./orders')

module.exports = ({ baseUrl, userAgent } = {}) => {
  const fetch = fetchSetup({ baseUrl, userAgent });

  return {
    user: userSetup({ fetch }),
    campaign: campaignSetup({ fetch }),
    shop: shopSetup({ fetch }),
    product: productSetup({ fetch }),
    categories: categoriesSetup({ fetch }),
    payment: paymentSetup({ fetch }),
    shoppingCart: shoppingCartSetup({ fetch }),
    entity: entitySetup({ fetch }),
    deliveries: deliveriesSetup({ fetch }),
    addresses: addressesSetup({ fetch }),
    orders: ordersSetup({ fetch }),
    fetch
  };
};
