const USER_SET_USER_INFO = "USER_SET_USER_INFO";

const setUserInfo = ({ token }) => dispatch =>
  dispatch({
    type: USER_SET_USER_INFO,
    payload: { token }
  });

setUserInfo.action = USER_SET_USER_INFO;

export default setUserInfo;
