import api from "lib/api";

const CAMPAIGN_FETCH_SCHEDULE = "CAMPAIGN_FETCH_SCHEDULE";

const fetchSchedule = ({ campaignId }) => (dispatch, getState) => {
  const state = getState()
  return dispatch({
    type: CAMPAIGN_FETCH_SCHEDULE,
    payload: api.campaign.getSchedule({
      campaignId,
      language: state.context.language,
    })
  });
}

fetchSchedule.action = CAMPAIGN_FETCH_SCHEDULE;

export default fetchSchedule;
