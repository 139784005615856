import React, {useCallback, useEffect, useState} from "react";
import withErrorModal from "lib/hocs/with-error-modal";
import {Col, Form, Row} from "react-bootstrap";
import phoneCodes from "lib/helpers/phone-codes.json";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import selectors from "lib/redux/selectors";
import {css} from "@emotion/core";
import {useTranslation} from "react-i18next";
import {Formik} from "formik";
import {addHours, formatDate, getJavaDateOffset} from "lib/helpers/calendar";
import actions from "lib/redux/actions";
import {PaymentMethods} from "lib/helpers/constants";
import FormikEffect from "components/formik-effect";
import {triggerEvent} from "lib/analytics";

const styles = {
  phoneRow: css({
    display: "flex",
    select: {
      minWidth: "10em",
    },
    ".phone-number": {
      flex: 1,
      marginLeft: "1rem",
    },
  }),
}

const schema = yup.object({
  mbwayPrefix: yup.string(),
  mbwayPhoneNumber: yup
    .string()
    .trim()
    .when("mbwayPrefix", {
      is: "+351",
      then: (schema) => schema.matches(/^9[0-9]{8}$/),
      otherwise: (schema) => schema.matches(/[0-9]+/),
    })
    .required(),
});

function MbwayPayment({ onFormikProps, updateStepState, stepState, onFinish, showModal, onPaymentRequest, paymentFailed }) {
  const { t } = useTranslation();
  const campaign = useSelector((state) => state.campaign);
  const flow = useSelector((state) => state.flow.steps);
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const isPremiumReservation = useSelector(selectors.flow.isPremiumReservation)
  const { prefix, phone } = flow;

  const handleValidSubmit = async () => {
    try {// create mbway payment
      setSubmitting(true);
      onPaymentRequest()

      triggerEvent(
        "Purchase",
        "buy_as_gift_click_go_to_payment_gateway",
        "MBWay"
      );

      const expireDate = addHours(new Date(), 2);
      const {campaignView} = campaign.info;
      const {campaignId, campaignType} = campaignView;
      const {numberOfPeople, needVatInvoice, name, vatId, prizeId} = flow.voucher;

      const { mbwayPrefix, mbwayPhoneNumber, email } = flow

      const result = await dispatch(
        actions.payment.hipayMbwayPaymentRequest({
          expireDate: `${formatDate(expireDate)}T00:00:00${getJavaDateOffset()}`,
          invoiceName: needVatInvoice ? name : "",
          invoiceFiscalId: needVatInvoice ? vatId : "",
          onBehalfOfName: "",
          campaignId: String(campaignId),
          campaignTypeId: campaignType,
          quantityPurchased: numberOfPeople,
          prizeTransactionId: prizeId,
          paymentMethod: PaymentMethods.HIPAY_MBWAY,
          paymentPhoneNumber: mbwayPrefix + String(mbwayPhoneNumber),
          paymentReservationType: isPremiumReservation ? 'premium' : 'voucher',
          email: email,
        })
      );

      const {
        id,
        status,
        message,
      } = result.action.payload.data;

      // payment request successful
      if (status === 'IN_PROGRESS') {
        onFinish()
      } else if (status === 'ERROR_IN_PAY_PLATFORM') {
        // show error - allow retry
        paymentFailed()
        showModal(message)
      }

      // do nothing? it should always be success/in_progress, error_in_platform or exception
    } catch (err) {
      showModal(err.message)
      setSubmitting(false)
    }
  }

  const handleFormChange = useCallback(
    ({ nextValues }) => {
      updateStepState(nextValues);
    },
    [updateStepState]
  );

  useEffect(
    function updateFormikProps() {
      onFormikProps({
        isSubmitting: submitting,
      });
    },
    [submitting]
  );

  useEffect(() => {
    updateStepState({ mbwayPrefix: prefix, mbwayPhoneNumber: phone });
  }, [prefix, phone, updateStepState]);

  return (
    <div>
      <Row>
        <Col>
          <span className="medium-text">{t('paymentMethods.mbway.inputPhone')}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <Formik
            validateOnMount
            validationSchema={schema}
            onSubmit={handleValidSubmit}
            initialValues={{
              mbwayPrefix: stepState.mbwayPrefix || prefix,
              mbwayPhoneNumber: stepState.mbwayPhoneNumber || phone || "",
            }}
          >
            {({
              handleBlur,
              handleChange,
              handleSubmit,
              submitForm,
              values,
              touched,
              errors,
              isValid,
              isSubmitting,
            }) => {
              onFormikProps({
                submitForm,
                submitLabel: t("flow.pay"),
                isValid,
                isSubmitting
              });
              return (
                <>
                  <FormikEffect onChange={handleFormChange} />
                  <Form
                    noValidate
                    className="form padded-step-container"
                    onSubmit={handleSubmit}
                  >
                    <Form.Group css={styles.phoneRow}>
                      <div className="phone-prefix">
                        <Form.Control
                          as="select"
                          className="rounded-underline"
                          name="mbwayPrefix"
                          placeholder={t("forms.phonePrefix")}
                          defaultValue={values.mbwayPrefix}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.mbwayPrefix && !!errors.mbwayPrefix}
                        >
                          {phoneCodes.map((entry) => (
                            <option
                              key={`${entry.code}-${entry.dial_code}`}
                              value={entry.dial_code}
                            >
                              {entry.code} ({entry.dial_code})
                            </option>
                          ))}
                        </Form.Control>
                        {touched.mbwayPrefix && (
                          <Form.Control.Feedback type="invalid">
                            {t("forms.errors.phonePrefixInvalid")}
                          </Form.Control.Feedback>
                        )}
                      </div>
                      <div className="phone-number">
                        <Form.Control
                          className="rounded-underline"
                          type="number"
                          pattern="[0-9]*"
                          inputMode="numeric"
                          name="mbwayPhoneNumber"
                          placeholder={t("forms.phone")}
                          value={values.mbwayPhoneNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.mbwayPhoneNumber && !!errors.mbwayPhoneNumber}
                        />
                        {touched.mbwayPhoneNumber && (
                          <Form.Control.Feedback type="invalid">
                            {t("forms.errors.phoneInvalid")}
                          </Form.Control.Feedback>
                        )}
                      </div>
                    </Form.Group>
                  </Form>
                </>
              )
            }}
          </Formik>
        </Col>
      </Row>
    </div>
  )
}

export default withErrorModal(MbwayPayment);
