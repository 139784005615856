import api from "lib/api"

const ALL_CATEGORIES_FETCH = "ALL_CATEGORIES_FETCH"

const fetchCategories = () => (dispatch, getState) => {
  const state = getState()
  return dispatch({
    type: ALL_CATEGORIES_FETCH,
    payload: api.categories.get({
      language: state.context.language,
    }),
  })
}

fetchCategories.action = ALL_CATEGORIES_FETCH

export default fetchCategories
