const RESTAURANTS_CATEGORY_ID = 1;

export const isCampaignRestaurant = (campaign) => {
  try {
    return !!campaign.info.categoryView.categories.find(
      (cat) => cat.categoryId === RESTAURANTS_CATEGORY_ID
    );
  } catch (err) {
    return false;
  }
};

export const formatPrice = (value, currency, { minFractionDigits } = {}) => {
  let currencyCode;
  switch (currency) {
    case "€":
      currencyCode = "EUR";
      break;
    case "$":
      currencyCode = "USD";
      break;
    default:
      // Non-supported currency, return some standard formatting
      return `${value} ${currency}`;
  }
  let minimumFractionDigits;
  if (typeof minFractionDigits !== "undefined") {
    minimumFractionDigits = minFractionDigits;
  } else {
    minimumFractionDigits = value % 1 === 0 ? 0 : 2;
  }

  return new Intl.NumberFormat("pt-PT", {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits,
  }).format(value);
};

export const getParentUrl = () => {
  try {
    return parent.window.location.href;
  } catch (err) {}

  try {
    // https://stackoverflow.com/a/7739035
    const parentUrl =
      window.location != window.parent.location
        ? document.referrer
        : document.location.href;
    return parentUrl;
  } catch (err) {}
  return null;
};

/**
 * Some prices (offerPrice, priceBefore) are sent as strings with a comma as the
 * decimal separator.
 *
 * @param {String} stringPrice - One or more prices to convert to float
 */
export const stringPriceToFloat = (...stringPrices) => {
  return stringPrices.map((stringPrice) => {
    if (typeof stringPrice === "undefined" || stringPrice === null) {
      return null;
    }
    const priceWithDot = String(stringPrice).replace(",", ".");
    return parseFloat(priceWithDot, 10);
  });
};

export const guid = function () {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};

export const promiseTimeout = (promise, ms) => {
  const timeout = new Promise((resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      reject(new Error(`Timed out in ${ms}ms`));
    }, ms);
  });
  return Promise.race([promise, timeout]);
};

export const formatDuration = (seconds) => {
  const hhmm = new Date(seconds * 1000).toISOString().substr(11, 5);
  const [hh, mm] = hhmm.split(":").map((num) => parseInt(num, 10));
  const h = hh ? `${hh}h` : "";
  const m = mm ? `${mm}min` : "";

  return [h, m].join(" ").trim() || '0min';
};
