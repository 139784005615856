import React from "react"
import { css } from "@emotion/core"
import MygonLogo from "../public/static/images/mygon-logo.png"
import Image from "next/image"

const styles = {
  wrapper: css({
    float: "right",
    fontSize: '0.9rem',
    display: 'flex',
    alignSelf: 'baseline',
    '.powered-by': {
      marginRight: '4px',
    }
  }),
};

export default function PoweredByMygon() {
  return (
    <div css={styles.wrapper}>
      <span className="powered-by">Powered by </span>
      <Image
        src="https://www.mygon.com/reservation-widget/static/images/logo_red.svg"
        height="13px"
        width="48px"
      />
    </div>
  );
}
