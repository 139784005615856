import api from "lib/api";

const CAMPAIGN_FETCH_CALENDAR_SLOTS = "CAMPAIGN_FETCH_CALENDAR_SLOTS";

const fetchCampaignCalendarSlots = ({ campaignId }) => (dispatch, getState) => {
  const state = getState()
  return dispatch({
    type: CAMPAIGN_FETCH_CALENDAR_SLOTS,
    payload: api.campaign.getCalendarSlots({
      campaignId,
      language: state.context.language,
    }),
    meta: {
      campaignId,
    },
  });
}

fetchCampaignCalendarSlots.action = CAMPAIGN_FETCH_CALENDAR_SLOTS;

export default fetchCampaignCalendarSlots;
