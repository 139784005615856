import api from "lib/api"

const DELETE_ADDRESS = "DELETE_ADDRESS"

const deleteAddress = ({ id }) => (dispatch, getState) => {
  const state = getState()

  return dispatch({
    type: DELETE_ADDRESS,
    payload: api.addresses.deleteAddress({
      language: state.context.language,
      token: state.user.token,
      id,
    }),
  })
}

deleteAddress.action = DELETE_ADDRESS

export default deleteAddress
