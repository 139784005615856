import pkg from "../../package.json";

// Dimensions mapping:
// dimension1 -  User Status
// dimension2 -  Category id
// dimension3 -  SubCategory id
// dimension4 -  App Version
// dimension6 -  user id
// dimension8 -  Campaign id
// dimension9 -  Shop id
// dimension10 -  transaction id / payment id
// dimension11 -  clientApplication
// dimension12 -  campaignType
// dimension16 -  entity id
// dimension17 -  type of widget
// dimension18 -  subCategory Name
// dimension20 -  booking type (booking, voucher, premium booking, premium voucher, premium exclusive)

let dimensionsValues = {
  dimension1: null,
  dimension2: null,
  dimension3: null,
  dimension4: `widget-${pkg.version}`,
  dimension6: null,
  dimension8: null,
  dimension9: null,
  dimension10: null,
  dimension11: null,
  dimension12: null,
  dimension16: null,
  dimension17: null,
  dimension18: null,
  dimension20: null,
};

let fbPixelValues = {
  content_type: "product",
  content_ids: null, // campaign id
  content_name: null, // campaign title
};

export const updateDimensions = (updateValues) => {
  dimensionsValues = {
    ...dimensionsValues,
    ...updateValues,
  };
};

export const updateFbPixelValues = (updateValues) => {
  fbPixelValues = {
    ...fbPixelValues,
    ...updateValues,
  };
};

export const getDimensions = () => dimensionsValues;

export const triggerEvent = (type, action, label, customOptions = {}) => {
  if (!window) return;

  const options = {
    ...dimensionsValues,
    event_category: type,
    event_action: action,
    ...(!!label && { event_label: label }),
    ...customOptions,
  };
  window.gtag("event", type, options);
};

export const trackFbEvent = (eventId, eventParams = {}) => {
  if (!window || !window.fbq) return;
  window.fbq("track", eventId, { ...fbPixelValues, ...eventParams });
};
