const FLOW_SET_BACK_BUTTON_STATE = "FLOW_SET_BACK_BUTTON_STATE";
const setBackButtonState = (status) => {
  return {
    type: FLOW_SET_BACK_BUTTON_STATE,
    payload: status
  };
};
setBackButtonState.action = FLOW_SET_BACK_BUTTON_STATE;

export default setBackButtonState;
