import React, { useCallback, useMemo, useState, useEffect } from "react";
import { css } from "@emotion/core";
import { Button } from "react-bootstrap";
import { IconWrapper } from "components/icons";
import {
  isDateAvailable,
  dateDiffInDays,
  getDatesOfTheWeek,
  getDatesWeeksOffset,
  formatDate,
} from "lib/helpers/calendar";
import { useTranslation } from "react-i18next";
import cssVars from "styles/variables.module.scss";

const styles = {
  header: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "1.1rem",
    fontWeight: 500,
    button: {
      margin: "0.5rem",
      width: "1.333rem",
      height: "2rem",
      padding: 0,
      svg: {
        fill: cssVars.primary,
      },
      "&:disabled": {
        svg: {
          fill: cssVars.gray500,
        },
      },
    },
  }),
  weekDates: css({
    listStyle: "none",
    padding: 0,
    display: "flex",
    justifyContent: "space-around",
    textAlign: "center",
    li: {
      cursor: "pointer",
    },
    ".unavailable": {
      color: cssVars.gray500,
    },
    ".weekday": {
      marginBottom: "0.5em",
    },
    ".day-number": {
      fontSize: "1.1rem",
      width: "2rem",
      height: "2rem",
      borderRadius: "1rem",
      padding: 0,
    },
    ".day-number:hover": {
      backgroundColor: cssVars.gray300,
    },
    "li.unavailable .day-number": {
      color: cssVars.gray500,
    },
    "li.has-selections .day-number": {
      backgroundColor: cssVars.white,
      borderColor: cssVars.primary,
      borderWidth: "3px",
      color: cssVars.primary,
    },
    "li.today .day-number": {
      backgroundColor: cssVars.gray700,
      color: cssVars.white,
    },
    "li.active .day-number": {
      backgroundColor: cssVars.primary,
      color: cssVars.white,
    },
  }),
};

/**
 *
 * @param {Object} props
 * @param {String} props.startDay - The first allowed day in the format
 * YYYY-MM-DD
 * @param {String} props.endDay - The last allowed day in the format YYYY-MM-DD
 * @param {Array} props.unavailableDays - An array of days such as
 * ["2020-01-12", "2020-01-19"]
 */
export default function WeekDaySelector({
  startDay,
  endDay,
  date,
  dateTimes,
  unavailableDays,
  onDateChange,
  onSelectedDayAndWeekMatchChange,
}) {
  const { t } = useTranslation();
  const now = useMemo(() => new Date(), []);
  const [weeksOffset, setWeeksOffset] = useState(
    date ? getDatesWeeksOffset(now, date) : 0
  );
  const [weekOffsetOfSelectedDay, setWeekOffsetOfSelectedDay] = useState(
    date ? 0 : getDatesWeeksOffset(now, date)
  );
  const firstAllowedDate = useMemo(() => new Date(startDay), []);
  const lastAllowedDate = useMemo(() => new Date(endDay), []);
  const [currentMonday, setCurrentMonday] = useState(null);
  const [selectedDay, setSelectedDay] = useState(
    date || formatDate(now)
    // formatDate(now)
  );
  const [canTravelBack, setCanTravelBack] = useState(false);
  const [canTravelForward, setCanTravelForward] = useState(false);

  useEffect(function init() {
    onSelectedDayAndWeekMatchChange(true);
  }, []);

  const localIsDateAvailable = useCallback(
    (date) => isDateAvailable(date, startDay, endDay, unavailableDays),
    [startDay, endDay, unavailableDays]
  );

  const dateHasTimeSelections = useCallback(
    (dateYmd) =>
      dateTimes &&
      dateTimes.find(
        (entry) => entry.date === dateYmd && entry.times && entry.times.length
      ),
    [dateTimes]
  );

  const datesOfWeek = useMemo(
    () =>
      getDatesOfTheWeek(now, weeksOffset).map((date) => {
        const dateYmd = formatDate(date);
        return {
          date,
          dateYmd,
          hasTimeSelections: dateHasTimeSelections(dateYmd),
          unavailable: !localIsDateAvailable(date),
        };
      }),
    [weeksOffset, localIsDateAvailable, dateHasTimeSelections]
  );

  useEffect(
    function updateCurWeekMonday() {
      const monday = datesOfWeek[0].date;
      setCurrentMonday(monday);

      // Update the calendar back and forward button availability
      const daysToFirstAllowed = dateDiffInDays(firstAllowedDate, monday);
      const daysToLastAllowed = dateDiffInDays(monday, lastAllowedDate);
      setCanTravelBack(daysToFirstAllowed > 0);
      setCanTravelForward(daysToLastAllowed >= 7);
    },
    [datesOfWeek]
  );

  const handleSelect = useCallback(
    (date, availableDay) => {
      const dateYmd = formatDate(date);
      setSelectedDay(dateYmd);
      setWeekOffsetOfSelectedDay(weeksOffset);
      onSelectedDayAndWeekMatchChange(true);
      onDateChange(dateYmd, availableDay, true);
    },
    [weeksOffset]
  );

  const handleOffsetWeek = useCallback(
    (sum) => {
      const newWeeksOffset = weeksOffset + sum;
      onSelectedDayAndWeekMatchChange(
        newWeeksOffset === weekOffsetOfSelectedDay
      );
      setWeeksOffset((cur) => cur + sum);
    },
    [weeksOffset, weekOffsetOfSelectedDay]
  );

  const dayLabels =
    t("calendar.daysOfWeekShort", {
      returnObjects: true,
    }) || [];

  return currentMonday ? (
    <div>
      <div css={styles.header}>
        <Button
          disabled={!canTravelBack}
          onClick={() => handleOffsetWeek(-1)}
          variant="non-existing"
          aria-label={t("calendar.previousWeek")}
        >
          <IconWrapper
            name="mdiChevronLeft"
            width="1.333rem"
            height="2rem"
            viewBox="8 0 12 24"
          />
        </Button>
        <div className="month">
          {t("calendar.monthYear", {
            month: t(`calendar.months.${currentMonday.getMonth()}`),
            year: currentMonday.getFullYear(),
          })}
        </div>
        <Button
          disabled={!canTravelForward}
          onClick={() => handleOffsetWeek(1)}
          variant="non-existing"
          aria-label={t("calendar.nextWeek")}
        >
          <IconWrapper
            name="mdiChevronRight"
            width="1.333rem"
            height="2rem"
            viewBox="5 0 12 24"
          />
        </Button>
      </div>
      <ul css={styles.weekDates}>
        {datesOfWeek.map((entry, index) => (
          <li
            key={entry.date.getTime()}
            onClick={() => handleSelect(entry.date, !entry.unavailable)}
            className={[
              entry.unavailable && "unavailable",
              entry.dateYmd === formatDate(now) && "today",
              entry.dateYmd === selectedDay
                ? "active"
                : entry.hasTimeSelections && "has-selections",
            ]
              .filter(Boolean)
              .join(" ")}
          >
            <div className="weekday">{dayLabels[index]}</div>
            <Button type="button" variant="non-existing" className="day-number">
              {entry.date.getDate()}
            </Button>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
}
