import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Container, Form } from "react-bootstrap";
import { css } from "@emotion/core";
import {Formik} from "formik"
import * as yup from "yup";
import selectors from "lib/redux/selectors";
import withErrorModal from "lib/hocs/with-error-modal";
import { useTranslation } from "react-i18next";
import phoneCodes from "lib/helpers/phone-codes";
import FormikEffect from "components/formik-effect";
import ReservationSummaryHeading from "components/elements/reservation-summary-heading";
import cssVars from "styles/variables.module.scss";
import { mq } from "styles";
import Footer from "../footer";

const styles = {
  contactText: css(
    mq({
      fontSize: ["1rem", "1.25rem"],
      fontWeight: 500,
      color: cssVars.gray800,
      textAlign: "center",
    })
  ),
  phoneRow: css({
    display: "flex",
    select: {
      minWidth: "10em",
    },
    ".phone-number": {
      flex: 1,
      marginLeft: "1rem",
    },
  }),
};

/**
 * Flow Step 3 - Customer name, phone notes and actual booking submission
 */
function VoucherPaymentGuestInfo({
                               stepState,
                               updateStepState,
                               onFormikProps,
                               onNextStep,
                               onPrevStep,
                             }) {
  const { t } = useTranslation()
  const loggedIn = useSelector(selectors.user.loggedIn)
  const context = useSelector((state) => state.context)

  const {
    bookingType,
  } = useSelector(selectors.product.info);

  const merchantNotes = useSelector(selectors.flow.bookingMerchantNotes)

  let schema = yup.object({
    name: yup.string().trim().required(),
    prefix: yup.string(),
    phone: yup
      .string()
      .trim()
      .when("prefix", {
        is: "+351",
        then: yup.string().matches(/^9[0-9]{8}$/),
        otherwise: yup.string().matches(/[0-9]+/),
      })
      .required(),
    email: !loggedIn && context.allowGuestLogin
      ? yup.string().email().required()
      : yup.string().email(),
  });

  const showBookingFinishingNote = !!merchantNotes && !!merchantNotes.bookingFinishingNote

  const handleFormChange = useCallback(
    ({ nextValues }) => {
      updateStepState(nextValues);
    },
    [updateStepState]
  );

  const handleValidSubmit = async () => {
    onNextStep()
  }

  return (
    <div>
      <ReservationSummaryHeading onClick={onPrevStep} />
      <Container>
        <Formik
          validateOnMount
          validationSchema={schema}
          onSubmit={handleValidSubmit}
          initialValues={{
            name: stepState.name,
            prefix: stepState.prefix,
            email: stepState.email || "",
            phone: stepState.phone || "",
            notes: stepState.notes || "",
            vatId: stepState.vatId || "",
          }}
        >
          {({
              handleBlur,
              handleChange,
              handleSubmit,
              submitForm,
              values,
              touched,
              errors,
              isValid,
              isSubmitting,
            }) => {
            onFormikProps({
              submitForm,
              isValid,
              isSubmitting,
              submitLabel: t("flow.continue"),
            });
            return (
              <>
                <FormikEffect onChange={handleFormChange} />
                <Form
                  noValidate
                  className="form padded-step-container"
                  onSubmit={handleSubmit}
                >
                  <Form.Group>
                    <Form.Control
                      className="rounded-underline"
                      type="text"
                      name="name"
                      placeholder={t("forms.name")}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.name && !!errors.name}
                    />
                    {touched.name && (
                      <Form.Control.Feedback type="invalid">
                        {t("forms.errors.nameInvalid")}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  {!loggedIn && context.allowGuestLogin && (
                    <Form.Group>
                      <Form.Control
                        className="rounded-underline"
                        type="text"
                        name="email"
                        placeholder={t("forms.email")}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.email && !!errors.email}
                      />
                      {touched.email && (
                        <Form.Control.Feedback type="invalid">
                          {t("forms.errors.emailInvalid")}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  )}
                  <Form.Group css={styles.phoneRow}>
                    <div className="phone-prefix">
                      <Form.Control
                        as="select"
                        className="rounded-underline"
                        name="prefix"
                        placeholder={t("forms.phonePrefix")}
                        defaultValue={values.prefix}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.prefix && !!errors.prefix}
                      >
                        {phoneCodes.map((entry) => (
                          <option
                            key={`${entry.code}-${entry.dial_code}`}
                            value={entry.dial_code}
                          >
                            {entry.code} ({entry.dial_code})
                          </option>
                        ))}
                      </Form.Control>
                      {touched.prefix && (
                        <Form.Control.Feedback type="invalid">
                          {t("forms.errors.phonePrefixInvalid")}
                        </Form.Control.Feedback>
                      )}
                    </div>
                    <div className="phone-number">
                      <Form.Control
                        className="rounded-underline"
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        name="phone"
                        placeholder={t("forms.phone")}
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.phone && !!errors.phone}
                      />
                      {touched.phone && (
                        <Form.Control.Feedback type="invalid">
                          {t("forms.errors.phoneInvalid")}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Form.Group>
                  {bookingType > 6 && (
                    <Form.Group>
                      <Form.Control
                        className="rounded-underline"
                        type="text"
                        name="vatId"
                        placeholder={t("forms.vatId")}
                        value={values.vatId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.vatId && !!errors.vatId}
                      />
                      {touched.vatId && (
                        <Form.Control.Feedback type="invalid">
                          {t("forms.errors.vatIdInvalid")}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  )}
                  {showBookingFinishingNote && (
                    <div>
                      <h5>{t("reservation.merchant_additional_info")}</h5>
                      <p className="merchant-notes">{merchantNotes.bookingFinishingNote}</p>
                    </div>
                  )}
                </Form>
              </>
            );
          }}
        </Formik>

        <div className="padded-step-container">
          <Footer />
        </div>
      </Container>
    </div>
  );
}

export default withErrorModal(VoucherPaymentGuestInfo);
